import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../actions/model';

export interface PhoneConfirmState {
    phoneString: string;
}

const initialState: PhoneConfirmState = {
    phoneString: ''
};

export const phoneConfirmSlice = createSlice({
    name: '@@vp/phoneConfirm',
    initialState,
    reducers: {
        setPhoneString(state, action: PayloadAction<PhoneConfirmState['phoneString']>): void {
            state.phoneString = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): PhoneConfirmState => initialState,
    }
});

export const {
    setPhoneString,
} = phoneConfirmSlice.actions;
