export const KEY_INDEX_IN_OBJECT_ENTRY_CORTAGE = 0;
export const VALUE_INDEX_IN_OBJECT_ENTRY_CORTAGE = 1;

export const DEFAULT_WHITE_SPACES_FOR_JSON_STRINGIFY = 2;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;

export const DB_MAX_PARAMETERS_PER_REQUEST = 2100;
export const DB_MAX_TEXT_LENGTH = 4000; // nvarchar can hold upto 4000 characters and it takes double the space as SQL varchar (https://www.sqlshack.com/sql-varchar-data-type-deep-dive)

export const PERCENT_TO_MULTIPLIER = 0.01;

export const DEFAULT_TAB_INDENTATION = 4;

export const QUERY_ROWS_SEPARATOR = ';';

export const QUERY_PARTS_SEPARATOR = '|';

export const ELECTRO_MEASURE = 'кВтч';
