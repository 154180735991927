import React from 'react';
import classNames from 'classnames';
import { LoaderProps, LoaderSize } from './model';

import './styles.scss';

export const View: React.FC<LoaderProps> = ({ size = LoaderSize.Default, className }) => {

    return (<div className={classNames('loader__wrapper', className)}>
        <div className="loader" style={{
            fontSize: `${size}px`,
            width: `${size}px`,
            height: `${size}px`,
        }}>
            <svg width={`${size}px`} height={`${size}px`}>
                <circle cx="0.5em" cy="0.5em" r="0.45em" />
                <circle cx="0.5em" cy="0.5em" r="0.45em" />
            </svg>
        </div>
    </div>);
};
