import React from 'react';
import { withCapitalLetter } from 'common/shared/utils/text';
import { MeterEntriesValidationErrorType, MeterEntriesValidationError } from 'common/model/meterReadings/meterEntriesValidateError';
import { Typography } from 'app/shared/components/typography';

export const ValidationErrorMessage: React.FC<MeterEntriesValidationError> = (props) => {
    switch (props.type) {
        case MeterEntriesValidationErrorType.Excessive:
            return <>
                Расход по показаниям {props.consumption} {props.measure}&nbsp;
                по прибору учета {props.meterSerialNum}&nbsp;
                существенно превышает средний расход вашего потребления. <br />
                № счетчика: {props.meterSerialNum} <br />
                {props.tariff ? `Тариф: ${props.tariff}` : ''}
            </>;
        case MeterEntriesValidationErrorType.Insufficient:
            return <>
                Вы ввели показания меньше предыдущих. <br />
                № счетчика: {props.meterSerialNum} <br />
                Показания: {props.consumption} {props.measure} <br />
                {props.tariff ? `Тариф: ${props.tariff}` : ''}
            </>;
        case MeterEntriesValidationErrorType.Format:
            return <>
                По следующим приборам учета превышена разрядность показаний. <br />
                № счетчика: {props.meterSerialNum} <br />
                Показания: {props.consumption} {props.measure} <br />
                {props.tariff ? `Тариф: ${props.tariff}` : ''}
            </>;
        case MeterEntriesValidationErrorType.Period:
            return <>
                Период передачи показаний не наступил. <br />
                {props.settings?.startReadingsAcceptDayOfMonth && props.settings?.endReadingsAcceptDayOfMonth &&
                    `Вы можете отправить данные с ${props.settings?.startReadingsAcceptDayOfMonth}
                по ${props.settings?.endReadingsAcceptDayOfMonth} число каждого месяца`}
            </>;
        case MeterEntriesValidationErrorType.DailyLimit:
            return <>
                По следующим приборам учета показания за эту дату уже переданы.<br />
                № счетчика: {props.meterSerialNum}<br />
                {props.tariff ? `Тариф: ${props.tariff}` : ''}
            </>;
        case MeterEntriesValidationErrorType.MonthLimit:
            return <>
                По следующим приборам учета в текущем расчетном периоде&nbsp;
                уже передано максимальное количество показаний. <br />
                № счетчика: {props.meterSerialNum}<br />
                {props.tariff ? `Тариф: ${props.tariff}` : ''}
            </>;
        default:
            return null;
    }
};

interface Props {
    groupedErrors: [string, MeterEntriesValidationError[]][];
}

export const ValidationErrorMessages: React.FC<Props> = ({ groupedErrors }) => {
    let showPhoneNumber = false;

    return <>
        {groupedErrors.map(([name, errors], errIndex) => {
            if (!errors.length) {
                return null;
            }

            showPhoneNumber = showPhoneNumber || errors.some(err => err.type === MeterEntriesValidationErrorType.Excessive);

            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return <div key={name} style={{ marginBottom: errIndex === groupedErrors.length - 1 ? 0 : 16 }}>
                <Typography variant='bodyBold'>{withCapitalLetter(name)}:</Typography>
                {errors.map((err, index) => (
                    <div style={{ marginTop: 8 }} key={`${err.meterId}_${index}`}>
                        <ValidationErrorMessage {...err} />
                    </div>
                ))}
            </div>;
        })}

        {showPhoneNumber && (
            <div style={{ marginTop: 24 }}>
                Проверьте правильность введенных показаний или обратитесь в справочную службу: 8 (800) 250-60-06.
            </div>
        )}
    </>;
};
