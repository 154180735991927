import { parseBoolean } from './parseBoolean';

export const getEnvBool = (name: string, varPrefix = 'REACT_APP_'): boolean => {
    const value: string | undefined = process.env[`${varPrefix}${name}`];

    if (value) {
        return parseBoolean(value);
    }

    return false;
};

export const getFeatureFlag = (name: string, clientId: string | undefined, varPrefix = 'REACT_APP_'): boolean => {
    const value: string | undefined = process.env[`${varPrefix}${name}`];

    if (!value) {
        return false;
    }

    if (value.toLowerCase().trim() === 'true') {
        return true;
    }

    if (value.toLowerCase().trim() === 'false') {
        return false;
    }

    if (!clientId) {
        return false;
    }

    const values = value.split(',').map((v) => v.toLowerCase().trim());

    return values.includes(clientId.toLowerCase());
};
