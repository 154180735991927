import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/from';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/ends-with';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';

import { createRoot } from 'react-dom/client';

import './init';
import { App } from './app';

// eslint-disable-next-line no-console
console.log('ЛК Восток. Дев-стенд 2. Запрос 26-Б v2.3');

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
