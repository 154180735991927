/**
 * Список владельцев услуг
 */

export enum Branches {
    SUENKOIU = 'SUENKOIU',
    NFSBYT = 'NFSBYT',
    ERICYANAO = 'ERICYANAO',
    SUENKOYA = 'SUENKOYA',
    SUENKOZ = 'SUENKOZ',
    OSESBYT = 'OSESBYT',
    USTEK = 'USTEK',
    OOOTEO = 'OOOTEO',
    TRIC = 'TRIC',
    TESBYT = 'TESBYT',
    SFSBYT = 'SFSBYT',
    OAORIC = 'OAORIC',
    GUBSBYT = 'GUBSBYT',
    GET = 'GET',
}

export const branchesDictionary: Record<Branches, string> = {
    [Branches.SUENKOIU]: 'ПАО СУЭНКО г.Ишим',
    [Branches.NFSBYT]: 'Ноябрьский филиал АО ЭК Восток',
    [Branches.ERICYANAO]: 'Филиал АО «Единый расчетно-информационный центр ЯНАО» в городе Ноябрьске»',
    [Branches.SUENKOYA]: 'ПАО СУЭНКО г.Ялуторовск',
    [Branches.SUENKOZ]: 'ПАО СУЭНКО г. Заводоуковск',
    [Branches.OSESBYT]: 'Акционерное общество "Энергосбытовая компания "Восток"',
    [Branches.USTEK]: 'АО "УСТЭК"',
    [Branches.OOOTEO]: 'ООО «ТЭО»',
    [Branches.TRIC]: 'ОАО ТРИЦ',
    [Branches.TESBYT]: 'АО "ЭК "Восток"',
    [Branches.SFSBYT]: 'ООО "Югорский расчетно-информационный центр"',
    [Branches.OAORIC]: 'АО «РИЦ»',
    [Branches.GUBSBYT]: 'Губкинский филиал АО ЭК Восток',
    [Branches.GET]: '«Газпром энергосбыт Тюмень»',
};

export const branchesWithAlwaysSeparativePayments: Branches[] = [Branches.TRIC];
