import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ExtendedActiveEnergyReadingData,
    ExtendedIncrementalEnergyReadingData
} from '../../../../../common/model/isup/dailyIncrementalResponse';
import { ActionType } from '../../actions/model';
import { MeterViewModel } from '../../../common/model/isup/dailyIncrementalResponse';


export interface MeterReadingsIsupState {
    meterActiveEnergyReadingData: ExtendedActiveEnergyReadingData[];
    meterActiveEnergyReadingDataLoading: boolean;
    meterActiveEnergyReadingDataLoaded: boolean;
    meterIncrementalEnergyReadingData: ExtendedIncrementalEnergyReadingData[];
    meterIncrementalEnergyReadingDataLoading: boolean;
    meterIncrementalEnergyReadingDataLoaded: boolean;
    meterIsupInfo: MeterViewModel[];
    isMeterIsupInfoLoading: boolean;
    isupMeterEnergyReadingData?: ExtendedActiveEnergyReadingData;
    isupMeterEnergyReadingLoading: boolean;
    meterJournalExcelLoading: boolean;
    meterReadingDataExcelLoading: boolean;
    sendMeterCurrentReadingsOrderLoading: boolean;
}

const initialState: MeterReadingsIsupState = {
    meterActiveEnergyReadingData: [],
    meterActiveEnergyReadingDataLoading: false,
    meterActiveEnergyReadingDataLoaded: false,
    meterIncrementalEnergyReadingData: [],
    meterIncrementalEnergyReadingDataLoading: false,
    meterIncrementalEnergyReadingDataLoaded: false,
    meterIsupInfo: [],
    isupMeterEnergyReadingData: undefined,
    isupMeterEnergyReadingLoading: false,
    meterJournalExcelLoading: false,
    meterReadingDataExcelLoading: false,
    isMeterIsupInfoLoading: false,
    sendMeterCurrentReadingsOrderLoading: false,
};

export const meterReadingsIsupSlice = createSlice({
    name: '@@vp/isup',
    initialState,
    reducers: {
        setMeterActiveEnergyReadingDataLoading(state, action: PayloadAction<MeterReadingsIsupState['meterActiveEnergyReadingDataLoading']>): void {
            state.meterActiveEnergyReadingDataLoading = action.payload;
        },
        setMeterIncrementalEnergyReadingDataLoading(state, action: PayloadAction<MeterReadingsIsupState['meterIncrementalEnergyReadingDataLoading']>): void {
            state.meterIncrementalEnergyReadingDataLoading = action.payload;
        },
        setMeterActiveEnergyReadingData(state, action: PayloadAction<MeterReadingsIsupState['meterActiveEnergyReadingData']>): void {
            state.meterActiveEnergyReadingData = action.payload ?? [];
            state.meterActiveEnergyReadingDataLoaded = true;
        },
        setMeterIncrementalEnergyReadingData(state, action: PayloadAction<MeterReadingsIsupState['meterIncrementalEnergyReadingData']>): void {
            state.meterIncrementalEnergyReadingData = action.payload ?? [];
            state.meterIncrementalEnergyReadingDataLoaded = true;
        },
        setMeterIsupInfo(state, action: PayloadAction<MeterViewModel>): void {
            if (action.payload) {
                const index = state.meterIsupInfo.findIndex(item => item.meterSerialNum === action.payload.meterSerialNum);
                if (index > -1) {
                    state.meterIsupInfo[index] = action.payload;
                } else {
                    state.meterIsupInfo.push(action.payload);
                }
            }
        },
        setMeterIsupInfoLoading(state, action: PayloadAction<MeterReadingsIsupState['isMeterIsupInfoLoading']>): void {
            state.isMeterIsupInfoLoading = action.payload;
        },
        setIsupMeterEnergyReadingData(state, action: PayloadAction<MeterReadingsIsupState['isupMeterEnergyReadingData']>): void {
            state.isupMeterEnergyReadingData = action.payload;
        },
        setIsupMeterEnergyReadingLoading(state, action: PayloadAction<MeterReadingsIsupState['isupMeterEnergyReadingLoading']>): void {
            state.isupMeterEnergyReadingLoading = action.payload;
        },
        clearMeterDailyEnergyReadingData(state): void {
            state.meterActiveEnergyReadingData = [];
            state.meterActiveEnergyReadingDataLoading = false;
            state.meterActiveEnergyReadingDataLoaded = false;
            state.meterIncrementalEnergyReadingData = [];
            state.meterIncrementalEnergyReadingDataLoading = false;
            state.meterIncrementalEnergyReadingDataLoaded = false;
            state.meterIsupInfo = [];
        },
        setMeterJournalExcelLoading(state, action: PayloadAction<MeterReadingsIsupState['meterJournalExcelLoading']>): void {
            state.meterJournalExcelLoading = action.payload;
        },
        setMeterReadingDataExcelLoading(state, action: PayloadAction<MeterReadingsIsupState['meterReadingDataExcelLoading']>): void {
            state.meterReadingDataExcelLoading = action.payload;
        },
        setSendMeterCurrentReadingsOrderLoading(state, action: PayloadAction<MeterReadingsIsupState['sendMeterCurrentReadingsOrderLoading']>): void {
            state.sendMeterCurrentReadingsOrderLoading = action.payload;
        }
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): MeterReadingsIsupState => initialState,
    }
});

export const {
    setMeterActiveEnergyReadingData,
    setMeterActiveEnergyReadingDataLoading,
    setMeterIncrementalEnergyReadingData,
    setMeterIsupInfo,
    setMeterIncrementalEnergyReadingDataLoading,
    clearMeterDailyEnergyReadingData,
    setMeterJournalExcelLoading,
    setMeterReadingDataExcelLoading,
    setMeterIsupInfoLoading,
    setSendMeterCurrentReadingsOrderLoading,
    setIsupMeterEnergyReadingData,
    setIsupMeterEnergyReadingLoading
} = meterReadingsIsupSlice.actions;
