import { getApiRoute } from '../../../../app/shared/pipes';
import { AppThunk } from '../../../reducers';
import { appUrls } from '../../../../common/appUrls';
import { httpService } from '../../../../app/shared/httpWrapper';
import { setTechnicalWorkingStates } from '../../../reducers/unauthorized/technicalWorking';
import { TechnicalWorkingStatesResponse } from '../../../../common/model/unauthorized/technicalWorking';

export const getTechnicalWorkingsStatesByRegions = (): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_get_technical_working_states_by_regions, {});
        const data = await httpService.get<TechnicalWorkingStatesResponse>(route);
        dispatch(setTechnicalWorkingStates(data));
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};
