import { AccountClaimViewModel } from './../../../../../common/model/account/accountClaimViewModel';
import { accountsClaimsListSliceSelector } from './../../../../selectors/accounts/claims/list/index';
import { accountsClaimsListSlice } from '../../../../reducers/accounts/claims/list/index';
import { AppThunk } from '../../../../reducers';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';

export const getAccountClaims = (): AppThunk => async (dispatch, getState) => {
    const { isLoading } = accountsClaimsListSliceSelector(getState());

    if (isLoading) {
        return;
    }

    dispatch(accountsClaimsListSlice.actions.fetchStart());

    try {
        const data = await httpService.get<AccountClaimViewModel[]>(
            appUrls.api_get_client_accounts_claims
        );

        dispatch(accountsClaimsListSlice.actions.fetchSuccess({ data }));
    } catch (err: any) {
        const errorMessage = err?.data?.errorDetails ?? 'Произошла ошибка при загрузке данных по исковому производству';

        dispatch(accountsClaimsListSlice.actions.fetchFailure(errorMessage));
    }
};
