import { StorageState } from '../../../reducers';
import {
    VIFontSize,
    VIFontType,
    VILetterSpacing,
    VisuallyImpairedSettings,
    VITheme
} from '../../../models/app/visuallyImpaired';

export const viSettingsSelector = (state: StorageState): VisuallyImpairedSettings => {
    return state.app.visuallyImpairedSettings;
};

export const viIsMobileMenuOpen = (state: StorageState): boolean => {
    return state.app.visuallyImpairedSettings.isMobileMenuOpen;
};

export const viStatusSelector = (state: StorageState): boolean => {
    return state.app.visuallyImpairedSettings.status;
};

export const viFontSizeSelector = (state: StorageState): VIFontSize => {
    return state.app.visuallyImpairedSettings.fontSize;
};

export const viLetterSpacingSelector = (state: StorageState): VILetterSpacing => {
    return state.app.visuallyImpairedSettings.letterSpacing;
};

export const viFontTypeSelector = (state: StorageState): VIFontType => {
    return state.app.visuallyImpairedSettings.fontType;
};

export const viThemeSelector = (state: StorageState): VITheme => {
    return state.app.visuallyImpairedSettings.theme;
};

export const viImageVisibleSelector = (state: StorageState): boolean => {
    return state.app.visuallyImpairedSettings.image;
};
