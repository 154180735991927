import { getApiRoute } from '../../../../../app/shared/pipes';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { UserUpdater } from '../../../../../common/model/users/user/userUpdater';
import { UserViewModel } from '../../../../../common/model/users/user/userViewModel';
import { AppThunk } from '../../../../reducers';
import { setEditableUser, setAccessRules, setEditCLient, removeEditClient, clearEditCLient } from '../../../../reducers/users/users/editable';
import { AccessRule } from '../../../../../common/model/users/accessRule';
import { UserRegionsViewModel } from '../../../../../../../common/model/users/userRegions/userRegionsViewModel';
import { ClientAdminImpersonationListItem } from 'common/model/client/clientAdminImpersonationListResponse';

export const getEditableUser = (
    userId: number | undefined
): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_get_user, { userId });
    const user = await httpService.get<UserViewModel>(route);
    dispatch(setEditableUser(user));
};


export const setEditableAccessUser = (user: UserViewModel): AppThunk => (dispatch): void => {
    dispatch(setEditableUser(user));
};

export const setClientToEdit = (user: ClientAdminImpersonationListItem): AppThunk => (dispatch): void => {
    dispatch(setEditCLient(user));
};

export const removeEditToClient = (): AppThunk => (dispatch): void => {
    dispatch(removeEditClient());
};

export const clearEditToClient = (): AppThunk => (dispatch): void => {
    dispatch(clearEditCLient());
};

export const createUser = (creator: UserUpdater): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_create_user);
    const createdUser = await httpService.post<UserUpdater>(route, creator);
    return createdUser;
};

export const updateUser = (id: number | undefined, updater: UserUpdater): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_update_user, { userId: id });
    const updatedUser = await httpService.put<UserViewModel>(route, updater);
    return updatedUser;
};

export const deleteUser = (id: number | undefined): AppThunk => async () => {
    const route = getApiRoute(appUrls.api_delete_user, { userId: id });
    await httpService.delete<void>(route);
};

export const updateEditableAccessUserRules = (
    userId: number | undefined,
    payload: AccessRule[]
): AppThunk => async (): Promise<void> => {
    await httpService.put<AccessRule[]>(
        getApiRoute(appUrls.api_put_access_users_access_rules, { userId }),
        payload,
    );
};

export const updateEditableAccessUserRegions = (
    userId: number | undefined,
    payload: string[]
): AppThunk => async (): Promise<void> => {
    await httpService.put<UserRegionsViewModel[]>(
        getApiRoute(appUrls.api_put_access_users_regions, { userId }),
        payload,
    );
};

export const setEditableAccessRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(setAccessRules(rules));
};

export const getEditableAccessUserRules = (userId: number | undefined): AppThunk => async (dispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_users_access_rules, { userId }));
    dispatch(setEditableAccessRules(rules));
};

export const resetEditableUserState = (): AppThunk => (dispatch): void => {
    dispatch(setEditableAccessRules([]));
    dispatch(setEditableUser(null));
};
