import { DebtTypeFromBilling } from '../../../../common/model/account/debtType';
import { AccountsChargesSelectionState } from '../../../models/accounts/chargesSelection';
import { AccountServicesViewModel } from '../../../../common/model/account/accountServicesViewModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { AccountViewModel } from '../../../../common/model/account/accountViewModel';
import _ from 'lodash';


const initialState: AccountsChargesSelectionState = {
    amountSelection: {},
    activitySelection: {},
};

export const accountsChargesSelection = createSlice({
    name: '@@vp/accounts/chargesSelection',
    initialState,
    reducers: {
        resetAccountsChargesSelectionState(state): void {
            state.amountSelection = initialState.amountSelection;
            state.activitySelection = initialState.activitySelection;
        },
        setAccountsChargesAmountSelection(state, action: PayloadAction<AccountsChargesSelectionState['amountSelection']>): void {
            state.amountSelection = action.payload;
        },
        setAccountChargeAmountSelectionForService(state, action: PayloadAction<{ accountId: AccountViewModel['id'], debtType: DebtTypeFromBilling, accountServiceId: AccountServicesViewModel['id'], amount: number; }>): void {
            const { accountId, debtType, accountServiceId, amount } = action.payload;
            _.set(state.amountSelection, [accountId, debtType, 'separatableServices', accountServiceId].map(String), amount);
        },
        setAccountChargeAmountSelectionForAtomicServiceGroup(state, action: PayloadAction<{ accountId: AccountViewModel['id'], debtType: DebtTypeFromBilling, amount: number; }>): void {
            const { accountId, debtType, amount } = action.payload;
            _.set(state.amountSelection, [accountId, debtType, 'atomicServices'].map(String), amount);
        },
        setAccountsChargesActivitySelection(state, action: PayloadAction<AccountsChargesSelectionState['activitySelection']>): void {
            state.activitySelection = action.payload;
        },
        setAccountChargeActivitySelectionForSeparatableServices(state, action: PayloadAction<{ accountId: AccountViewModel['id'], debtType: DebtTypeFromBilling, selectedServiceIds: AccountServicesViewModel['id'][]; }>): void {
            const { accountId, debtType, selectedServiceIds } = action.payload;
            _.set(state.activitySelection, [accountId, debtType, 'separatableServices'].map(String), selectedServiceIds);
        },
        setAccountChargeActivitySelectionForAtomicServiceGroup(state, action: PayloadAction<{ accountId: AccountViewModel['id'], debtType: DebtTypeFromBilling, selected: boolean; }>): void {
            const { accountId, debtType, selected } = action.payload;
            _.set(state.activitySelection, [accountId, debtType, 'atomicServices'].map(String), selected);
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccountsChargesSelectionState => initialState,
    }
});

export const {
    resetAccountsChargesSelectionState,
    setAccountsChargesAmountSelection,
    setAccountChargeAmountSelectionForService,
    setAccountChargeAmountSelectionForAtomicServiceGroup,
    setAccountsChargesActivitySelection,
    setAccountChargeActivitySelectionForSeparatableServices,
    setAccountChargeActivitySelectionForAtomicServiceGroup,
} = accountsChargesSelection.actions;
