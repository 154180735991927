import { push } from 'connected-react-router';
import { Routes } from '../../../../../app/shared/constants';
import { getApiRoute } from '../../../../../app/shared/pipes/routing';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { AccessRule } from '../../../../../common/model/users/accessRule';
import { UserViewModel } from '../../../../../common/model/users/user/userViewModel';
import { AppThunk } from '../../../../reducers';
import { setActiveUser, setAccessRules } from '../../../../reducers/users/users/active';
import { getResources } from '../../../resources';

export const setActiveAccessUser = (user: UserViewModel): AppThunk => (dispatch): void => {
    dispatch(setActiveUser(user));
    dispatch(getResources());
};

export const getCurrentUser = (): AppThunk => async (dispatch): Promise<void> => {
    const user = await httpService.get<UserViewModel>(appUrls.api_get_current_user);
    dispatch(setActiveAccessUser(user));
};

export const setActiveAccessUserRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(setAccessRules(rules));
};

export const getActiveAccessUserRules = (userId: number): AppThunk => async (dispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_users_access_rules, { userId }));
    dispatch(setActiveAccessUserRules(rules));
};

export const resetActiveUserState = (): AppThunk => (dispatch): void => {
    dispatch(setAccessRules([]));
    dispatch(setActiveUser(null));
};

export const logout = (): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.api_user_logout);
    await httpService.get(route);
    dispatch(push(Routes.login));
};
