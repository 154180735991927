import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessRule } from '../../../../common/model/users/accessRule';
import { ActionType } from '../../../actions/model';

export interface AccessRulesAccessState {
    available: AccessRule[];
}

const initialState: AccessRulesAccessState = {
    available: [],
};

export const accessAccessRulesSlice = createSlice({
    name: '@@vp/users/access-rules',
    initialState,
    reducers: {
        setAccessRules(state, action: PayloadAction<AccessRule[]>): void {
            state.available = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccessRulesAccessState => initialState,
    }
});
