import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserViewModel } from '../../../../../common/model/users/user/userViewModel';
import { AccessRule } from '../../../../../common/model/users/accessRule';
import { ClientAdminImpersonationListItem } from 'common/model/client/clientAdminImpersonationListResponse';

export interface UserEditableState {
    user: UserViewModel | null;
    accessRules: AccessRule[];
    client: ClientAdminImpersonationListItem | null | undefined;
}

const initialState: UserEditableState = {
    user: null,
    accessRules: [],
    client: undefined,
};

export const userEditableSlice = createSlice({
    name: '@vp/users/users/editable',
    initialState,
    reducers: {
        setEditableUser(state, action: PayloadAction<UserEditableState['user']>) {
            state.user = action.payload;
        },
        setAccessRules(state, action: PayloadAction<AccessRule[]>): void {
            state.accessRules = action.payload;
        },
        setEditCLient(state, action: PayloadAction<ClientAdminImpersonationListItem>): void {
            state.client = action.payload;
        },
        removeEditClient(state): void {
            state.client = null;
        },
        clearEditCLient(state): void {
            state.client = undefined;
        }
    }
});

export const {
    setEditableUser,
    setAccessRules,
    setEditCLient,
    removeEditClient,
    clearEditCLient
} = userEditableSlice.actions;
