import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { MeterReadingsReport } from '../../../../../../common/model/meterReadings/meterReadings';

export interface AdminMeterReadingOrderReportsState {
    list: MeterReadingsReport | null;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean;
}

const initialState: AdminMeterReadingOrderReportsState = {
    list: null,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminMeterReadingOrderReportsSlice = createSlice({
    name: '@@vp/meterReadingOrders/reports/list',
    initialState,
    reducers: {
        setMeterReadingOrderReportsList(state, action: PayloadAction<MeterReadingsReport>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoadingMeterReadingOrderReports(state, action: PayloadAction<AdminMeterReadingOrderReportsState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoadingMeterReadingOrderReportsExcel(state, action: PayloadAction<AdminMeterReadingOrderReportsState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearMeterReadingOrderReports(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminMeterReadingOrderReportsState => initialState,
    }
});

export const {
    setMeterReadingOrderReportsList,
    setLoadingMeterReadingOrderReports,
    setLoadingMeterReadingOrderReportsExcel,
    clearMeterReadingOrderReports
} = adminMeterReadingOrderReportsSlice.actions;
