import { adminAccountStatisticChargesExcelSlice } from './chargesExcel/index';
import { adminAccountStatisticBalanceSlice } from './balance';
import { adminAccountStatisticChargesSlice } from './charges';
import { combineReducers } from 'redux';

export const adminAccountStatistic = combineReducers({
    balance: adminAccountStatisticBalanceSlice.reducer,
    charges: adminAccountStatisticChargesSlice.reducer,
    chargesExcel: adminAccountStatisticChargesExcelSlice.reducer,
});
