import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingViewModel } from '../../../common/model/resources/settings/settingViewModel';

export type SettingsState = {
    available: SettingViewModel[];
};
const initialState: SettingsState = {
    available: [],
};

export const adminSettingsSlice = createSlice({
    name: '@@vp/settings',
    initialState,
    reducers: {
        setAvailable(state, action: PayloadAction<SettingViewModel[]>): SettingsState {
            return {
                ...state,
                available: action.payload,
            };
        }
    }
});
