import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentQrCallbackSliceSelector = (state: StorageState) => state.payment.paymentQrCallback;

export const paymentQrCallbackLoadingSelector = createSelector(
    paymentQrCallbackSliceSelector,
    state => state.paymentQrCallbackLoading
);

export const paymentQrCallbackLoadingErrorSelector = createSelector(
    paymentQrCallbackSliceSelector,
    state => state.paymentQrCallbackLoadingError
);

export const paymentQrCallbackCompleteLoadingSelector = createSelector(
    paymentQrCallbackSliceSelector,
    state => state.paymentQrCallbackCompleteLoading
);

export const paymentQrCallbackCompleteLoadingErrorSelector = createSelector(
    paymentQrCallbackSliceSelector,
    state => state.paymentQrCallbackCompleteLoadingError
);

export const paymentQrCallbackResponseSelector = createSelector(
    paymentQrCallbackSliceSelector,
    state => state.paymentQrCallbackResponse
);
