import { AppThunk } from '../../reducers';
import { blockingCallCountSlice } from '../../reducers/httpWrapper/blockingCallCount';
import { messagesSlice } from '../../reducers/httpWrapper/messages';

export type MessageType = 'error' | 'info';

export class NotificationMessage {
    public message: string;
    public messageType: MessageType;
    public title?: string;
}

export const increaseBlockingCallCounter = (): AppThunk => dispatch => {
    dispatch(blockingCallCountSlice.actions.increment());
};

export const decreaseBlockingCallCounter = (): AppThunk => dispatch => {
    dispatch(blockingCallCountSlice.actions.decrement());
};

export const newNotificationMessage = (notification: NotificationMessage): AppThunk => dispatch => {
    dispatch(messagesSlice.actions.push(notification));
};

export const clearNotificationMessages = (): AppThunk => dispatch => {
    dispatch(messagesSlice.actions.clear());
};
