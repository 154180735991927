import { httpService } from '../../../app/shared/httpWrapper';
import { appUrls } from '../../../common/appUrls';
import { ResourcesViewModel } from '../../../common/model/resources';
import { FrontSettings } from '../../../common/model/resources/lkSettings';
import { AppThunk } from '../../reducers';
import { dictionariesSlice } from '../../reducers/resources/dictionaries';
import { resourcesSettingsSlice } from '../../reducers/resources/settings';

export const getResources = (): AppThunk => async dispatch => {
    const resources = await httpService.get<ResourcesViewModel>(appUrls.api_get_resources);
    if (resources?.dictionaries) {
        dispatch(dictionariesSlice.actions.update(resources.dictionaries));
    }
};

export const getFrontSettings = (): AppThunk => async dispatch => {
    const frontSettings = await httpService.get<FrontSettings>(appUrls.api_get_front_settings);
    if (frontSettings) {
        dispatch(resourcesSettingsSlice.actions.update({ frontSettings }));
    }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkVersion = (version: number): AppThunk => async () => {
    // const currentVersion = getState().resources.version;
    // if (!currentVersion) {
    //     dispatch(setVersion(version));
    // } else if (version > currentVersion) {
    //     dispatch(setVersion(version));
    //     await dispatch(getResources());
    // }
    // TODO create implementation
};
