import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentsHistorySliceSelector = (state: StorageState) => state.payment.paymentsHistory;

export const paymentsHistoryListSelector = createSelector(
    paymentsHistorySliceSelector,
    payment => payment.list
);

export const paymentsHistoryTotalSelector = createSelector(
    paymentsHistorySliceSelector,
    payment => payment.total
);

export const paymentsHistoryLoadingSelector = createSelector(
    paymentsHistorySliceSelector,
    payment => payment.isLoading
);

export const paymentsHistoryLoadedSelector = createSelector(
    paymentsHistorySliceSelector,
    payment => payment.isLoaded
);
