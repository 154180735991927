import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessRule } from '../../../../../common/model/users/accessRule';
import { UserViewModel } from '../../../../../common/model/users/user/userViewModel';
import { ActionType } from '../../../../actions/model';

export interface ActiveUserAccessState {
    user: UserViewModel | null;
    accessRules: AccessRule[];
}

const initialState: ActiveUserAccessState = {
    user: null,
    accessRules: [],
};

export const activeUserAccessSlice = createSlice({
    name: '@@vp/users/users/active',
    initialState,
    reducers: {
        setActiveUser(state, action: PayloadAction<ActiveUserAccessState['user']>): void {
            state.user = action.payload;
        },
        setAccessRules(state, action: PayloadAction<AccessRule[]>): void {
            state.accessRules = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): ActiveUserAccessState => initialState,
    }
});


export const {
    setActiveUser,
    setAccessRules
} = activeUserAccessSlice.actions;
