import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BannerState {
    isClosed: boolean;
}

const initialState: BannerState = {
    isClosed: false
};

export const bannerSlice = createSlice({
    name: 'bannerEntitie',
    initialState,
    reducers: {
        setBannerIsClosed(state): void {
            state.isClosed = true;
        },
    }
});

export const {
    setBannerIsClosed
} = bannerSlice.actions;
