import { appUrls } from 'common/appUrls';
import { PaymentDetailViewModel } from 'common/model/payment/paymentDetailViewModel';
import { AppThunk } from 'redux/reducers';
import { clearPaymentDetails, setLoading, setPaymentDetailsList } from 'redux/reducers/payment/paymentDetails';
import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { modalNotificationsPush } from '../..';

export const getPaymentDetails = (paymentId: string, accountId?: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_payment_details, { paymentId }, { accountId });

    try {
        const data = await httpService.get<PaymentDetailViewModel[]>(route);
        dispatch(setPaymentDetailsList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке детализации платежа. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};

export const resetPaymentDetailsData = (): AppThunk => (dispatch) => {
    dispatch(clearPaymentDetails());
};
