import { accountsChargesSelectionSliceSelector } from '../../../selectors/accounts/chargesSelection/index';
import { accountChargesSelectionInitialDataMapper } from '../../../mappers/accounts/chargesSelection/index';
import { AppThunk } from '../../../reducers';
import { AccountWithMappedBalanceAndChargesData } from '../../../mappers/accounts/balanceAndCharges';
import { setAccountsChargesAmountSelection, setAccountsChargesActivitySelection } from '../../../reducers/accounts/chargesSelection';

export const createAccountChargesSelectionInitialData = (
    mappedAccounts: AccountWithMappedBalanceAndChargesData[]
): AppThunk => (dispatch, getState) => {
    const { amountSelection, activitySelection } = accountsChargesSelectionSliceSelector(getState());
    const createdInitialData = accountChargesSelectionInitialDataMapper(mappedAccounts);

    if (!Object.keys(amountSelection).length) {
        dispatch(setAccountsChargesAmountSelection(createdInitialData.amountSelection));
    }

    if (!Object.keys(activitySelection).length) {
        dispatch(setAccountsChargesActivitySelection(createdInitialData.activitySelection));
    }
};
