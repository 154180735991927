import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';


export interface AccountsInvoiceRequestState {
  isLoading: boolean;
}

const initialState: AccountsInvoiceRequestState = {
    isLoading: false,
};

export const accountsInvoiceRequestSlice = createSlice({
    name: '@@vp/accounts/invoiceRequest',
    initialState,
    reducers: {
        setInvoiceRequestLoading(state, action: PayloadAction<AccountsInvoiceRequestState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccountsInvoiceRequestState => initialState,
    }
});

export const {
    setInvoiceRequestLoading,
} = accountsInvoiceRequestSlice.actions;
