import { initialState } from './initialState';
import { Dictionaries } from '../../../../common/model/resources/dictionaries';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DictionariesState = Dictionaries;

export const dictionariesSlice = createSlice({
    name: '@@vp/resources/dictionaries',
    initialState,
    reducers: {
        update(state, action: PayloadAction<Dictionaries>): DictionariesState {
            return Object.assign({}, state, action.payload);
        }
    }
});
