import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const adminPaymentsHistorySliceSelector = (state: StorageState) => state.adminPayment.adminPaymentsHistory;

export const adminPaymentsHistoryListSelector = createSelector(
    adminPaymentsHistorySliceSelector,
    payment => payment.list
);

export const adminPaymentsHistoryTotalSelector = createSelector(
    adminPaymentsHistorySliceSelector,
    payment => payment.total
);

export const adminPaymentsHistoryLoadingSelector = createSelector(
    adminPaymentsHistorySliceSelector,
    payment => payment.isLoading
);

export const adminPaymentsHistoryLoadedSelector = createSelector(
    adminPaymentsHistorySliceSelector,
    payment => payment.isLoaded
);

export const adminPaymentLogsExcelLoadingSelector = createSelector(
    adminPaymentsHistorySliceSelector,
    payment => payment.isLoadingExcel
);
