import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';

export interface AutopaySettingsState {
    isLoading: boolean;
}

const initialState: AutopaySettingsState = {
    isLoading: false,
};

export const autopaySettingsSlice = createSlice({
    name: '@@vp/payment/autopaySettings',
    initialState,
    reducers: {
        setAutopaySettingsLoading(state, action: PayloadAction<AutopaySettingsState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AutopaySettingsState => initialState,
    }
});

export const {
    setAutopaySettingsLoading
} = autopaySettingsSlice.actions;
