import { getApiRoute } from '../../../../../app/shared/pipes';
import { UsersFindResponse } from '../../../../../common/model/users/user/usersFindResponse';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { Sort } from '../../../../../common/sort';
import { AppThunk } from '../../../../reducers';
import { setUsersList, setLoading } from '../../../../reducers/users/users/list';
import { modalNotificationsPush } from '../../../';


export const getAccessUsersList = (sort: Sort): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_users, { }, { ...sort });

    try {
        const data = await httpService.get<UsersFindResponse>(route);
        dispatch(setUsersList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка пользователей. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};
