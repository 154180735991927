import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../actions/model';

export interface EmailResendState {
    emailString: string;
}

const initialState: EmailResendState = {
    emailString: ''
};

export const emailResendSlice = createSlice({
    name: '@@vp/emailResend',
    initialState,
    reducers: {
        setEmailString(state, action: PayloadAction<EmailResendState['emailString']>): void {
            state.emailString = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): EmailResendState => initialState,
    }
});

export const {
    setEmailString,
} = emailResendSlice.actions;
