import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentSberPayCViewModel } from 'common/model/payment/paymentSberPayCViewModel';

export interface PaymentSberPayCallbackState {
  paymentSberPayCallbackLoading: boolean;
  paymentSberPayCallbackLoadingError: string | null;
  paymentSberPayCallbackCompleteLoading: boolean;
  paymentSberPayCallbackCompleteLoadingError: string | null;
  paymentSberPayCallbackResponse: PaymentSberPayCViewModel | null;
}

const initialState: PaymentSberPayCallbackState = {
    paymentSberPayCallbackLoading: false,
    paymentSberPayCallbackLoadingError: null,
    paymentSberPayCallbackCompleteLoading: false,
    paymentSberPayCallbackCompleteLoadingError: null,
    paymentSberPayCallbackResponse: null,
};

export const paymentSberPayCallbackSlice = createSlice({
    name: '@@vp/payment/createSberPay',
    initialState,
    reducers: {
        resetPayment: () => initialState,
        setPaymtneSberPayCallbackLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentSberPayCallbackLoading = action.payload;
        },
        setPaymentSberPayCallbackLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentSberPayCallbackLoadingError = action.payload;
        },
        setPaymentSberPayCallbackCompleteLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentSberPayCallbackCompleteLoading = action.payload;
        },
        setPaymentSberPayCallbackCompleteLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentSberPayCallbackCompleteLoadingError = action.payload;
        },
        setPaymentSberPayCallbackResponse: (state, action: PayloadAction<PaymentSberPayCViewModel | null>) => {
            state.paymentSberPayCallbackResponse = action.payload;
        },
    }
});

export const {
    resetPayment,
    setPaymtneSberPayCallbackLoading,
    setPaymentSberPayCallbackLoadingError,
    setPaymentSberPayCallbackCompleteLoading,
    setPaymentSberPayCallbackCompleteLoadingError,
    setPaymentSberPayCallbackResponse,
} = paymentSberPayCallbackSlice.actions;
