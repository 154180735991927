import { SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseReason, useSnackbar } from 'notistack';
import { controlsSnackbarSelector } from 'redux/selectors';
import { addDisplayed, removeDisplayed, removeSnackbar } from 'redux/actions';

export const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(controlsSnackbarSelector());
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect((): void => {
        notifications.notifications.forEach(({
            key,
            message,
            options = {},
            dismissed = false,
            title,
            icon,
            type,
            isClosed,
            action,
        }) => {
            if (dismissed) {
                closeSnackbar(key);
                dispatch(removeSnackbar(key));
                return;
            }

            if (notifications.displayed.includes(key)) {
                return;
            }

            //JSON.stringify для того что бы передавать props
            enqueueSnackbar(JSON.stringify({
                message,
                title,
                icon,
                type,
                isClosed,
                key,
            }), {
                key,
                ...options,
                onClose: (event: SyntheticEvent<any, Event> | null, reason: CloseReason) => {
                    if (options.onClose) {
                        options.onClose(event, reason, key);
                    }
                },
                action,
                onExited: (): void => {
                    dispatch(removeDisplayed(key));
                    dispatch(removeSnackbar(key));
                },
            });
            dispatch(addDisplayed(key));
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};
