import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../../reducers';

export const accountStatisticChargesReportSliceSelector = (state: StorageState) => state.accounts.statistic.chargesReport;

export const accountStatisticChargesReportSelectors = createSelector(
    accountStatisticChargesReportSliceSelector,
    report => report
);

export const accountStatisticPaymentsReportSliceSelector = (state: StorageState) => state.accounts.statistic.paymentsReport;

export const accountStatisticPaymentsReportSelectors = createSelector(
    accountStatisticPaymentsReportSliceSelector,
    report => report
);

export const accountStatisticEntriesReportSliceSelector = (state: StorageState) => state.accounts.statistic.entriesReport;

export const accountStatisticEntriesReportSelectors = createSelector(
    accountStatisticEntriesReportSliceSelector,
    report => report
);
