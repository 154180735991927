import { useEffect, useState } from 'react';
import { LoaderProps } from './model';
import { View } from './view';

const showLoaderAfterMs = 300;

export const Loader = ({ startShowLoaderDelayMs = showLoaderAfterMs, ...props }: LoaderProps) => {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowLoader(true);
        }, startShowLoaderDelayMs);

        return () => clearTimeout(timeout);
    }, [startShowLoaderDelayMs]);

    if (!showLoader) {
        return null;
    }

    return View({ ...props });
};
