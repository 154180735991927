import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { ClientAccountsRegistartionReport } from 'common/model/client/clientsAccountsRegistartionReport';

export interface AdminClientClientAccountsRegistrationState {
    data: ClientAccountsRegistartionReport | null;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean;
}

const initialState: AdminClientClientAccountsRegistrationState = {
    data: null,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminClientAccountsRegistrationReportsSlice = createSlice({
    name: '@@vp/clients/reports/accounts_registration/list',
    initialState,
    reducers: {
        setReportData(state, action: PayloadAction<ClientAccountsRegistartionReport>): void {
            state.data = action.payload;
            state.isLoaded = true;
        },
        setLoadingReport(state, action: PayloadAction<AdminClientClientAccountsRegistrationState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoadingReportLogsExcel(state, action: PayloadAction<AdminClientClientAccountsRegistrationState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearReport(state): void {
            state.data = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminClientClientAccountsRegistrationState => initialState,
    }
});

export const {
    setReportData,
    setLoadingReport,
    clearReport,
    setLoadingReportLogsExcel
} = adminClientAccountsRegistrationReportsSlice.actions;
