import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TechnicalWorkingStatesResponse } from '../../../../common/model/unauthorized/technicalWorking';

export interface TechnicalWorkingState {
    states: TechnicalWorkingStatesResponse | null;
}

const initialState: TechnicalWorkingState = {
    states: null
};

export const unauthorizedTechnicalWorkingSlice = createSlice({
    name: '@@vp/unauthorizedTechnicalWorking',
    initialState,
    reducers: {
        setTechnicalWorkingStates(state, action: PayloadAction<TechnicalWorkingStatesResponse | null>): void {
            state.states = action.payload;
        },
    }
});

export const {
    setTechnicalWorkingStates
} = unauthorizedTechnicalWorkingSlice.actions;
