import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientRegistrationsReportViewModel } from '../../../../common/model/client/clientsReportsRegistrations';
import { ActionType } from '../../../actions/model';

export interface AdminClientsReportsRegistrationsState {
    data: ClientRegistrationsReportViewModel | null;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean;
}

const initialState: AdminClientsReportsRegistrationsState = {
    data: null,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminClientsReportsRegistrationsSlice = createSlice({
    name: '@@vp/clients/reports/list',
    initialState,
    reducers: {
        setClientsReportsRegistrationsData(state, action: PayloadAction<ClientRegistrationsReportViewModel>): void {
            state.data = action.payload;
            state.isLoaded = true;
        },
        setLoadingClientsReportsRegistrations(state, action: PayloadAction<AdminClientsReportsRegistrationsState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoadingClientsReportsRegistrationsLogsExcel(state, action: PayloadAction<AdminClientsReportsRegistrationsState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearClientsReportsRegistrations(state): void {
            state.data = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminClientsReportsRegistrationsState => initialState,
    }
});

export const {
    setClientsReportsRegistrationsData,
    setLoadingClientsReportsRegistrations,
    clearClientsReportsRegistrations,
    setLoadingClientsReportsRegistrationsLogsExcel
} = adminClientsReportsRegistrationsSlice.actions;
