import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentOrderViewModel } from '../../../../common/model/payment/order/paymentOrderViewModel';
import { PaymentOrderResponse } from '../../../../common/model/payment/paymentOrderResponse';
import { ActionType } from '../../../actions/model';

export interface AdminPaymentsOrderState {
    list: PaymentOrderViewModel[] | null;
    total: number;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean,
}

const initialState: AdminPaymentsOrderState = {
    list: null,
    total: 0,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminPaymentsOrderSlice = createSlice({
    name: '@@vp/users/users/list',
    initialState,
    reducers: {
        setPaymentsOrderList(state, action: PayloadAction<PaymentOrderResponse>): void {
            state.list = action.payload.data;
            state.total = action.payload.total;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<AdminPaymentsOrderState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoadingPaymentsOrderLogsExcel(state, action: PayloadAction<AdminPaymentsOrderState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearPaymentsOrder(state): void {
            state.list = null;
            state.total = 0;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminPaymentsOrderState => initialState,
    }
});

export const {
    setPaymentsOrderList,
    setLoading,
    clearPaymentsOrder,
    setLoadingPaymentsOrderLogsExcel
} = adminPaymentsOrderSlice.actions;
