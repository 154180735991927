import { combineReducers } from 'redux';
import { dictionariesSlice, DictionariesState } from './dictionaries';
import { resourcesSettingsSlice, ResourcesSettingsState } from './settings';

export interface ResourcesState {
    settings: ResourcesSettingsState;
    dictionaries: DictionariesState;
}

export const resources = combineReducers<ResourcesState>({
    settings: resourcesSettingsSlice.reducer,
    dictionaries: dictionariesSlice.reducer,
});
