import { PaymentCreateInput } from '../../../../common/model/payment/paymentCreateInput';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { setOrderCreateLoading, setOrderCreateLoadingError, setOrderCompleteLoading, setOrderCompleteLoadingError } from '../../../reducers/payment/paymentOrder';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';
import { paymentOrderSliceSelector } from '../../../selectors/payment/paymentOrder';
import { PaymentOrderViewModel } from '../../../../common/model/payment/order/paymentOrderViewModel';
import { generatePath } from 'react-router';

export const createOrder = (
    requestBody: PaymentCreateInput,
    isUnauthorized: boolean,
    onComplete: (order: PaymentOrderViewModel) => void,
): AppThunk => async (dispatch, getState) => {
    const { orderCreateLoading } = paymentOrderSliceSelector(getState());

    if (orderCreateLoading) {
        return;
    }

    dispatch(setOrderCreateLoadingError(null));
    dispatch(setOrderCreateLoading(true));

    try {
        if (isUnauthorized) {
            const result = await httpService.post<PaymentOrderViewModel>(
                appUrls.api_post_unauthorized_payment_order,
                requestBody
            );
            onComplete?.(result);
        } else {
            const result = await httpService.post<PaymentOrderViewModel>(
                appUrls.api_post_payment_order,
                requestBody
            );
            onComplete?.(result);
        }
    } catch {
        const errorMessage = 'Произошла ошибка при оплате счета. Попробуйте позже';
        dispatch(setOrderCreateLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));

        dispatch(setOrderCreateLoading(false));
    }
};

export const completeOrder = (
    orderId: string,
    onComplete?: (order: PaymentOrderViewModel) => void,
    onFailed?: () => void,
    isUnauthorized?: boolean,
): AppThunk => async (dispatch, getState) => {
    const { orderCompleteLoading } = paymentOrderSliceSelector(getState());

    if (orderCompleteLoading) {
        return;
    }

    dispatch(setOrderCompleteLoadingError(null));
    dispatch(setOrderCompleteLoading(true));

    try {
        if (isUnauthorized) {
            const result = await httpService.put<PaymentOrderViewModel>(
                generatePath(appUrls.api_put_unauthorized_payment_order_complete, { orderId })
            );
            onComplete?.(result);
        } else {
            const result = await httpService.put<PaymentOrderViewModel>(
                generatePath(appUrls.api_put_payment_order_complete, { orderId })
            );
            onComplete?.(result);
        }
    } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);

        const errorMessage = 'Произошла ошибка при подтверждении оплаты. Попробуйте позже';
        dispatch(setOrderCompleteLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));

        onFailed?.();
    }

    dispatch(setOrderCompleteLoading(false));
};
