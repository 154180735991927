import { Button } from '../../shared/components/button';
import { Alert } from 'antd';
import React from 'react';
import { ImpersonationViewProps } from './model';
import './styles.scss';

export const View = (props: ImpersonationViewProps) => {
    const { isImpersonation, clientProfile, onReturnAdminPanel } = props;
    if (!isImpersonation) {
        return null;
    }
    // eslint-disable-next-line max-len
    const message = `Вход осуществлен под пользователем: ${clientProfile?.firstName} ${clientProfile?.middleName} ${clientProfile?.lastName}`;
    return (
        <Alert
            message={message}
            type='info'
            className="impersonationAlert"
            action={
                <Button variant='link' value='Вернуться в интерфейс администратора' onClick={onReturnAdminPanel} />
            }
        />
    );
};
