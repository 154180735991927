
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountDetailsViewModel } from '../../../../common/model/account/accountDetailsViewModel';
import { ActionType } from '../../../actions/model';
import { MeterEntriesValidateSuccess } from '../../../../common/model/meterReadings/meterEntriesValidateSuccess';


export interface AccountsState {
  accounts: AccountDetailsViewModel[] | null;
  accountsLoading: boolean;
  accountsLoaded: boolean;
  accountsLoadingError: string | null;
  meterEntriesSending: boolean;
  savedPaynowMeterEntries: MeterEntriesValidateSuccess[] | null;
}

const initialState: AccountsState = {
    accounts: null,
    accountsLoading: false,
    accountsLoaded: false,
    accountsLoadingError: null,
    meterEntriesSending: false,
    savedPaynowMeterEntries: null
};

export const accountsWithMetersAndEntriesSlice = createSlice({
    name: '@@vp/accounts/metersAndEntries',
    initialState,
    reducers: {
        setAccountsLoading(state, action: PayloadAction<AccountsState['accountsLoading']>): void {
            state.accountsLoading = action.payload;
        },
        resetAccounts(state): void {
            state.accounts = initialState.accounts;
            state.accountsLoaded = initialState.accountsLoaded;
            state.accountsLoading = initialState.accountsLoading;
            state.accountsLoadingError = initialState.accountsLoadingError;
        },
        setAccounts(state, action: PayloadAction<AccountDetailsViewModel[]>): void {
            state.accounts = action.payload;
            state.accountsLoaded = true;
            state.accountsLoading = false;
        },
        setAccountsLoadingError(state, action: PayloadAction<AccountsState['accountsLoadingError']>): void {
            state.accountsLoadingError = action.payload;
        },
        setMeterEntriesSending(state, action: PayloadAction<AccountsState['meterEntriesSending']>): void {
            state.meterEntriesSending = action.payload;
        },
        setSavedPaynowMeterEntries(state, action: PayloadAction<AccountsState['savedPaynowMeterEntries']>): void {
            state.savedPaynowMeterEntries = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccountsState => initialState,
    }
});

export const {
    resetAccounts,
    setAccounts,
    setAccountsLoading,
    setAccountsLoadingError,
    setMeterEntriesSending,
    setSavedPaynowMeterEntries
} = accountsWithMetersAndEntriesSlice.actions;
