import { View } from './view';
import { useSelector } from 'react-redux';
import { isPasswordTemporarySelector } from 'redux/selectors';

export const PasswordTemporaryAlert = () => {
    const isPasswordTemporary = useSelector(isPasswordTemporarySelector);
    if (!isPasswordTemporary) {
        return null;
    }

    return View();
};
