import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentOrderState {
  orderCreateLoading: boolean;
  orderCreateLoadingError: string | null;
  orderCompleteLoading: boolean;
  orderCompleteLoadingError: string | null;
}

const initialState: PaymentOrderState = {
    orderCreateLoading: false,
    orderCreateLoadingError: null,
    orderCompleteLoading: false,
    orderCompleteLoadingError: null,
};

export const paymentOrderSlice = createSlice({
    name: '@@vp/payment/paymentOrder',
    initialState,
    reducers: {
        resetPayment(state): void {
            state.orderCreateLoading = initialState.orderCreateLoading;
            state.orderCreateLoadingError = initialState.orderCreateLoadingError;
        },
        setOrderCreateLoading(state, action: PayloadAction<PaymentOrderState['orderCreateLoading']>): void {
            state.orderCreateLoading = action.payload;
        },
        setOrderCreateLoadingError(state, action: PayloadAction<PaymentOrderState['orderCreateLoadingError']>): void {
            state.orderCreateLoadingError = action.payload;
        },

        setOrderCompleteLoading(state, action: PayloadAction<PaymentOrderState['orderCompleteLoading']>): void {
            state.orderCompleteLoading = action.payload;
        },
        setOrderCompleteLoadingError(state, action: PayloadAction<PaymentOrderState['orderCompleteLoadingError']>): void {
            state.orderCompleteLoadingError = action.payload;
        },
    }
});

export const {
    resetPayment,
    setOrderCreateLoading,
    setOrderCreateLoadingError,
    setOrderCompleteLoading,
    setOrderCompleteLoadingError,
} = paymentOrderSlice.actions;
