import { modalNotificationsPush } from '..';
import { httpService } from '../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../app/shared/pipes/routing';
import { appUrls } from '../../../common/appUrls';
import { AppThunk } from '../../reducers';
import {
    clearNotifications,
    setLoadingNotifications,
    setNotificationsList,
    updateViewedNotification,
    updateViewedAllNotification,
    deleteNotification,
    deleteAllNotifications
} from '../../reducers/notifications';
import { ClientNotificationViewModel } from '../../../../../common/model/notification/clientNotificationViewModel';

export const getNotifications = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoadingNotifications(true));
    const route = getApiRoute(appUrls.api_get_client_notifications);

    try {
        const data = await httpService.get<ClientNotificationViewModel[]>(route);
        dispatch(setNotificationsList(data));

    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке оповещений. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoadingNotifications(false));
};

export const viewNotification = (id:string): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.api_put_client_notification_viewed, { id });

    try {
        await httpService.put<void>(route);
        dispatch(updateViewedNotification(id));

    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка. Попробуйте позже',
            type: 'error',
        }));
    }
};

export const viewAllNotifications = (ids:string[]): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.api_put_client_all_notifications_viewed);

    try {
        await httpService.put<void>(route, ids);
        dispatch(updateViewedAllNotification());

    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка. Попробуйте позже',
            type: 'error',
        }));
    }
};

export const removeNotification = (id:string): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.api_delete_client_notification_item, { id });

    try {
        await httpService.delete<void>(route);
        dispatch(deleteNotification(id));

    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка удаления оповещения. Попробуйте позже',
            type: 'error',
        }));
    }
};

export const removeAllNotifications = (ids:string[]): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.api_delete_all_client_notifications);

    try {
        await httpService.delete<void>(route, ids);
        dispatch(deleteAllNotifications());

    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка удаления оповещений. Попробуйте позже',
            type: 'error',
        }));
    }
};

export const resetNotifications = (): AppThunk => (dispatch) => {
    dispatch(clearNotifications());
};
