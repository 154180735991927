export const DateFormats = {
    paymentCardExpiration: 'yyyyMM',
    dateTimeFormat: 'DD.MM.YYYY HH:mm',
    dateFullTimeFormat: 'DD.MM.YYYY HH:mm:ss',
    dateFullFormat: 'DD.MM.YYYY',
    dateFormat: 'dd.MM.yyy',
    oldLkDateFormat: 'dd.MM.yy',
    timeFormat: 'HH:mm',
    dateFormatNative: 'yyyy-MM-dd',
    yearMonthDayFormat: 'YYYY-MM-DD',
    dateTimeInputFormat: 'dd.MM.yyyy HH:mm',
    dateInputFormat: 'DD.MM.yyyy',
    dateExportFormat: 'DD.MM.YYYY',
    apiDateFormat: 'YYYY-MM-DDTHH:mm:ssZ',
    dateFullTimeSQL: 'YYYY-MM-DD HH:mm:ss',
    apiDateFormatWithoutTZ: 'YYYY-MM-DDTHH:mm:ss',
    month: 'LLLL',
    dateTimeMomentFormat: 'YYYY-MM-DD',
    monthYear: 'MMM, YYYY',
    monthFormat: 'MMM',
    montReductionYear: 'MMM, YY',
    dayMonth: 'DD.MM',
    dateTimeBillingFormat: 'dd.MM.yyyy H:mm:ss',
    dateTimeAtolFormat: 'dd.MM.yyyy HH:mm:ss',
    dateTimeBillingExportFormat: 'yyyy-MM-dd HH:mm:ss.0',
    dateBillingFormat: 'dd.MM.yyyy',
    year: 'yyyy',
    notificationDateFormat: 'DD MMM H:mm',
    notificationDateFormatYear: 'DD MMM yyy H:mm',
    monthYearSeparatedWithDot: 'MM.yyyy',
    monthYearFormat: 'MM. YYYY',
    dayFormat: 'DD',
    dayMonthFormat: 'dd.MM',
};

export const hoursInDay = 24;
export const minutesInHour = 60;
export const SECONDS_IN_MINUTE = 60;
export const MS_IN_SECOND = 1000;
export const MAX_MONTH_NUMBER = 11;
export const msInSecond = 1000;

export const ONE_MONTH_COUNT = 1;
export const TWENTY_SIX_COUNT = 26;
export const TWENTY_FIVE_COUNT = 25;
