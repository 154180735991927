import { createSelector } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { uniqWith } from 'lodash';
import { DateFormats } from '../../../common/constants/date';
import { StorageState } from '../../reducers';

export const meterReadingsIsupSliceSelector = (state: StorageState) => state.meterReadingsIsup;

export const meterActiveEnergyReadingData = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => uniqWith(meterReadingsIsup.meterActiveEnergyReadingData.filter(item => item.isDaily), (a, b) => {
        return format(new Date(a.readingDate), DateFormats.dateFormat) === format(new Date(b.readingDate), DateFormats.dateFormat);
    })
);
export const meterActiveEnergyReadingDataTable = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterActiveEnergyReadingData
);
export const meterActiveEnergyReadingDataLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterActiveEnergyReadingDataLoading
);

export const meterIncrementalEnergyReadingData = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterIncrementalEnergyReadingData
);

export const meterIncrementalEnergyReadingDataLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterIncrementalEnergyReadingDataLoading
);

export const meterIsupInfo = (foreignId: string | undefined) => createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterIsupInfo.find(item => item.foreignId === foreignId?.toString())
);

export const meterIsupInfoLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.isMeterIsupInfoLoading
);

export const meterJournalExcelLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterJournalExcelLoading
);

export const meterReadingDataExcelLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.meterReadingDataExcelLoading
);

export const sendMeterCurrentReadingsOrderLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.sendMeterCurrentReadingsOrderLoading
);

export const meterIsupEnergyReadingData = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.isupMeterEnergyReadingData
);

export const meterIsupEnergyReadingLoading = createSelector(
    meterReadingsIsupSliceSelector,
    meterReadingsIsup => meterReadingsIsup.isupMeterEnergyReadingLoading
);
