import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

export const YandexMetrikaService: React.FC = () => {
    // Do not load yandex metrika while in development mode
    if (process.env.NODE_ENV === 'development') {
        return null;
    }

    const METER_NUMBER = Number(process.env.REACT_APP_YM_METER_NUMBER);

    return <YMInitializer accounts={[METER_NUMBER]} options={{ webvisor: true }} />;
};
