import { AppThunk } from '../../reducers';
import { SnackbarNotification, snackbarSlice } from '../../reducers/controls/snackbar';
import { VariantType } from 'notistack';
const DEFAULT_MILLISECONDS = 5000;

const snackbarType = {
    red: 'error',
    white: 'info',
    green: 'success',
    dark: 'warning',
};

const getAutoHideDuration = (delay: number | undefined) => {
    if (delay === undefined) {
        return DEFAULT_MILLISECONDS;
    } else if (delay === 0) {
        return null;
    } else {
        return delay;
    }
};

const getType = (type: string): VariantType => {
    return snackbarType[type as keyof typeof snackbarType] as VariantType;
};

export const createSnackbar = (notification: Omit<SnackbarNotification, 'key'> & Partial<Pick<SnackbarNotification, 'key'>>): AppThunk => dispatch => {
    const key = notification.options && notification.options.key;
    const { options } = notification;
    if (options) {
        options.autoHideDuration = getAutoHideDuration(notification.delay);
        options.variant = getType(notification.type);
    } else {
        const newOptions = {
            variant: getType(notification.type),
            autoHideDuration: getAutoHideDuration(notification.delay),
        };
        notification.options = { ...newOptions };
    }

    dispatch(snackbarSlice.actions.add({
        ...notification,
        key: key || new Date().getTime() + Math.random(),
    }));
};

export const closeSnackbar = (key: string): AppThunk => dispatch => {
    dispatch(snackbarSlice.actions.close({ dismissAll: !key, key }));
};

export const removeSnackbar = (key: string | number): AppThunk => dispatch => {
    dispatch(snackbarSlice.actions.remove({ key }));
};

export const addDisplayed = (key: string | number): AppThunk => dispatch => {
    dispatch(snackbarSlice.actions.addDisplayed(key));
};
export const removeDisplayed = (key: string | number): AppThunk => dispatch => {
    dispatch(snackbarSlice.actions.removeDisplayed(key));
};
