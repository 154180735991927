import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import '../styles/index.scss';
import { history, store } from '../redux/store/configureStore';
import { ModalNotifier } from './shared/components/modalNotifier';
import { CurrentDivisionService } from './shared/components/currentDivisionService';
import { SnackbarMessage } from './content/snackbar/message';
import { Notifier } from './content/snackbar/notifier';
import { ImpersonationAlert } from './content/impersonation';
import { DemoModeAlert } from './content/demoModeAlert';
import { RouterScrollService } from './content/routerScrollService';
import { YandexMetrikaService } from './content/yandexMetrikaService';
import { PasswordTemporaryAlert } from './shared/components/passwordTemporaryAlert';
import { Loader } from './shared/components/loader';
import { routerConfig } from './routing';
import { TechnicalWorkingAlert } from './content/technicalWorking';

export const View = () => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Provider store={store}>
            <ImpersonationAlert />
            <TechnicalWorkingAlert />
            <DemoModeAlert />
            <PasswordTemporaryAlert />
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                content={(_key, message) => <SnackbarMessage message={message} />}
            >
                <Notifier />
                <ConnectedRouter history={history}>
                    <ConfigProvider locale={ruRU}>
                        <Suspense fallback={<Loader startShowLoaderDelayMs={0} />}>
                            <Switch>
                                {routerConfig.map(route => (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        component={route.component}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                        <ModalNotifier />
                        <CurrentDivisionService />
                        <RouterScrollService />
                    </ConfigProvider>
                </ConnectedRouter>
            </SnackbarProvider>
            <YandexMetrikaService />
        </Provider>
    );
};
