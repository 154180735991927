import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentDetailViewModel } from '../../../../common/model/payment/paymentDetailViewModel';
import { ActionType } from '../../../actions/model';

export interface PaymentsDetailsState {
    list: PaymentDetailViewModel[] | null;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: PaymentsDetailsState = {
    list: null,
    isLoading: false,
    isLoaded: false,
};

export const paymentDetailsSlice = createSlice({
    name: '@@vp/payment/paymentDetails',
    initialState,
    reducers: {
        setPaymentDetailsList(state, action: PayloadAction<PaymentDetailViewModel[]>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<PaymentsDetailsState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        clearPaymentDetails(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): PaymentsDetailsState => initialState,
    }
});

export const {
    setPaymentDetailsList,
    setLoading,
    clearPaymentDetails
} = paymentDetailsSlice.actions;
