import { adminNotifications } from './adminNotifications';
import { adminAccount } from './adminAccount';
import { adminSettingsSlice } from './adminSettings';
import { passwordResetSlice } from './passwordReset';
import { otpSlice } from './otp';
import { client } from './client';
import { accounts } from './accounts';
import { Action, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { ThunkAction } from 'redux-thunk';
import { payment } from './payment';
import { adminPayment } from './adminPayment';
import { resources } from './resources';
import { controls } from './controls';
import { emailResendSlice } from './emailResend';
import { httpWrapper } from './httpWrapper';
import { meterReadingsSlice } from './meterReadings';
import { passwordResetRequestSlice } from './passwordResetRequest';
import { phoneConfirmSlice } from './phoneConfirm';
import { users } from './users';
import { meterReadingsIsupSlice } from './isup';
import { historyEntriesSlice } from './admin';
import { impersonationSlice } from './impersonation';
import { adminReports } from './adminReports';
import { notificationsSlice } from './notifications';
import { notificationsSendingSlice } from './notificationsSending';
import { app } from './app';
import { tickets } from './tickets';
import { unauthorizedAccountMetersSlice } from './unauthorized/entries';
import { unauthorizedPaymentSlice } from './unauthorized/payment';
import { unauthorizedConfirmPaymentRequestSlice } from './unauthorized/confirmPayment';
import { adminServicesSelectionSlice, adminServicesSlice } from './admin/services';
import { accountDeleteSlice } from './accountDelete';
import { bannerSlice } from './banner';
import { unauthorizedTechnicalWorkingSlice } from './unauthorized/technicalWorking';

export const createRootReducer = (history: History) => combineReducers({
    app,
    banner: bannerSlice.reducer,
    router: connectRouter(history),
    httpWrapper,
    controls,
    resources,
    accounts,
    payment,
    adminReports,
    adminPayment,
    adminNotifications,
    meterReadings: meterReadingsSlice.reducer,
    meterReadingsIsup: meterReadingsIsupSlice.reducer,
    users,
    client,
    phoneConfirm: phoneConfirmSlice.reducer,
    emailConfirm: emailResendSlice.reducer,
    otp: otpSlice.reducer,
    passwordReset: passwordResetSlice.reducer,
    accountDelete: accountDeleteSlice.reducer,
    passwordResetRequest: passwordResetRequestSlice.reducer,
    historyEntries: historyEntriesSlice.reducer,
    adminAccount,
    adminSettings: adminSettingsSlice.reducer,
    impersonation: impersonationSlice.reducer,
    notifications: notificationsSlice.reducer,
    notificationsSending: notificationsSendingSlice.reducer,
    tickets,
    unauthorizedEntries: unauthorizedAccountMetersSlice.reducer,
    unauthorizedPayment: unauthorizedPaymentSlice.reducer,
    unauthorizedConfirmPayment: unauthorizedConfirmPaymentRequestSlice.reducer,
    adminServices: adminServicesSlice.reducer,
    adminServicesSelection: adminServicesSelectionSlice.reducer,
    unauthorizedTechnicalWorking: unauthorizedTechnicalWorkingSlice.reducer,
});

export type StorageState = ReturnType<ReturnType<typeof createRootReducer>>;

export type AppThunk = ThunkAction<void, StorageState, null, Action<string>>;

type ThunkActionReturnType = Promise<any> | Promise<void> | void;

export type BaseDispatch = (...args: any[]) => ThunkActionReturnType;
