import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VisuallyImpairedSettings, VIFontSize, VILetterSpacing, VITheme, VIFontType } from 'redux/models/app/visuallyImpaired';
import { LocalStorage } from 'app/shared/constants';

const initState = (): VisuallyImpairedSettings => {
    const defaultInitialState = {
        isMobileMenuOpen: false,
        status: false,
        fontSize: VIFontSize.Small,
        letterSpacing: VILetterSpacing.Normal,
        fontType: VIFontType.SansSerif,
        theme: VITheme.White,
        image: true,
    };

    const preserveInitialState = localStorage.getItem(LocalStorage.viSettings);
    if (preserveInitialState) {
        try {
            return { ...defaultInitialState, ...JSON.parse(preserveInitialState) };
        } catch {
            return defaultInitialState;
        }
    }

    return defaultInitialState;
};

const saveStateToLS = (state: VisuallyImpairedSettings): void => {
    if (state.status) {
        localStorage.setItem(LocalStorage.viSettings, JSON.stringify({
            status: state.status,
            fontSize: state.fontSize,
            letterSpacing: state.letterSpacing,
            fontType: state.fontType,
            theme: state.theme,
            image: state.image,
        }));
    } else {
        localStorage.removeItem(LocalStorage.viSettings);
    }
};

export const visuallyImpairedSettings = createSlice({
    name: '@@vp/app/visuallyImpaired',
    initialState: initState(),
    reducers: {
        toggleVIStatus(state) {
            state.status = !state.status;
            saveStateToLS(state);
        },
        setVIStatus(state, { payload }: PayloadAction<boolean>) {
            state.status = payload;
            saveStateToLS(state);
        },
        setVIFontSize(state, { payload }: PayloadAction<VIFontSize>) {
            state.fontSize = payload;
            saveStateToLS(state);
        },
        setVITheme(state, { payload }: PayloadAction<VITheme>) {
            state.theme = payload;
            saveStateToLS(state);
        },
        setVIImages(state, { payload }: PayloadAction<boolean>) {
            state.image = payload;
            saveStateToLS(state);
        },
        setVILetterSpacing(state, { payload }: PayloadAction<VILetterSpacing>) {
            state.letterSpacing = payload;
            saveStateToLS(state);
        },
        setVIFontType(state, { payload }: PayloadAction<VIFontType>) {
            state.fontType = payload;
            saveStateToLS(state);
        },
        setVIMobileMenuVisibility(state, { payload }: PayloadAction<boolean>) {
            state.isMobileMenuOpen = payload;
        },
    }
});

export const {
    toggleVIStatus,
    setVIStatus,
    setVIFontSize,
    setVITheme,
    setVIImages,
    setVILetterSpacing,
    setVIFontType,
    setVIMobileMenuVisibility
} = visuallyImpairedSettings.actions;
