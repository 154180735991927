import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountViewModel } from '../../../common/model/account/accountViewModel';
import { HistoriesMeterEntries } from '../../../../../common/model/meterReadings/historyMeterEntries';
import { AccountWithAddressAndServices } from '../../../common/model/account/accountWithAddressAndServices';
import { HistoryMeterEntries } from '../../../../../common/model/admin/historyMeterEntries';
import { ClientAdminImpersonationListResponse } from '../../../common/model/client/clientAdminImpersonationListResponse';

export interface HistoryEntriesState {
    accounts: AccountViewModel[];
    account: AccountWithAddressAndServices | null;
    historyMeterEntriesTotal: number;
    historyMeterEntries: HistoriesMeterEntries[];
    accountsLoading: boolean;
    accountLoading: boolean;
    isLoadingExcel: boolean;
    clients: ClientAdminImpersonationListResponse | null;
    isClientsLoading: boolean;
}

const initialState: HistoryEntriesState = {
    accounts: [],
    account: null,
    historyMeterEntriesTotal: 0,
    accountsLoading: false,
    accountLoading: false,
    historyMeterEntries: [],
    isLoadingExcel: false,
    clients: null,
    isClientsLoading: false,
};

export const historyEntriesSlice = createSlice({
    name: '@@wh/historyEntries',
    initialState,
    reducers: {
        setSearchAccountsLoading(state, action: PayloadAction<HistoryEntriesState['accountsLoading']>): void {
            state.accountsLoading = action.payload;
        },
        setSearchAccounts(state, action: PayloadAction<HistoryEntriesState['accounts']>): void {
            state.accounts = action.payload;
        },
        setAdminHistoryMeterEntries(state, action: PayloadAction<HistoryMeterEntries>): void {
            state.historyMeterEntries = action.payload.data;
            state.historyMeterEntriesTotal = action.payload.total;
        },
        resetAdminHistoryMeterEntries(state): void {
            state.historyMeterEntries = [];
            state.historyMeterEntriesTotal = 0;
        },
        setAdminHistoryAccountInfo(state, action: PayloadAction<HistoryEntriesState['account']>): void {
            state.account = action.payload;
        },
        setAdminHistoryAccountLoading(state, action: PayloadAction<HistoryEntriesState['accountLoading']>): void {
            state.accountLoading = action.payload;
        },
        setLoadingExel(state, action: PayloadAction<HistoryEntriesState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        setClientsLoading(state, action: PayloadAction<HistoryEntriesState['isClientsLoading']>): void {
            state.isClientsLoading = action.payload;
        },
        setClients(state, action: PayloadAction<HistoryEntriesState['clients']>): void {
            state.clients = action.payload;
        }
    }
});

export const {
    setSearchAccounts,
    setSearchAccountsLoading,
    setAdminHistoryMeterEntries,
    setAdminHistoryAccountInfo,
    setAdminHistoryAccountLoading,
    resetAdminHistoryMeterEntries,
    setLoadingExel,
    setClientsLoading,
    setClients
} = historyEntriesSlice.actions;
