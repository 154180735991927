import { accountStatisticBalanceSlice } from './balance';
import { accountStatisticChargesSlice } from './charges';
import { combineReducers } from 'redux';
import { historyEntriesReportSlice } from './report/entries';
import { historyChargesReportSlice } from './report/charges';
import { historyPaymentsReportSlice } from './report/payments';

export const accountStatistic = combineReducers({
    balance: accountStatisticBalanceSlice.reducer,
    charges: accountStatisticChargesSlice.reducer,
    entriesReport: historyEntriesReportSlice.reducer,
    chargesReport: historyChargesReportSlice.reducer,
    paymentsReport: historyPaymentsReportSlice.reducer,
});
