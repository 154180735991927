
import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentSberPayCreateSliceSelector = (state: StorageState) => state.payment.paymentSberPayCreate;

export const paymentSberPayCreateLoadingSelector = createSelector(
    paymentSberPayCreateSliceSelector,
    payment => payment.paymentSberPayCreateLoading
);

export const paymentSberPayCreateLoadingErrorSelector = createSelector(
    paymentSberPayCreateSliceSelector,
    payment => payment.paymentSberPayCreateLoadingError
);

export const paymentSberPayCompleteLoadingSelector = createSelector(
    paymentSberPayCreateSliceSelector,
    payment => payment.paymentSberPayCompleteLoading
);

export const paymentSberPayCompleteLoadingErrorSelector = createSelector(
    paymentSberPayCreateSliceSelector,
    payment => payment.paymentSberPayCompleteLoadingError
);

export const paymentSberPayResponseSelector = createSelector(
    paymentSberPayCreateSliceSelector,
    payment => payment.paymentSberPayResponse
);
