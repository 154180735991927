import { createAccountChargesSelectionInitialData } from '../chargesSelection';
import { accountBalanceAndChargesDataMapper } from '../../../mappers/accounts/balanceAndCharges';
import { accountsWithBalanceAndChargesSlice } from '../../../reducers/accounts/balanceAndCharges';
import { modalNotificationsPush } from '../../controls';
import { AccountWithBalanceAndChargesViewModel } from '../../../../common/model/account/accountWithBalanceAndChargesViewModel';
import { accountsWithBalanceAndChargesSliceSelector, clientCurrentRegionSelector } from '../../../selectors';
import { AppThunk } from '../../../reducers';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { getApiRoute } from '../../../../app/shared/pipes';

export const getAccountsWithBalanceAndCharges = (): AppThunk => async (dispatch, getState) => {
    const { isLoading } = accountsWithBalanceAndChargesSliceSelector(getState());
    const currentRegion = clientCurrentRegionSelector(getState());

    if (isLoading) {
        return;
    }

    dispatch(accountsWithBalanceAndChargesSlice.actions.fetchStart());

    try {
        const endpointURL = getApiRoute(
            appUrls.api_get_client_accounts_with_balance_and_charges,
            {},
            { region: currentRegion || undefined },
        );
        const result = await httpService.get<AccountWithBalanceAndChargesViewModel[]>(endpointURL);
        const mappedResult = result.map(accountBalanceAndChargesDataMapper);

        dispatch(createAccountChargesSelectionInitialData(mappedResult));

        dispatch(accountsWithBalanceAndChargesSlice.actions.fetchSuccess({ data: mappedResult }));
    } catch (err: any) {
        const errorMessage = 'Произошла ошибка при загрузке баланса и начислений. Попробуйте позже';
        // eslint-disable-next-line no-console
        console.error('Ошибка при загрузке баланса и начислений:', err?.message);
        dispatch(accountsWithBalanceAndChargesSlice.actions.fetchFailure(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));
    }
};
