import { combineReducers } from 'redux';
import { activeUserAccessSlice, ActiveUserAccessState } from './active';
import { usersListAccessSlice, UsersListAccessState } from './list';
import { userEditableSlice, UserEditableState } from './editable';

export interface UsersAccessState {
    active: ActiveUserAccessState;
    editable: UserEditableState;
    list: UsersListAccessState;
}

export const usersAccessSlice = combineReducers<UsersAccessState>({
    active: activeUserAccessSlice.reducer,
    editable: userEditableSlice.reducer,
    list: usersListAccessSlice.reducer,
});
