import { HttpWrapperOptions } from './../../../../../app/shared/httpWrapper/options';
import { generatePath } from 'react-router';
import { accountsClaimsDocumentSlice } from './../../../../reducers/accounts/claims/document/index';
import { accountsClaimsDocumentSliceSelector } from './../../../../selectors/accounts/claims/document';
import { modalNotificationsPush } from '../../../controls/modalNotifications';
import { AppThunk } from '../../../../reducers';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { downloadFile } from '../../../../../app/shared/pipes';

export const getAccountClaimDocument = (claimId: string): AppThunk => async (dispatch, getState) => {
    const { isLoading } = accountsClaimsDocumentSliceSelector(getState());

    if (isLoading) {
        return;
    }

    dispatch(accountsClaimsDocumentSlice.actions.setLoading(true));

    try {
        const options = new HttpWrapperOptions();
        options.ResponseType('blob');

        const data = await httpService.get<Blob>(
            generatePath(appUrls.api_get_client_accounts_claims_document, { claimId }),
            options,
        );

        downloadFile({ resource: data, filename: `account-claim-${claimId}.pdf`, type: 'application/pdf', openInNewTab: true });
    } catch (err: any) {
        const errorMessage = err?.data?.errorDetails ?? 'Произошла ошибка при загрузке документа';

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));
    } finally {
        dispatch(accountsClaimsDocumentSlice.actions.setLoading(false));
    }
};
