import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentViewModel } from 'common/model/payment/paymentViewModel';
import { PaymentHistoryResponse } from 'common/model/payment/paymentHistoryResponse';
import { ActionType } from 'redux/actions/model';

export interface AdminPaymentsHistoryState {
    list: PaymentViewModel[] | null;
    total: number;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean;
}

const initialState: AdminPaymentsHistoryState = {
    list: null,
    total: 0,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminPaymentsHistorySlice = createSlice({
    name: '@@vp/users/users/list',
    initialState,
    reducers: {
        setPaymentsHistoryList(state, action: PayloadAction<PaymentHistoryResponse>): void {
            state.list = action.payload.data;
            state.total = action.payload.total;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<AdminPaymentsHistoryState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoaded(state, action: PayloadAction<AdminPaymentsHistoryState['isLoaded']>): void {
            state.isLoaded = action.payload;
        },
        setLoadingPaymentLogsExcel(state, action: PayloadAction<AdminPaymentsHistoryState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearPaymentsHistory(state): void {
            state.list = null;
            state.total = 0;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminPaymentsHistoryState => initialState,
    }
});

export const {
    setPaymentsHistoryList,
    setLoading,
    setLoaded,
    clearPaymentsHistory,
    setLoadingPaymentLogsExcel
} = adminPaymentsHistorySlice.actions;
