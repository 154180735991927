import _ from 'lodash';
import { objectEntries } from '../../../../common/shared/utils/object';
import { AccountsChargesSelectionState } from '../../../models/accounts/chargesSelection';
import { AccountWithMappedBalanceAndChargesData } from '../balanceAndCharges/index';

export const accountChargesSelectionInitialDataMapper = (
    mappedAccounts: AccountWithMappedBalanceAndChargesData[]
): AccountsChargesSelectionState => {
    const initialData: AccountsChargesSelectionState = {
        amountSelection: {},
        activitySelection: {},
    };

    return mappedAccounts.reduce((acc, { id: accountId, mappedData }) => {
        if (!accountId) {
            return acc;
        }

        for (const [debtType, { atomicServices, separatableServices }] of objectEntries(mappedData)) {
            for (const [, servicesList] of objectEntries(separatableServices)) {
                for (const { accountServiceId, endPeriodBalance } of servicesList) {
                    const amountSelection = Math.max(0, endPeriodBalance);
                    _.set(acc.amountSelection, [accountId, debtType, 'separatableServices', accountServiceId].map(String), amountSelection);

                    if (acc.activitySelection[accountId]?.[debtType]?.separatableServices) {
                        acc.activitySelection[accountId][debtType].separatableServices.push(accountServiceId);
                    } else {
                        _.set(acc.activitySelection, [accountId, debtType, 'separatableServices'].map(String), [accountServiceId]);
                    }
                }
            }

            for (const [, servicesList] of objectEntries(atomicServices)) {
                for (const { accountServiceId, endPeriodBalance } of servicesList) {
                    const amountSelection = Math.max(0, endPeriodBalance);
                    _.set(acc.amountSelection, [accountId, debtType, 'separatableServices', accountServiceId].map(String), amountSelection);
                }
            }

            if (Object.keys(atomicServices).length) {
                const totalAmount = objectEntries(atomicServices).reduce((groupAcc, [, servicesList]) => {
                    return servicesList.reduce((listAcc, { endPeriodBalance }) => listAcc + Math.max(0, endPeriodBalance), groupAcc);
                }, 0);

                _.set(acc.amountSelection, [accountId, debtType, 'atomicServices'].map(String), totalAmount);
                _.set(acc.activitySelection, [accountId, debtType, 'atomicServices'].map(String), true);
            }
        }
        return acc;
    }, initialData);
};
