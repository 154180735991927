const roundToPrecisionValue = (val:number, precision:number) => {
    if (precision < 0) {
        throw new Error('Precision can\'t be negative');
    }
    return Number(Math.round(Number(val + 'e' + precision)) + 'e-' + precision);
};

export const scoreboardValueToArray = (value:number, precision:number, targetArrayLength:number) => {
    const symbols: string[] = roundToPrecisionValue(value, precision).toString().split('');

    if (symbols.length > targetArrayLength) {
        return new Array(targetArrayLength).fill('9').map((item, index) => {
            if (index + 1 === (targetArrayLength - precision)) {
                return '.';
            }
            return item;
        });
    }

    if (symbols.length === targetArrayLength) {
        return symbols;
    }
    const precisionFact = Math.max(symbols.slice().reverse().indexOf('.'), 0);
    const precisionFactDiff = precision - precisionFact;
    return new Array(targetArrayLength - symbols.length - precisionFactDiff).fill('0').concat(symbols);
};
