import { VIFontType, VILetterSpacing } from 'redux/models/app/visuallyImpaired';

export const intervals = [
    {
        title: 'Нормальный',
        value: VILetterSpacing.Normal,
    },
    {
        title: 'Увеличенный',
        value: VILetterSpacing.Increased,
    },
    {
        title: 'Большой',
        value: VILetterSpacing.Big,
    },
];

export const fontTypes = [
    {
        title: 'Без засечек',
        value: VIFontType.SansSerif,
    },
    {
        title: 'С засечками',
        value: VIFontType.Serif,
    },
];
