import { ClientProfileUpdateViewModel } from '../../../../common/model/client/clientProfileUpdateViewModel';
import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const clientProfileUpdatingSliceSelector = (state: StorageState) => state.client.profileUpdating;

export const profileUpdatingActiveFieldSelector = createSelector(
    clientProfileUpdatingSliceSelector,
    slice => slice.profileUpdatingActiveField
);

export const profileUpdatingSelector = createSelector(
    clientProfileUpdatingSliceSelector,
    slice => slice.profileIsUpdating
);

export const profileUpdatingFieldSelector = (field: keyof ClientProfileUpdateViewModel) => createSelector(
    clientProfileUpdatingSliceSelector,
    slice => slice.profileIsUpdating && slice.profileUpdatingFields.includes(field)
);

export const profileUpdatingErrorSelector = createSelector(
    clientProfileUpdatingSliceSelector,
    slice => slice.profileUpdatingError
);
