
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateQRResponseViewModel } from 'common/model/payment/order/createQRResponseViewModel';

export interface PaymentQrState {
  paymentQrCreateLoading: boolean;
  paymentQrCreateLoadingError: string | null;
  paymentQrCompleteLoading: boolean;
  paymentQrCompleteLoadingError: string | null;
  activeQrIds: string[];
  paymentQrResponse: CreateQRResponseViewModel | null;
}

const initialState: PaymentQrState = {
    paymentQrCreateLoading: false,
    paymentQrCreateLoadingError: null,
    paymentQrCompleteLoading: false,
    paymentQrCompleteLoadingError: null,
    activeQrIds: [],
    paymentQrResponse: null,
};

export const paymentQrCreateSlice = createSlice({
    name: '@@vp/payment/createQr',
    initialState,
    reducers: {
        resetPayment: () => initialState,
        setPaymtneQrCreateLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentQrCreateLoading = action.payload;
        },
        setPaymentQrCreateLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentQrCreateLoadingError = action.payload;
        },
        setPaymentQrCompleteLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentQrCompleteLoading = action.payload;
        },
        setPaymentQrCompleteLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentQrCompleteLoadingError = action.payload;
        },
        setPaymentQrResponse: (state, action: PayloadAction<CreateQRResponseViewModel | null>) => {
            state.paymentQrResponse = action.payload;
        },
        removeActiveQrId: (state, action: PayloadAction<CreateQRResponseViewModel>) => {
            state.activeQrIds = state.activeQrIds.filter(id => id !== action.payload.qrcId);
        },
        setActiveQrId: (state, action: PayloadAction<CreateQRResponseViewModel>) => {
            state.activeQrIds = [...state.activeQrIds, action.payload.qrcId];
        }
    }
});

export const {
    resetPayment,
    setPaymtneQrCreateLoading,
    setPaymentQrCreateLoadingError,
    setPaymentQrCompleteLoading,
    setPaymentQrCompleteLoadingError,
    setPaymentQrResponse,
    removeActiveQrId,
    setActiveQrId
} = paymentQrCreateSlice.actions;
