import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalStorage } from '../../../../app/shared/constants';
import { Regions } from '../../../../common/model/account/regions';
import { featureFlagBoolean } from 'app/shared/utils/lkFeatureFlags';

const getPaymentAvailable = (region: Regions): boolean | null => {
    switch (region) {
        case Regions.TjumenskajaOblast: {
            return featureFlagBoolean('paymentsTyumen');
        }
        case Regions.KurganskajaOblast: {
            return featureFlagBoolean('paymentsKurgan');
        }
        case Regions.OrenburgskajaOblast: {
            return featureFlagBoolean('paymentsOrenburg');
        }
        case Regions.SverdlovskajaOblast: {
            return featureFlagBoolean('paymentsSverdlovsk');
        }
        case Regions.Surgut: {
            return featureFlagBoolean('paymentsSurgut');
        }
        case Regions.JaNAO: {
            return featureFlagBoolean('paymentsJanao');
        }
        default: {
            // eslint-disable-next-line no-console
            console.log('current region is not in a list');
            return null;
        }
    }
};

export interface ClientRegionsState {
    regions: string[] | null;
    currentRegion: string | null;
    hasAtolGatewayGroupCode: boolean | null;
    hasSberPaySettings: boolean | null;
    paymentAvailableForCurrentRegion: boolean;
}

const initialState: ClientRegionsState = {
    regions: null,
    currentRegion: null,
    hasAtolGatewayGroupCode: null,
    hasSberPaySettings: null,
    paymentAvailableForCurrentRegion: false,
};

try {
    const savedCurrentRegion = localStorage.getItem(LocalStorage.currentClientRegion);
    if (savedCurrentRegion) {
        initialState.currentRegion = savedCurrentRegion;
        const isPaymentAvailable = getPaymentAvailable(initialState.currentRegion as Regions);
        initialState.paymentAvailableForCurrentRegion = !!isPaymentAvailable;
    }
    // eslint-disable-next-line no-empty
} catch (e) {}

export const clientRegionsSlice = createSlice({
    name: '@@vp/clientRegions',
    initialState,
    reducers: {
        setClientRegions: (state, { payload }: PayloadAction<string[] | null>) => {
            state.regions = payload;
        },
        setCurrentClientRegion: (state, { payload }: PayloadAction<string | null>) => {
            state.currentRegion = payload;
            state.hasAtolGatewayGroupCode = null;
            state.hasSberPaySettings = null;
            if (payload) {
                localStorage.setItem(LocalStorage.currentClientRegion, payload);
            } else {
                localStorage.removeItem(LocalStorage.currentClientRegion);
            }
            const isPaymentAvailable = getPaymentAvailable(state.currentRegion as Regions);
            state.paymentAvailableForCurrentRegion = !!isPaymentAvailable;
        },
        setHasAtolGatewayGroupCode: (state, { payload }: PayloadAction<boolean | null>) => {
            state.hasAtolGatewayGroupCode = payload;
        },
        setHasSberPaySettings: (state, { payload }: PayloadAction<boolean | null>) => {
            state.hasSberPaySettings = payload;
        },
    },
});
