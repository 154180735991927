import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ticket } from 'app/shared/model/tickets/ticket';
import { ActionType } from '../../../actions/model';

export interface TicketsListState {
    list: Ticket[] | null;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: TicketsListState = {
    list: null,
    isLoading: false,
    isLoaded: false,
};

export const ticketsSlice = createSlice({
    name: '@@vp/tickets/list',
    initialState,
    reducers: {
        setTicketsList(state, action: PayloadAction<Ticket[]>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoadingTickets(state, action: PayloadAction<TicketsListState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        clearTickets(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): TicketsListState => initialState,
    }
});

export const {
    setTicketsList,
    setLoadingTickets,
    clearTickets
} = ticketsSlice.actions;
