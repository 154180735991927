import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationSendingViewModel } from 'common/model/notification/notificationSendingViewModel';
import { ActionType } from '../../actions/model';

export interface NotificationSendingState {
    list: NotificationSendingViewModel[] | null;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: NotificationSendingState = {
    list: null,
    isLoading: false,
    isLoaded: false,
};

export const notificationsSendingSlice = createSlice({
    name: '@@vp/notificationsSending/list',
    initialState,
    reducers: {
        setNotificationsSendingList(state, action: PayloadAction<NotificationSendingViewModel[]>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoadingNotificationsSending(state, action: PayloadAction<NotificationSendingState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        clearNotificationsSending(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): NotificationSendingState => initialState,
    }
});

export const {
    setNotificationsSendingList,
    setLoadingNotificationsSending,
    clearNotificationsSending,
} = notificationsSendingSlice.actions;
