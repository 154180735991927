import { getApiRoute } from '../../../../app/shared/pipes/routing';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { RoleCreator } from '../../../../common/model/users/role/roleCreator';
import { RoleUpdater } from '../../../../common/model/users/role/roleUpdater';
import { RoleViewModel } from '../../../../common/model/users/role/roleViewModel';
import { AppThunk } from '../../../reducers';

export const createRole = (payload: RoleCreator) => async (): Promise<RoleViewModel> => {
    const createdRole = await httpService.post<RoleViewModel>(appUrls.api_post_access_roles, payload);
    return createdRole;
};

export const updateRole = (id: string, payload: RoleUpdater): AppThunk => async (): Promise<RoleViewModel> => {
    const updatedRole = await httpService.put<RoleViewModel>(getApiRoute(appUrls.api_put_access_roles, { id }), payload);
    return updatedRole;
};

export const deleteRole = (id: string): AppThunk => async (): Promise<void> => {
    await httpService.delete<void>(getApiRoute(appUrls.api_delete_access_roles, { id }));
};

export * from './active';
export * from './list';
