import { combineReducers } from 'redux';
import { clientProfileUpdatingSlice } from './profileUpdating';
import { clientProfileSlice } from './profile';
import { clientAccountsSlice } from './accounts';
import { clientRegionsSlice } from './regions';
import accountToDelete from './accountToDelete';

export const client = combineReducers({
    profile: clientProfileSlice.reducer,
    profileUpdating: clientProfileUpdatingSlice.reducer,
    accounts: clientAccountsSlice.reducer,
    regions: clientRegionsSlice.reducer,
    accountToDelete
});
