import { modalNotificationsPush } from '../..';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute } from '../../../../app/shared/pipes/routing';
import { appUrls } from '../../../../common/appUrls';
import { PaymentDetailViewModel } from '../../../../common/model/payment/paymentDetailViewModel';
import { AppThunk } from '../../../reducers';
import { clearPaymentDetails, setLoading, setPaymentDetailsList } from '../../../reducers/adminPayment/paymentDetails';

export const getAdminPaymentDetails = (paymentId: string): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_admin_payment_details, { paymentId }, {});

    try {
        const data = await httpService.get<PaymentDetailViewModel[]>(route);
        dispatch(setPaymentDetailsList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке детализации платежа. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};

export const resetAdminPaymentDetailsData = (): AppThunk => (dispatch) => {
    dispatch(clearPaymentDetails());
};
