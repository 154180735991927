import { ComponentType, lazy } from 'react';
import { Routes } from 'app/shared/constants';

export type RouterConfigItem = {
    path: typeof Routes[keyof typeof Routes];
    component: ReturnType<typeof lazy> | ComponentType;
    exact?: boolean,
};

export const routerConfig: RouterConfigItem[] = [
    {
        path: Routes.login,
        component: lazy(() => import(/* webpackChunkName: "login" */ '../content/login/index')),
    },
    {
        path: Routes.socialConfirmLogin,
        component: lazy(() => import(/* webpackChunkName: "social-login" */ '../content/login/socialConfirmLogin/index')),
    },
    {
        path: Routes.registration,
        component: lazy(() => import(/* webpackChunkName: "registration" */ '../content/registration/index')),
    },
    {
        path: Routes.registrationConfirm,
        component: lazy(() => import(/* webpackChunkName: "registration-confirm" */ '../content/registration/confirm/index')),
    },
    {
        path: Routes.resetPasswordRequest,
        component: lazy(() => import(/* webpackChunkName: "password-reset-req" */ '../content/passwordReset/passwordResetRequestPage/index')),
    },
    {
        path: Routes.resetPasswordResendEmail,
        component: lazy(() => import(/* webpackChunkName: "password-reset-resend-email" */ '../content/passwordReset/resendEmailPage/index')),
    },
    {
        path: Routes.resetPasswordConfirmByEmail,
        component: lazy(() => import(/* webpackChunkName: "password-reset-confirm-email" */ '../content/passwordReset/confirmByEmail/index')),
    },
    {
        path: Routes.resetPasswordConfirmByPhone,
        component: lazy(() => import(/* webpackChunkName: "password-reset-confirm-phone" */ '../content/passwordReset/confirmByPhone/index')),
    },
    {
        path: Routes.resetPassword,
        component: lazy(() => import(/* webpackChunkName: "password-reset" */ '../content/passwordReset/index')),
    },
    {
        path: Routes.unauthorizedAccountMeters,
        component: lazy(() => import(/* webpackChunkName: "unauth-meters" */ '../content/unauthorized/entries/index')),
    },
    {
        path: Routes.unauthorizedPayment,
        component: lazy(() => import(/* webpackChunkName: "unauth-meters" */ '../content/unauthorized/payment/index')),
    },
    {
        path: Routes.unauthorizedPaymentDetailsPage,
        component: lazy(() => import(/* webpackChunkName: "unauth-meters" */ '../content/unauthorized/paymentDetails/index')),
    },
    {
        path: Routes.unauthorizedConfirmPhonePayment,
        component: lazy(() => import(/* webpackChunkName: "password-reset-confirm-email" */ '../content/unauthorized/payment/accountPayment/components/confirmPhone')),
    },
    {
        path: Routes.adminLogin,
        component: lazy(() => import(/* webpackChunkName: "admin-login" */ '../content/adminAuth/login/index')),
    },
    {
        path: Routes.adminAuthError,
        component: lazy(() => import(/* webpackChunkName: "admin-error" */ '../content/adminAuth/authError/index')),
    },
    {
        path: Routes.admin,
        component: lazy(() => import(/* webpackChunkName: "admin" */ './user/index'))
    },
    {
        path: Routes.empty,
        component: lazy(() => import(/* webpackChunkName: "client" */'./client/index'))
    },
];
