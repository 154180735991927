import { combineReducers } from 'redux';
import { accessAccessRulesSlice, AccessRulesAccessState } from './accessRules';
import { rolesAccessSlice, RolesAccessState } from './roles';
import { usersAccessSlice, UsersAccessState } from './users';

export interface UsersState {
    roles: RolesAccessState;
    users: UsersAccessState;
    accessRules: AccessRulesAccessState;
}

export const users = combineReducers<UsersState>({
    roles: rolesAccessSlice,
    users: usersAccessSlice,
    accessRules: accessAccessRulesSlice.reducer,
});
