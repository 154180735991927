import { httpService } from 'app/shared/httpWrapper';
import { appUrls } from 'common/appUrls';
import { NotificationSendingViewModel } from 'common/model/notification/notificationSendingViewModel';
import { modalNotificationsPush } from '../';
import { getApiRoute } from '../../../app/shared/pipes';
import { downloadExcelData } from '../../../app/shared/utils/excel';
import { NotificationWithErrorsExcelResponse } from '../../../common/model/notification/notificationWithErrorsExcelResponse';
import { AppThunk } from '../../reducers';
import { setLoadingNotificationsSending, setNotificationsSendingList } from '../../reducers/notificationsSending';


export const getNotificationSendingList = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoadingNotificationsSending(true));

    try {
        const data = await httpService.get<NotificationSendingViewModel[]>(appUrls.api_get_notification_sending);
        dispatch(setNotificationsSendingList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка оповещений. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoadingNotificationsSending(false));
};

export const getProcessedWithErrorNotificationsToExcel = (
    sendingProcessId: string,
    callback?: () => void
): AppThunk => async (
    dispatch
): Promise<void> => {
    try {
        const route = getApiRoute(appUrls.api_get_notification_errors, { id: sendingProcessId });
        const { data } = await httpService.get<NotificationWithErrorsExcelResponse>(route);
        downloadExcelData(data, 'notification-errors');
        return callback?.();
    } catch (error) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при экспорте списка ошибок рассылки. Попробуйте еще раз',
            type: 'error',
        }));
    }
};
