import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export const RouterScrollService: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }, [history.location.pathname]);

    return null;
};
