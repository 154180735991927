import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentOrderSliceSelector = (state: StorageState) => state.payment.paymentOrder;

export const orderCreateLoadingSelector = createSelector(
    paymentOrderSliceSelector,
    payment => payment.orderCreateLoading
);

export const orderCreateLoadingErrorSelector = createSelector(
    paymentOrderSliceSelector,
    payment => payment.orderCreateLoadingError
);

export const orderCompleteLoadingSelector = createSelector(
    paymentOrderSliceSelector,
    payment => payment.orderCompleteLoading
);

export const orderCompleteLoadingErrorSelector = createSelector(
    paymentOrderSliceSelector,
    payment => payment.orderCompleteLoadingError
);
