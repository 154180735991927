import { ModalNotification, modalNotificationsSlice } from '../../reducers/controls/modalNotifications';
import { AppThunk } from '../../reducers';

export const modalNotificationsPop = (): AppThunk => dispatch => {
    dispatch(modalNotificationsSlice.actions.pop());
};

export const modalNotificationsClear = (): AppThunk => dispatch => {
    dispatch(modalNotificationsSlice.actions.clear());
};

export const modalNotificationsPush = (payload: ModalNotification): AppThunk => dispatch => {
    dispatch(modalNotificationsSlice.actions.push(payload));
};
