import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';
import { createDataFetchSliceSelectors } from 'redux/utils/dataFetch';
import { demoModeCredentials } from 'app/shared/constants/demoModeCredentials';

export const clientProfileSliceSelector = (state: StorageState) => state.client.profile;
export const clientProfileDataSliceSelector = (state: StorageState) => state.client.profile.data;

export const clientProfileSelectors = createDataFetchSliceSelectors(clientProfileSliceSelector);

export const isDemoModeSelector = createSelector(
    clientProfileDataSliceSelector,
    data => data?.lastName === demoModeCredentials.login
);

export const isPasswordTemporarySelector = createSelector(
    clientProfileDataSliceSelector,
    data => data?.isPasswordTemporary || false
);
