import { combineReducers } from 'redux';
import { adminPaymentsHistorySlice, AdminPaymentsHistoryState } from './paymentsHistory';
import { adminPaymentDetailsSlice, AdminPaymentsDetailsState } from './paymentDetails';
import { adminPaymentsOrderSlice, AdminPaymentsOrderState } from './paymentsOrder';
export interface AdminPaymentState {
    adminPaymentsHistory: AdminPaymentsHistoryState;
    adminPaymentDetails: AdminPaymentsDetailsState;
    adminPaymentsOrder: AdminPaymentsOrderState;
}

export const adminPayment = combineReducers<AdminPaymentState>({
    adminPaymentsHistory: adminPaymentsHistorySlice.reducer,
    adminPaymentDetails: adminPaymentDetailsSlice.reducer,
    adminPaymentsOrder: adminPaymentsOrderSlice.reducer
});
