
import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentQrCreateSliceSelector = (state: StorageState) => state.payment.paymentQrCreate;

export const paymentQrCreateLoadingSelector = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.paymentQrCreateLoading
);

export const paymentQrCreateLoadingErrorSelector = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.paymentQrCreateLoadingError
);

export const paymentQrCompleteLoadingSelector = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.paymentQrCompleteLoading
);

export const paymentQrCompleteLoadingErrorSelector = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.paymentQrCompleteLoadingError
);

export const paymentQrResponseSelector = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.paymentQrResponse
);

export const paymentQrPaymentIds = createSelector(
    paymentQrCreateSliceSelector,
    payment => payment.activeQrIds
);
