import React from 'react';

import './styles.scss';
import { ModalOwnProps } from './model';
import { BasicModal } from './basicModal';
import { InfoModal } from './infoModal';

export const View: React.FC<ModalOwnProps> = ({
    type = 'basic',
    children,
    infoMessage,
    ...props
}) => {
    if (type !== 'basic') {
        return (
            <InfoModal {...props} infoMessage={infoMessage} type={type}>{children}</InfoModal>
        );
    }

    return (
        <BasicModal {...props}>{children}</BasicModal>
    );
};
