import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PasswordResetRequestState {
    isSubmitting: boolean;
}

const initialState: PasswordResetRequestState = {
    isSubmitting: false,
};

export const passwordResetRequestSlice = createSlice({
    name: '@@vp/passwordResetRequest',
    initialState,
    reducers: {
        setPasswordResetRequestSubmitting(state, action: PayloadAction<PasswordResetRequestState['isSubmitting']>): void {
            state.isSubmitting = action.payload;
        },
    }
});

export const {
    setPasswordResetRequestSubmitting,
} = passwordResetRequestSlice.actions;
