import { combineReducers } from 'redux';
import { TicketsListState, ticketsSlice } from './ticketsList';

export interface TicketsState {
    ticketsList: TicketsListState;
}

export const tickets = combineReducers<TicketsState>({
    ticketsList: ticketsSlice.reducer,
});
