import React from 'react';
import { MeterEntriesConfirmationMessage } from '../../../../app/content/meter/components/metersForm/components/confirmationMessage';
import { MS_IN_SECOND, SECONDS_IN_MINUTE } from '../../../../common/constants/date';
import { buildModalNotificationFromMeterEntriesValidationError } from '../../../../app/content/meter/components/metersForm/utils';
import { HttpWrapperOptions, httpService } from '../../../../app/shared/httpWrapper';
import { AccountDetailsViewModel } from '../../../../../../common/model/account/accountDetailsViewModel';
import { appUrls } from '../../../../common/appUrls';
import { setAccounts, setAccountsLoading, setAccountsLoadingError, setMeterEntriesSending } from '../../../reducers/accounts/meterAndEntries';
import { accountsSliceSelector, accountsWithoutMetersSliceSelector, clientCurrentRegionSelector } from '../../../selectors';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';
import { MeterEntriesValidateSuccess } from '../../../../common/model/meterReadings/meterEntriesValidateSuccess';
import { getApiRoute } from '../../../../app/shared/pipes';
import {
    setAccountsWithoutMeters,
    setAccountsWithoutMetersLoading,
    setAccountsWithoutMetersLoadingError
} from '../../../reducers/accounts/withoutMeterAndEntires';
import { MeterEntriesCreator } from '../../../../../../common/model/meterReadings/meterEntriesCreator';
import { getEnvBool } from 'app/shared/utils/get-env';
import { featureFlagBoolean } from 'app/shared/utils/lkFeatureFlags';

export const getAccountsWithMetersAndEntries = (): AppThunk => async (dispatch, getState) => {
    const { accountsLoading } = accountsSliceSelector(getState());
    const currentRegion = clientCurrentRegionSelector(getState());

    if (accountsLoading) {
        return;
    }

    dispatch(setAccountsLoadingError(null));
    dispatch(setAccountsLoading(true));

    try {
        const endpointURL = getApiRoute(appUrls.api_get_client_accounts_with_meter_and_entries, {}, { region: currentRegion || undefined });
        const result = await httpService.get<AccountDetailsViewModel[]>(endpointURL);
        dispatch(setAccounts(result));
    } catch (err) {
        // Change items state to an empty array for possible future reset to null, which triggers items loading
        setAccounts([]);
        // eslint-disable-next-line no-console
        console.error(err);
        const errorMessage = 'Произошла ошибка при загрузке списка счетов';
        dispatch(setAccountsLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка счетов. Попробуйте позже',
            type: 'error',
        }));
    }
    dispatch(setAccountsLoading(false));
};

export const getAccountsWithoutMetersAndEntries = (): AppThunk => async (dispatch, getState) => {
    const { accountsLoading } = accountsWithoutMetersSliceSelector(getState());
    const currentRegion = clientCurrentRegionSelector(getState());

    if (accountsLoading) {
        return;
    }

    dispatch(setAccountsWithoutMetersLoadingError(null));
    dispatch(setAccountsWithoutMetersLoading(true));

    try {
        const endpointURL = getApiRoute(appUrls.api_get_client_accounts_without_meter_and_entries,
            {},
            { region: currentRegion || undefined }
        );
        const result = await httpService.get<AccountDetailsViewModel[]>(endpointURL);
        dispatch(setAccountsWithoutMeters(result));
    } catch (err) {
        // Change items state to an empty array for possible future reset to null, which triggers items loading
        setAccountsWithoutMeters([]);
        // eslint-disable-next-line no-console
        console.error(err);
        const errorMessage = 'Произошла ошибка при загрузке списка счетов';
        dispatch(setAccountsWithoutMetersLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка счетов. Попробуйте позже',
            type: 'error',
        }));
    }
    dispatch(setAccountsLoading(false));
};

export const sendMeterEntries = (
    meterEntries: MeterEntriesCreator[],
    accountId?: string,
    onSuccess?: () => Promise<void> | void,
    onPaynow?: (entries: MeterEntriesValidateSuccess[]) => Promise<void> | void,
    isDemoMode?: boolean,
): AppThunk => async (dispatch, getState) => {
    const { meterEntriesSending } = accountsSliceSelector(getState());

    if (meterEntriesSending) {
        return;
    }

    dispatch(setMeterEntriesSending(true));

    try {
        const httpWrapperOptions = new HttpWrapperOptions();
        httpWrapperOptions.queryParams = { isValidate: true, accountId };
        httpWrapperOptions.Timeout(MS_IN_SECOND * SECONDS_IN_MINUTE);

        const entries = await httpService.post<MeterEntriesValidateSuccess[]>(
            appUrls.api_post_meter_entries,
            meterEntries,
            httpWrapperOptions
        );

        dispatch(modalNotificationsPush({
            title: 'Подтвердите ввод показаний',
            message: React.createElement(MeterEntriesConfirmationMessage, { entries, isValidate: true }),
            cancelButtonTitle: 'Отменить',
            onCancel: () => {
                dispatch(setMeterEntriesSending(false));
            },
            okButtonTitle: 'Подтвердить',
            onOk: async () => {
                try {
                    const options = new HttpWrapperOptions();
                    options.queryParams = { accountId };
                    options.Timeout(MS_IN_SECOND * SECONDS_IN_MINUTE);

                    const savedEntries = isDemoMode ? entries : await httpService.post<MeterEntriesValidateSuccess[]>(
                        appUrls.api_post_meter_entries,
                        meterEntries,
                        options
                    );

                    dispatch(modalNotificationsPush({
                        title: 'Ваши показания приняты',
                        message: React.createElement(MeterEntriesConfirmationMessage, { entries: savedEntries, isValidate: false }),
                        type: 'success',
                        okButtonTitle: featureFlagBoolean('paynow') ? 'Оплатить сейчас' : undefined,
                        onOk: () => {
                            if (!onPaynow) {
                                return;
                            }

                            // ToDo: remove when new data come in
                            // now MeterEntriesValidateSuccess is Record<[service name], MeterEntriesValidateSuccess>
                            if (!Array.isArray(savedEntries)) {
                                onPaynow(Object.values(savedEntries));
                                return;
                            }

                            onPaynow(savedEntries);
                        },
                        cancelButtonTitle: 'Закрыть',
                        onCancel: () => {
                            dispatch(setMeterEntriesSending(false));
                        }
                    }));
                    onSuccess?.();

                } catch (err: any) {
                    dispatch(modalNotificationsPush({
                        title: 'Показания не приняты',
                        message: err?.data?.errorDetails ?? 'Попробуйте позже',
                        type: 'error',
                    }));
                } finally {
                    dispatch(setMeterEntriesSending(false));
                }
            },
            type: 'warning',
        }));
    } catch (err: any) {
        const modalNotification = buildModalNotificationFromMeterEntriesValidationError(err);
        dispatch(modalNotificationsPush(modalNotification));

        dispatch(setMeterEntriesSending(false));
    }
};

export const updateMeterEntries = (
    meterEntries: MeterEntriesCreator[],
    accountId: string,
    onSuccess?: () => Promise<void> | void,
    isDemoMode?: boolean,
): AppThunk => async (dispatch, getState) => {
    const { meterEntriesSending } = accountsSliceSelector(getState());

    if (meterEntriesSending) {
        return;
    }

    dispatch(setMeterEntriesSending(true));

    try {
        const httpWrapperOptions = new HttpWrapperOptions();
        httpWrapperOptions.queryParams = { isValidate: true, accountId };
        httpWrapperOptions.Timeout(MS_IN_SECOND * SECONDS_IN_MINUTE);

        const entries = await httpService.put<MeterEntriesValidateSuccess[]>(
            appUrls.api_put_meter_entries,
            meterEntries,
            httpWrapperOptions
        );

        dispatch(modalNotificationsPush({
            title: 'Подтвердите ввод показаний',
            message: React.createElement(MeterEntriesConfirmationMessage, { entries, isValidate: true }),
            cancelButtonTitle: 'Отменить',
            onCancel: () => {
                dispatch(setMeterEntriesSending(false));
            },
            okButtonTitle: 'Подтвердить',
            onOk: async () => {
                try {
                    const options = new HttpWrapperOptions();
                    options.queryParams = { accountId };
                    options.Timeout(MS_IN_SECOND * SECONDS_IN_MINUTE);

                    const savedEntries = isDemoMode ? entries : await httpService.put<MeterEntriesValidateSuccess[]>(
                        appUrls.api_put_meter_entries,
                        meterEntries,
                        options
                    );

                    dispatch(modalNotificationsPush({
                        title: 'Ваши показания приняты',
                        message: React.createElement(MeterEntriesConfirmationMessage, { entries: savedEntries, isValidate: false }),
                        type: 'success',
                    }));
                    onSuccess?.();

                } catch (err: any) {
                    dispatch(modalNotificationsPush({
                        title: 'Показания не приняты',
                        message: err?.data?.errorDetails ?? 'Попробуйте позже',
                        type: 'error',
                    }));
                } finally {
                    dispatch(setMeterEntriesSending(false));
                }
            },
            type: 'warning',
        }));
    } catch (err: any) {
        const modalNotification = buildModalNotificationFromMeterEntriesValidationError(err);
        dispatch(modalNotificationsPush(modalNotification));

        dispatch(setMeterEntriesSending(false));
    }
};
