export const parseBoolean = (value: string): boolean => {
    const trueValues = ['true', '1', 'on', 'enabled', 'enable'];
    const falseValues = ['false', '0', 'off', 'disabled', 'disable'];
    if (trueValues.includes(value)) {
        return true;
    } else if (falseValues.includes(value)) {
        return false;
    }
    return false;
};
