import { AccountMeterScaleType } from '../../../common/model/account/accountMeterScaleType';
import { VKLogo, TGLogo, YTLogo, VostokLogo, EricLogo, NteskLogo, RicLogo, UricLogo } from '../../../assets/icons';
import { SocialNetworkType, DivisionType } from '../../../common/model/client/divisionType';
import { SvgIconComponent } from './type';

import vostokFav from '../../../assets/icons/logos/favIcons/vostok.png';
import ericFav from '../../../assets/icons/logos/favIcons/eric.png';
import nteskFav from '../../../assets/icons/logos/favIcons/ntesk.png';
import rickFav from '../../../assets/icons/logos/favIcons/ric.png';
import uricFav from '../../../assets/icons/logos/favIcons/uric.png';

export enum MeterLocation {
  Kitchen = 'кухня',
  Bathroom = 'санузел',
  Hall = 'коридор',
}

export interface Tariff {
  id: number;
  name: string;
  price: number; // float
  code: string;
}

export interface MeterEntry {
  id: number;
  date: string;
  value: number; // float
}

export interface MeterModel {
  id: number;
  name: string;
  capacity: string;
  measure: string;
  decimalPlaces: string;
  loss: string;
}

export interface AccountMeter {
  id: number;
  number: string;
  checkDateTime: string;
  model: MeterModel;
  scales: MeterScale[];
  location: MeterLocation;
}

export interface MeterScale {
  id: number;
  lastEntry: MeterEntry;
  tariff: Tariff;
  type: AccountMeterScaleType;
}

export interface AccountService {
  id: number;
  name: string;
  meters: AccountMeter[];
}

export interface Account {
  id: number;
  number: number;
  city: string;
  street: string;
  house: string;
  flat?: string;
  services: AccountService[];
}

export enum ProfileType {
  user = 'user',
  client = 'client',
}


export const SocialNetworkIconDictionary: Record<string, SvgIconComponent> = {
    [SocialNetworkType.VK]: VKLogo,
    [SocialNetworkType.TG]: TGLogo,
    [SocialNetworkType.YT]: YTLogo,
};

export const DivisionLogoDictionary: Record<string, SvgIconComponent | string> = {
    [DivisionType.Vostok]: VostokLogo,
    [DivisionType.Eric]: EricLogo,
    [DivisionType.Ntesk]: NteskLogo,
    [DivisionType.Ric]: RicLogo,
    [DivisionType.Uric]: UricLogo,
    [DivisionType.Billing]: VostokLogo,
};

export const DivisionFavLogoDictionary: Record<string, string> = {
    [DivisionType.Vostok]: vostokFav,
    [DivisionType.Eric]: ericFav,
    [DivisionType.Ntesk]: nteskFav,
    [DivisionType.Ric]: rickFav,
    [DivisionType.Uric]: uricFav,
    [DivisionType.Billing]: vostokFav,
};
