import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentDetailsSliceSelector = (state: StorageState) => state.payment.paymentDetails;

export const paymentDetailsListSelector = createSelector(
    paymentDetailsSliceSelector,
    payment => payment.list
);

export const paymentDetailsLoadingSelector = createSelector(
    paymentDetailsSliceSelector,
    payment => payment.isLoading
);

export const paymentDetailsLoadedSelector = createSelector(
    paymentDetailsSliceSelector,
    payment => payment.isLoaded
);
