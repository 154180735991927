
import { PaymentCreateInput } from '../../../../common/model/payment/paymentCreateInput';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { setPaymtneSberPayCreateLoading, setPaymentSberPayCreateLoadingError, setPaymentSberPayResponse, setPaymentSberPayCompleteLoading } from '../../../reducers/payment/paymentSberPayCreate';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';
import { paymentSberPayCreateSliceSelector } from '../../../selectors/payment/paymentSberPayCreate';
import { CreateSberPayResponseViewModel } from 'common/model/payment/order/createSberPayResponseViewModel';
import { getApiRoute } from 'app/shared/pipes';
import { PaymentSberPaycStatusType } from 'common/model/payment/paymentSberPaycStatusType';
import { PaymentSberPayCViewModel } from 'common/model/payment/paymentSberPayCViewModel';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { Routes } from 'app/shared/constants';

export const createPaymentSberPay = (
    requestBody: PaymentCreateInput,
    onError?: () => void
): AppThunk => async (dispatch, getState) => {
    const { paymentSberPayCreateLoading } = paymentSberPayCreateSliceSelector(getState());

    if (paymentSberPayCreateLoading) {
        return;
    }

    dispatch(setPaymentSberPayCreateLoadingError(null));
    dispatch(setPaymtneSberPayCreateLoading(true));

    try {
        const result = await httpService.post<CreateSberPayResponseViewModel>(
            appUrls.api_post_payment_sp,
            requestBody
        );

        dispatch(setPaymentSberPayResponse(result));
        dispatch(setPaymentSberPayCompleteLoading(true));

        const TIMEOUT = 5000;

        const checkPaymentStatus = async () => {
            const paymentStatus = await httpService.get<PaymentSberPayCViewModel>(
                getApiRoute(appUrls.api_get_payment_sp, { spcId: result.spcId })
            );

            if (
                paymentStatus.status === PaymentSberPaycStatusType.Created ||
                paymentStatus.status === PaymentSberPaycStatusType.InProgress
            ) {
                setTimeout(checkPaymentStatus, TIMEOUT);
            } else if (paymentStatus.status === PaymentSberPaycStatusType.Succeed) {
                dispatch(push(generatePath(Routes.wallet, { orderId: result.orderId, status: 'success' })));
            } else if (paymentStatus.status === PaymentSberPaycStatusType.Failed) {
                dispatch(setPaymtneSberPayCreateLoading(false));
                dispatch(push(generatePath(Routes.wallet, { orderId: result.orderId, status: 'failed' })));
            }
        };

        await checkPaymentStatus();
    } catch {
        const errorMessage = 'SberPay: Произошла ошибка при оплате счета. Попробуйте позже';
        dispatch(setPaymentSberPayCreateLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
            onOk: onError
        }));
    }

    dispatch(setPaymtneSberPayCreateLoading(false));
};
