import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HistoryChargesReportState {
    isLoadingPdf: boolean;
}

const initialState: HistoryChargesReportState = {
    isLoadingPdf: false,
};

export const historyChargesReportSlice = createSlice({
    name: '@@wh/historyChargesReport',
    initialState,
    reducers: {
        setLoadingPdf(state, action: PayloadAction<HistoryChargesReportState['isLoadingPdf']>): void {
            state.isLoadingPdf = action.payload;
        },
    }
});

export const {
    setLoadingPdf,
} = historyChargesReportSlice.actions;
