import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { AccountServicesViewModel } from '../../../../common/model/account/accountServicesViewModel';
import { DebtTypeFromBilling } from '../../../../common/model/account/debtType';
import { AccountViewModel } from '../../../../common/model/account/accountViewModel';
import { StorageState } from '../../../reducers';


export const accountsChargesSelectionSliceSelector = (state: StorageState) => state.accounts.chargesSelection;

export const accountChargesAmountSelectionForSeparatableServicesSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => _.get(slice, ['amountSelection', accountId, debtType, 'separatableServices'], {} as Record<NonNullable<AccountServicesViewModel['id']>, number>)
);

export const accountChargesAmountSelectionForServiceSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling,
    accountServiceId: NonNullable<AccountServicesViewModel['id']>
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => _.get(slice, ['amountSelection', accountId, debtType, 'separatableServices', accountServiceId], 0)
);

export const accountChargesAmountSelectionForAtomicServicesSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling,
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => _.get(slice, ['amountSelection', accountId, debtType, 'atomicServices'], 0)
);

export const accountChargesActivitySelectionForSeparatableServicesSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => _.get(slice, ['activitySelection', accountId, debtType, 'separatableServices'], [] as NonNullable<AccountServicesViewModel['id']>[])
);

export const accountChargesActivitySelectionForSeparatableServiceSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling,
    accountServiceId: NonNullable<AccountServicesViewModel['id']>
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => (
        _
            .get(
                slice,
                ['activitySelection', accountId, debtType, 'separatableServices'],
                [] as NonNullable<AccountServicesViewModel['id']>[]
            )
            .includes(accountServiceId)
    )
);

export const accountChargesActivitySelectionForAtomicServicesSelector = (
    accountId: NonNullable<AccountViewModel['id']>,
    debtType: DebtTypeFromBilling
) => createSelector(
    accountsChargesSelectionSliceSelector,
    slice => _.get(slice, ['activitySelection', accountId, debtType, 'atomicServices'], false)
);
