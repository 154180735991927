import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HistoryPaymentsReportState {
    isLoadingPdf: boolean;
}

const initialState: HistoryPaymentsReportState = {
    isLoadingPdf: false,
};

export const historyPaymentsReportSlice = createSlice({
    name: '@@wh/historyPaymentsReport',
    initialState,
    reducers: {
        setLoadingPdf(state, action: PayloadAction<HistoryPaymentsReportState['isLoadingPdf']>): void {
            state.isLoadingPdf = action.payload;
        },
    }
});

export const {
    setLoadingPdf,
} = historyPaymentsReportSlice.actions;
