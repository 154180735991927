import { createSelector } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AccountViewModel } from 'common/model/account/accountViewModel';
import { StorageState } from '../../../reducers';

export const accountsSliceSelector = (state: StorageState) => state.accounts.metersAndEntries;
export const accountsWithoutMetersSliceSelector = (state: StorageState) => state.accounts.withoutMetersAndEntries;

const _accountsSelector = (includeDisabled: boolean) => createSelector(
    accountsSliceSelector,
    accounts => accounts.accounts ? [...accounts.accounts].sort((
        a:AccountViewModel,
        b:AccountViewModel
    ) => {
        return a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1;
    }).filter((account:AccountViewModel) => {
        return includeDisabled ? account : !account.isDisabled;
    }) : null
);

const _accountsWithoutMetersSelector = () => createSelector(
    accountsWithoutMetersSliceSelector,
    accounts => accounts.accounts ? [...accounts.accounts].sort((
        a:AccountViewModel,
        b:AccountViewModel
    ) => {
        return a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1;
    }) : null
);

const allAccountsSelector = _accountsSelector(true);
const activeAccountsSelector = _accountsSelector(false);
const allAccountsWithoutMetersSelector = _accountsWithoutMetersSelector();

export const accountsSelector = (includeDisabled = false) => includeDisabled ? allAccountsSelector : activeAccountsSelector;
export const accountsWithoutMetersSelector = () => allAccountsWithoutMetersSelector;

export const accountsWithoutClosedMetersSelector = () => createSelector(
    accountsSelector(),
    accounts => {
        if (!accounts?.length) {
            return [];
        }
        const filteredAccounts = cloneDeep(accounts);
        filteredAccounts?.forEach(account => account.services?.forEach(service => {
            service.meters = service.meters?.filter(meter => !meter.closeDatetime || meter.closeDatetime > new Date());
        }));
        return filteredAccounts;
    }
);

export const accountsByIdSelector = (id?:string, isDisabled?: boolean) => createSelector(
    accountsSelector(isDisabled),
    accounts => accounts?.find(item => item.id === id)
);

export const accountsLoadingSelector = createSelector(
    accountsSliceSelector,
    accounts => accounts.accountsLoading
);

export const accountsLoadedSelector = createSelector(
    accountsSliceSelector,
    accounts => accounts.accountsLoaded
);

export const accountsLoadingErrorSelector = createSelector(
    accountsSliceSelector,
    accounts => accounts.accountsLoadingError
);

export const meterEntriesSendingSelector = createSelector(
    accountsSliceSelector,
    accounts => accounts.meterEntriesSending
);

export const savedPaynowMeterEntriesSelector = createSelector(
    accountsSliceSelector,
    accounts => accounts.savedPaynowMeterEntries
);

export const accountsWithoutMetersLoadedSelector = createSelector(
    accountsWithoutMetersSliceSelector,
    accounts => accounts.accountsLoaded
);
