
import { PaymentCreateInput } from '../../../../common/model/payment/paymentCreateInput';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { setPaymtneQrCreateLoading, setPaymentQrCreateLoadingError, setPaymentQrResponse, setPaymentQrCompleteLoading, removeActiveQrId } from '../../../reducers/payment/paymentQrCreate';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';
import { paymentQrCreateSliceSelector } from '../../../selectors/payment/paymentQrCreate';
import { CreateQRResponseViewModel } from 'common/model/payment/order/createQRResponseViewModel';
import { getApiRoute } from 'app/shared/pipes';
import { PaymentQrcStatusType } from 'common/model/payment/paymentQrcStatusType';
import { PaymentQRCViewModel } from 'common/model/payment/paymentQRCViewModel';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { Routes } from 'app/shared/constants';

export const createPaymentQr = (
    requestBody: PaymentCreateInput,
): AppThunk => async (dispatch, getState) => {
    const { paymentQrCreateLoading } = paymentQrCreateSliceSelector(getState());

    if (paymentQrCreateLoading) {
        return;
    }

    dispatch(setPaymentQrCreateLoadingError(null));
    dispatch(setPaymtneQrCreateLoading(true));

    try {
        const result = await httpService.post<CreateQRResponseViewModel>(
            appUrls.api_post_payment_qr,
            requestBody
        );

        dispatch(setPaymentQrResponse(result));
        dispatch(setPaymentQrCompleteLoading(true));

        const TIMEOUT = 5000;

        const checkPaymentStatus = async () => {
            const paymentStatus = await httpService.get<PaymentQRCViewModel>(
                getApiRoute(appUrls.api_get_payment_qr, { qrcId: result.qrcId })
            );

            if (
                paymentStatus.status === PaymentQrcStatusType.Created ||
                paymentStatus.status === PaymentQrcStatusType.InProgress
            ) {
                setTimeout(checkPaymentStatus, TIMEOUT);
            } else if (paymentStatus.status === PaymentQrcStatusType.Succeed) {
                dispatch(push(generatePath(Routes.wallet, { orderId: result.orderId, status: 'success' })));
                dispatch(removeActiveQrId(result));
            } else if (paymentStatus.status === PaymentQrcStatusType.Failed) {
                dispatch(setPaymtneQrCreateLoading(false));
                dispatch(removeActiveQrId(result));
                dispatch(push(generatePath(Routes.wallet, { orderId: result.orderId, status: 'failed' })));
            }
        };

        await checkPaymentStatus();
    } catch {
        const errorMessage = 'Произошла ошибка при оплате счета. Попробуйте позже';
        dispatch(setPaymentQrCreateLoadingError(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));
    }

    dispatch(setPaymtneQrCreateLoading(false));
};
