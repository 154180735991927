import { ServicesViewModel } from 'common/model/account/servicesViewModel';
import { createDataFetchSlice } from '../../../utils/dataFetch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';


export const adminServicesSlice = createDataFetchSlice<
    {total: number, data: ServicesViewModel[]}
>({ name: 'adminServices' });

interface ModifiedDataState {
    modifiedServices: ServicesViewModel[];
}
const initialState: ModifiedDataState = {
    modifiedServices: [],
};

export const adminServicesSelectionSlice = createSlice({
    name: 'modifiedData',
    initialState,
    reducers: {
        updateServiceSelection(state, action: PayloadAction<ServicesViewModel>) {
            const { id } = action.payload;
            const service = state.modifiedServices.find(item => item.id === id);

            if (service) {
                state.modifiedServices = state.modifiedServices.filter(item => item.id !== id);
            } else {
                state.modifiedServices = [...state.modifiedServices, action.payload];
            }
        },
        clearModifiedServices(state) {
            state.modifiedServices = [];
        },
    },
});
