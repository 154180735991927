import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../../reducers';


export const accountsClaimsDocumentSliceSelector = (state: StorageState) => state.accounts.claims.document;

export const accountsClaimsDocumentIsLoadingSelector = createSelector(
    accountsClaimsDocumentSliceSelector,
    slice => slice.isLoading,
);
