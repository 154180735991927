import { StorageState } from '../../reducers';
import { createSelector } from 'reselect';

const baseSelector = (state: StorageState) => state.resources;

export const resourcesSettingsSelector = (state: StorageState) => state.resources.settings;

export const resourcesWebClientVersionSelector = () => createSelector(
    baseSelector,
    resources => resources.settings.webClientVersion
);

export const resourcesSelector = () => createSelector(baseSelector, resources => ({
    D: resources.dictionaries,
    settings: resources.settings,
}));

export const resourcesUserRolesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries?.userRoles || [],
);

export const resourcesAccessRulesSelector = () => createSelector(
    baseSelector,
    resources => resources.dictionaries?.accessRules || [],
);
