import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AccountToDeleteState {
    accountId: string;
    disabledInvoice?: boolean;
}

const initialState: AccountToDeleteState = {
    accountId: '',
    disabledInvoice: undefined,
};

const accountToDeleteSlice = createSlice({
    name: 'accountToDelete',
    initialState,
    reducers: {
        setAccountToDelete: (state, action: PayloadAction<{ accountId: string, disabledInvoice?: boolean }>) => {
            state.accountId = action.payload.accountId;
            state.disabledInvoice = action.payload.disabledInvoice;
        },
        removeAccountToDelete: (state) => {
            state.accountId = '';
            state.disabledInvoice = undefined;
        },
    },
});

export const { removeAccountToDelete, setAccountToDelete } = accountToDeleteSlice.actions;
export default accountToDeleteSlice.reducer;
