export enum VIFontSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export enum VILetterSpacing {
    Normal = 'normal',
    Increased = 'medium',
    Big = 'large',
}

export enum VIFontType {
    SansSerif = 'sans-serif',
    Serif = 'serif',
}

export enum VITheme {
    Black = 'black',
    White = 'white',
    Blue = 'blue',
}

export interface VisuallyImpairedSettings {
    isMobileMenuOpen: boolean,
    status: boolean;
    fontSize: VIFontSize;
    letterSpacing: VILetterSpacing;
    fontType: VIFontType;
    theme: VITheme;
    image: boolean;
}
