export enum MeterCurrentReadingOrderStatusError {
    meterNotFound= 'ПУ не найден',
    operationNotAvailable = 'Операция недоступна',
    invalidMeterStatus = 'Неверный статус ПУ',
    rejectedDueToPendingOrder = 'Запрос отклонен, так как операция в процессе',
    outOfWorkTime = 'Операция недоступна в данном периоде',
}

export const meterOrdersErrorStatusTypesDictionary: Record<MeterCurrentReadingOrderStatusError, string> = {
    [MeterCurrentReadingOrderStatusError.meterNotFound]: 'Невозможно создать заявку, прибор учета не определен. Попробуйте выполнить запрос позднее. Если ошибка возникнет повторно - обратитесь к представителю компании',
    [MeterCurrentReadingOrderStatusError.operationNotAvailable]: 'Операция недоступна. Попробуйте выполнить запрос позднее. Если ошибка возникнет повторно - обратитесь к представителю компании.',
    [MeterCurrentReadingOrderStatusError.invalidMeterStatus]: 'Невозможно создать заявку, так как прибор учета не активен. Попробуйте выполнить запрос позднее. Если ошибка возникнет повторно - обратитесь к представителю компании.',
    [MeterCurrentReadingOrderStatusError.rejectedDueToPendingOrder]: 'Запрос отклонен, так как операция в процессе выполнения.',
    [MeterCurrentReadingOrderStatusError.outOfWorkTime]: 'Операция временно недоступна. Попробуйте выполнить запрос позднее. Если ошибка возникнет повторно - обратитесь к представителю компании.',
};
