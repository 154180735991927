import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const meterReadingsSliceSelector = (state: StorageState) => state.meterReadings;

export const historiesMeterEntriesSelector = createSelector(
    meterReadingsSliceSelector,
    meterReadings => meterReadings.historiesMeterEntries
);

export const historiesMeterEntriesLoadingSelector = createSelector(
    meterReadingsSliceSelector,
    meterReadings => meterReadings.historiesMeterEntriesLoading
);

export const historiesMeterEntriesLoadedSelector = createSelector(
    meterReadingsSliceSelector,
    meterReadings => meterReadings.historiesMeterEntriesLoaded
);
