import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientCurrentRegionSelector } from 'redux/selectors';
import { currentDivisionDataSelector } from 'redux/selectors/app';
import { setDivisionSettings } from 'redux/actions/app';
import { RegionsToDivisionTypeMap, DivisionTypeDictionary, DivisionType } from 'common/model/client/divisionType';
import { Regions } from 'common/model/account/regions';
import { DivisionFavLogoDictionary } from '../../model/common';

export const CurrentDivisionService: React.FC = () => {
    const dispatch = useDispatch();
    const currentRegion = useSelector(clientCurrentRegionSelector);
    const previewRegion = useRef(currentRegion);
    const currentDivision = useSelector(currentDivisionDataSelector);
    const favicon: HTMLElement | null = document.getElementById('favicon');

    useEffect(() => {
        if (!currentDivision || previewRegion.current !== currentRegion) {
            previewRegion.current = currentRegion;

            let divisionType: DivisionType | null = null;
            if (currentRegion) {
                divisionType = RegionsToDivisionTypeMap[currentRegion as Regions];
            }

            const divisionSettings = DivisionTypeDictionary[
                divisionType && divisionType in DivisionTypeDictionary ? divisionType : DivisionType.Vostok
            ];

            favicon?.setAttribute('href', DivisionFavLogoDictionary[divisionSettings.type]);
            dispatch(setDivisionSettings(divisionSettings));
        }
    }, [dispatch, currentDivision, favicon, currentRegion]);

    return null;
};
