import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentViewModel } from '../../../../../../common/model/payment/paymentViewModel';
import { PaymentHistoryResponse } from '../../../../../../common/model/payment/paymentHistoryResponse';
import { ActionType } from '../../../actions/model';

export interface PaymentsHistoryState {
    list: PaymentViewModel[] | null;
    total: number;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: PaymentsHistoryState = {
    list: null,
    total: 0,
    isLoading: false,
    isLoaded: false,
};

export const paymentsHistorySlice = createSlice({
    name: '@@vp/payment/paymentsHistory',
    initialState,
    reducers: {
        setPaymentsHistoryList(state, action: PayloadAction<PaymentHistoryResponse>): void {
            state.list = action.payload.data;
            state.total = action.payload.total;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<PaymentsHistoryState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        clearPaymentsHistory(state): void {
            state.list = null;
            state.total = 0;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): PaymentsHistoryState => initialState,
    }
});

export const {
    setPaymentsHistoryList,
    setLoading,
    clearPaymentsHistory
} = paymentsHistorySlice.actions;
