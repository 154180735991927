import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const ticketsListSliceSelector = (state: StorageState) => state.tickets.ticketsList;

export const ticketsListSelector = createSelector(
    ticketsListSliceSelector,
    tickets => tickets.list
);

export const ticketsListLoadedSelector = createSelector(
    ticketsListSliceSelector,
    tickets => tickets.isLoading
);

export const ticketsListLoadingSelector = createSelector(
    ticketsListSliceSelector,
    tickets => tickets.isLoading
);
