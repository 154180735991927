import { getApiRoute, RequestParams } from '../../../app/shared/pipes';
import { httpService } from '../../../app/shared/httpWrapper';
import { appUrls } from '../../../common/appUrls';
import { AppThunk } from '../../reducers';
import { modalNotificationsPush } from '..';
import {
    HistoriesMeterEntries
} from '../../../../../common/model/meterReadings/historyMeterEntries';
import {
    setHistoriesMeterEntries,
    setHistoriesMeterEntriesLoading,
    clearMeterEntriesDataData,
} from '../../reducers/meterReadings';

export const getHistoriesMeterEntries = (options?: RequestParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setHistoriesMeterEntriesLoading(true));

    try {
        const route = getApiRoute(appUrls.api_get_history_entries, {}, options);
        const data = await httpService.get<HistoriesMeterEntries[]>(route);
        dispatch(setHistoriesMeterEntries(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка статистики. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setHistoriesMeterEntriesLoading(false));
};

export const resetMeterEntriesData = (): AppThunk => (dispatch) => {
    dispatch(clearMeterEntriesDataData());
};
