import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../../actions/model';


export interface AccountsClaimsDocumentState {
  isLoading: boolean;
}

const initialState: AccountsClaimsDocumentState = {
    isLoading: false,
};

export const accountsClaimsDocumentSlice = createSlice({
    name: '@@vp/accounts/claims/document',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<AccountsClaimsDocumentState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccountsClaimsDocumentState => initialState,
    }
});
