import { claims } from './claims';
import { accountsInvoiceRequestSlice } from './invoiceRequest';
import { accountStatistic } from './statistic';
import { accountsChargesSelection } from './chargesSelection';
import { accountsWithBalanceAndChargesSlice } from './balanceAndCharges';
import { accountsWithMetersAndEntriesSlice } from './meterAndEntries';
import { combineReducers } from 'redux';
import { accountsWithoutMetersAndEntriesSlice } from './withoutMeterAndEntires';
import { tecnhicalDocumentsSlice } from './documents';

export const accounts = combineReducers({
    metersAndEntries: accountsWithMetersAndEntriesSlice.reducer,
    withoutMetersAndEntries: accountsWithoutMetersAndEntriesSlice.reducer,
    balanceAndCharges: accountsWithBalanceAndChargesSlice.reducer,
    chargesSelection: accountsChargesSelection.reducer,
    statistic: accountStatistic,
    invoiceRequest: accountsInvoiceRequestSlice.reducer,
    claims,
    tecnhicalDocuments: tecnhicalDocumentsSlice.reducer
});
