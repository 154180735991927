import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';


interface AdminNotificationsCreateState {
    isLoading: boolean;
    error: string | null;
}

const initialState: AdminNotificationsCreateState = {
    isLoading: false,
    error: null,
};

export const adminNotificationsCreateSlice = createSlice({
    name: '@@vp/adminNotifications/edit',
    initialState,
    reducers: {
        startRequest(state): void {
            state.isLoading = true;
            state.error = null;
        },
        requestSuccess(state) {
            state.isLoading = false;
        },
        requestFailed(state, action: PayloadAction<AdminNotificationsCreateState['error']>): void {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminNotificationsCreateState => initialState,
    }
});
