import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultUnauthorizedPayment } from '../../../../../../common/model/unauthorized/payment';
import { AccountViewModel } from '../../../../common/model/account/accountViewModel';

export interface UnauthorizedPaymentState {
    unauthorizedPayment: ResultUnauthorizedPayment[];
    unauthorizedAccount: AccountViewModel;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: UnauthorizedPaymentState = {
    unauthorizedPayment: [],
    unauthorizedAccount: {},
    isLoading: false,
    isLoaded: false
};

export const unauthorizedPaymentSlice = createSlice({
    name: '@@vp/unauthorizedPayment',
    initialState,
    reducers: {
        setUnauthorizedPayment(state, action: PayloadAction<UnauthorizedPaymentState['unauthorizedPayment']>): void {
            state.unauthorizedPayment = action.payload;
            state.isLoaded = true;
        },
        setUnauthorizedAccount(state, action: PayloadAction<UnauthorizedPaymentState['unauthorizedAccount']>): void {
            state.unauthorizedAccount = action.payload;
            state.isLoaded = true;
        },
        setLoadingUnauthorizedPayment(state, action: PayloadAction<UnauthorizedPaymentState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    }
});

export const {
    setUnauthorizedPayment,
    setLoadingUnauthorizedPayment,
    setUnauthorizedAccount
} = unauthorizedPaymentSlice.actions;
