import { push } from 'connected-react-router';
import {
    setProfileUpdating,
    setProfileUpdatingActiveField,
    setProfileUpdatingError,
    setProfileUpdatingFields
} from 'redux/reducers/client/profileUpdating';
import { AddAccountRequestInput } from '../../../../../../common/model/client/addAccountRequest';
import { Routes, HttpStatusCode } from '../../../../app/shared/constants';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute, getRoute, RequestParams } from '../../../../app/shared/pipes';
import { AddAccountInput } from '../../../../common/model/client/addAccountRequest';
import { PasswordUpdateInput } from '../../../../common/model/client/passwordUpdateInput';
import { UpdatePhoneInput } from '../../../../common/model/client/updatePhoneInput';
import { OTPChecker } from '../../../../common/model/otp/oTPChecker';
import { OtpCreateResult } from '../../../../common/model/otp/otpCreateResult';
import { objectEntries } from '../../../../common/shared/utils/object';
import { AppThunk } from '../../../reducers';
import { clientProfileSlice } from '../../../reducers/client/profile';
import { clientProfileUpdatingSliceSelector } from '../../../selectors';
import { getAccountsWithBalanceAndCharges, getAccountsWithMetersAndEntries } from '../../accounts';
import { createSnackbar, modalNotificationsPush } from '../../controls';
import { getClientAccounts } from '../accounts';
import { appUrls } from '../../../../common/appUrls';
import { ClientProfileUpdateViewModel } from '../../../../common/model/client/clientProfileUpdateViewModel';
import { ClientProfileExtViewModel } from '../../../../common/model/client/clientProfileExtViewModel';
import { VKResponse } from '../../../../common/model/client/socialNetworks/VKResponse';
import { getClientProfile } from '../profile';

export const updateProfile = (updater: ClientProfileUpdateViewModel, onSuccess?: () => void): AppThunk => async (dispatch, getState) => {
    const { profileIsUpdating } = clientProfileUpdatingSliceSelector(getState());

    if (profileIsUpdating) {
        return;
    }

    const updatingFields = objectEntries(updater)
        .filter(([, value]) => value !== undefined)
        .map(([key]) => key);

    dispatch(setProfileUpdatingError(null));
    dispatch(setProfileUpdating(true));
    dispatch(setProfileUpdatingFields(updatingFields));

    try {
        const result = await httpService.put<ClientProfileExtViewModel>(
            appUrls.api_put_client_profile,
            updater,
        );
        dispatch(setProfileUpdatingActiveField(null));
        dispatch(clientProfileSlice.actions.fetchSuccess({ data: result }));
        if (updater.email) {
            dispatch(createSnackbar({
                type: 'info',
                message: 'Проверьте новую почту. Мы выслали на нее письмо со ссылкой - подтверждением.',
            }));
        }
        onSuccess?.();
    } catch (err: any) {
        const validationErrors = err.status === HttpStatusCode.badRequest ? err?.data?.errorDetails : undefined;

        const errorMessage = 'Произошла ошибка при изменении профиля клиента. Попробуйте позже';
        dispatch(setProfileUpdatingError({ avatar: validationErrors ?? errorMessage }));

        if (!validationErrors) {
            // eslint-disable-next-line no-console
            console.error(err);

            dispatch(modalNotificationsPush({
                title: 'Ошибка',
                message: errorMessage,
                type: 'error',
            }));
        }
    }

    dispatch(setProfileUpdatingFields([]));
    dispatch(setProfileUpdating(false));
};

export const requestUpdateClientPhone = (updater: UpdatePhoneInput): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_post_client_phone_change_request);
        const otpSession = await httpService.post<OtpCreateResult>(route, updater);
        const confirmRoute = getRoute(Routes.confirmPhoneChange, {}, { sessionId: otpSession.sessionId });
        dispatch(push(confirmRoute));
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'При изменении номера телефона произошла ошибка. Попробуйте позже'
        }));
    }
};

export const updateClientPhone = (otpChecker: OTPChecker): AppThunk => async (dispatch) => {

    try {
        dispatch(setProfileUpdating(true));
        const route = getApiRoute(appUrls.api_put_client_phone);
        const updatedProfile = await httpService.put<ClientProfileExtViewModel>(route, otpChecker);
        dispatch(clientProfileSlice.actions.fetchSuccess({ data: updatedProfile }));

        dispatch(push(Routes.profile));
        dispatch(createSnackbar({
            type: 'success',
            message: 'Номер телефона успешно изменён'
        }));
        return;
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'При проверке кода произошла ошибка. Попробуйте позже'
        }));
    } finally {
        dispatch(setProfileUpdatingActiveField(null));
        dispatch(setProfileUpdatingFields([]));
        dispatch(setProfileUpdating(false));
    }
};

export const updateClientPassword = (updater: PasswordUpdateInput): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_put_client_password);
    await httpService.put(route, updater);
    dispatch(getClientProfile());
    dispatch(createSnackbar({
        type: 'success',
        message: 'Пароль успешно изменён'
    }));
};

export const addAccountRequest = (input: AddAccountRequestInput): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_client_add_account_request);
    try {
        const { sessionId } = await httpService.post<OtpCreateResult>(route, input);
        const confirmRoute = getRoute(Routes.addAccountConfirm, {}, {
            sessionId,
            accountNumber: input.accountNumber,
            answer: input.answer
        });
        dispatch(push(confirmRoute));
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'При проверке реквизитов счёта произошла ошибка. Попробуйте позже'
        }));
    }
};

export const addAccount = (input: AddAccountInput): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_post_client_add_account);
    try {
        await httpService.post<boolean>(route, input);
        dispatch(getClientAccounts({ includeAutopaySettings: true }));
        dispatch(getAccountsWithBalanceAndCharges());
        dispatch(getAccountsWithMetersAndEntries());
        dispatch(push(Routes.profile));
        dispatch(createSnackbar({
            type: 'success',
            message: 'Лицевой счёт успешно добавлен'
        }));
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'При проверке реквизитов счёта произошла ошибка. Попробуйте позже'
        }));
    }
};

export const addSocialNetwork = (params: string, queryParams?: RequestParams): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.app_get_client_social_network_confirm, { socialNetwork: params }, { ...queryParams });
    try {
        await httpService.get<VKResponse>(route);
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'Ошибка при авторизации через социальную сеть. Попробуйте позже.'
        }));
    } finally {
        dispatch(getClientProfile());
        dispatch(push(Routes.profile));
    }
};

export const removeSocialNetwork = (params: string): AppThunk => async (dispatch): Promise<void> => {
    const route = getApiRoute(appUrls.app_delete_client_social_network, { socialNetwork: params });
    try {
        await httpService.delete(route);
        dispatch(getClientProfile());
    } catch (error: any) {
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'Ошибка при отвязке социальной сети. Попробуйте позже.'
        }));
    }
};
