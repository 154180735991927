import { paymentQuittanceSlice } from './quittance/index';
import { autopaySettingsSlice } from './autopaySettings';
import { combineReducers } from 'redux';
import { paymentOrderSlice } from './paymentOrder';
import { paymentsHistorySlice } from './paymentsHistory';
import { paymentDetailsSlice } from './paymentDetails';
import { paymentQrCreateSlice } from './paymentQrCreate';
import { paymentQrCallbackSlice } from './paymentQrCallback';
import { paymentSberPayCreateSlice } from './paymentSberPayCreate';
import { paymentSberPayCallbackSlice } from './paymentSberPayCallback';

export const payment = combineReducers({
    paymentOrder: paymentOrderSlice.reducer,
    paymentsHistory: paymentsHistorySlice.reducer,
    paymentDetails: paymentDetailsSlice.reducer,
    autopaySettings: autopaySettingsSlice.reducer,
    quittance: paymentQuittanceSlice.reducer,
    paymentQrCreate: paymentQrCreateSlice.reducer,
    paymentQrCallback: paymentQrCallbackSlice.reducer,
    paymentSberPayCreate: paymentSberPayCreateSlice.reducer,
    paymentSberPayCallback: paymentSberPayCallbackSlice.reducer,
});
