import { createDataFetchSlice } from '../../../utils/dataFetch';

export type TechnicalDocumentViewModel = {
    'id': string,
    'accountId': string,
    'documentId': string,
    'documentType': string,
    'createdAt': Date
}

export const tecnhicalDocumentsSlice = createDataFetchSlice<
    TechnicalDocumentViewModel[]
>({ name: 'techicalDocuments' });

export const downloadTechnicalDocumentSlice = createDataFetchSlice({ name: 'downloadTechnicalDocument' });
