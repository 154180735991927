import React from 'react';
import { AMOUNT_DIGITS_AFTER_DOT } from 'common/constants/payment';
import { withCapitalLetter } from 'common/shared/utils/text';
import { MeterEntriesValidateSuccess } from 'common/model/meterReadings/meterEntriesValidateSuccess';
import { Typography } from 'app/shared/components/typography';

export const MeterEntriesConfirmationMessage: React.FC<{
    entries: MeterEntriesValidateSuccess[],
    isValidate?: boolean;
}> = ({ entries, isValidate }) => {

    return <>
        <div style={{ marginBottom: 8 }}>
            <Typography variant='body'>{!isValidate ? 'Спасибо, ваши показания приняты!' : ''}</Typography>
        </div>
        {Object.values(entries)?.map(({ serviceName, measure, consumption, amount, mainDebt }, index) => {
            return <div key={index}>
                <Typography variant='bodyBold'>{withCapitalLetter(serviceName)}:</Typography>
                <div style={{ marginBottom: 24 }}>
                    Расход по переданным показаниям составил
                    <Typography variant='bodyBold' component='span'>
                        &nbsp;{consumption} {measure}&nbsp;
                    </Typography>
                    на&nbsp;сумму
                    <Typography variant='bodyBold' component='span'>
                        &nbsp;{amount.toFixed(AMOUNT_DIGITS_AFTER_DOT)}&nbsp;руб.
                    </Typography>
                </div>
                <div style={{ marginBottom: 24 }}>
                    Итого к оплате по услуге{' '}
                    <Typography variant='bodyBold' component='span'>
                        {(mainDebt ?? 0).toFixed(AMOUNT_DIGITS_AFTER_DOT)} руб.
                    </Typography>
                </div>
            </div>;
        })}
        <Typography variant='body'>
            По всем вопросам обращайтесь в справочную службу по телефону 8 (800) 250-60-06 (бесплатно).
        </Typography>
    </>;
};
