import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { DivisionSetting } from '../../../../common/model/client/divisionType';

export interface CurrentDivisionState {
  data?: DivisionSetting;
}

const initialState: CurrentDivisionState = {};

export const currentDivisionSlice = createSlice({
    name: '@@vp/clientProfileUpdating',
    initialState,
    reducers: {
        setCurrentDivisionSettings(state, action: PayloadAction<DivisionSetting>): void {
            state.data = action.payload;
        },
        clearCurrentDivision(state): void {
            state.data = undefined;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): CurrentDivisionState => initialState,
    }
});

export const {
    setCurrentDivisionSettings,
} = currentDivisionSlice.actions;
