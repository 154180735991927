import { createSelector, createSlice, OutputSelector, PayloadAction, Selector } from '@reduxjs/toolkit';
import { Draft } from 'immer';
import { DEFAULT_PAGE_SIZE } from '../../common/constants/common';
import { StorageState } from '../reducers';
import { ActionType } from '../actions/model';

interface DataFetchSliceCreationOptions<F> {
    defaultFilter?: F;
    pageSize?: number;
}

interface DataFetchSliceState<T, F> {
    data: T | null
    isLoading: boolean;
    isLoaded: boolean;
    error: string | null;
    pagination: {
        offset: number;
        count: number;
        total: number;
        pageSize: number;
    };
    filter: F | null;
}

const getInitialState = <T, F>(options?: DataFetchSliceCreationOptions<F>): DataFetchSliceState<T, F> => ({
    data: null,
    isLoading: false,
    isLoaded: false,
    error: null,
    pagination: {
        offset: 0,
        count: 0,
        total: 0,
        pageSize: options?.pageSize ?? DEFAULT_PAGE_SIZE,
    },
    filter: options?.defaultFilter ?? null,
});

export const createDataFetchSlice = <T, F extends Record<string, any> = Record<string, unknown>>({
    name,
    options,
}: {
    name: string;
    options?: DataFetchSliceCreationOptions<F>;
}) => createSlice({
        name: `@@vp/${name}`,
        initialState: getInitialState<T, F>(options),
        reducers: {
            fetchStart(state): void {
                state.isLoading = true;
                state.isLoaded = false;
                state.error = null;
            },
            fetchSuccess(
                state,
                action: PayloadAction<{
                    data: DataFetchSliceState<T, F>['data'],
                    pagination?: Pick<Partial<DataFetchSliceState<T, F>['pagination']>, 'offset' | 'count' | 'total'>
                }>
            ): void {
                state.data = action.payload.data as Draft<T>;
                state.isLoading = false;
                state.isLoaded = true;

                if (action.payload.pagination?.offset !== undefined) {
                    state.pagination.offset = action.payload.pagination.offset;
                }

                if (action.payload.pagination?.count !== undefined) {
                    state.pagination.count = action.payload.pagination.count;
                }

                if (action.payload.pagination?.total !== undefined) {
                    state.pagination.total = action.payload.pagination.total;
                }
            },
            fetchFailure(state, action: PayloadAction<DataFetchSliceState<T, F>['error']>): void {
                state.isLoading = false;
                state.isLoaded = false;
                state.error = action.payload;
            },
            setFilter(state, action: PayloadAction<DataFetchSliceState<T, F>['filter']>): void {
                state.filter = action.payload as Draft<F>;
            },
            clearList(state): void {
                state.data = null;
                state.isLoading = false;
                state.isLoaded = false;
            },
        },
        extraReducers: {
            [ActionType.LOGOUT]: getInitialState as () => DataFetchSliceState<T, F>
        }
    });

type CombinerReturnType<
    T,
    K extends keyof DataFetchSliceState<T, F>,
    F extends Record<string, any> = Record<string, unknown>,
> = DataFetchSliceState<T, F>[K];
type DataFetchSliceSelectors<T, F extends Record<string, any> = Record<string, unknown>> = {
    [K in keyof DataFetchSliceState<T, F>]: OutputSelector<
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        StorageState,
        CombinerReturnType<T, K>,
        (res: DataFetchSliceState<T, F>) => CombinerReturnType<T, K>
    >
};

export const createDataFetchSliceSelectors = <T, F>(
    sliceSelector: Selector<StorageState, DataFetchSliceState<T, F>>
): DataFetchSliceSelectors<T> => {
    return Object.keys(getInitialState<T, F>()).reduce((acc, key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[key] = createSelector(
            sliceSelector,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            slice => slice[key],
        );
        return acc;
    }, {} as DataFetchSliceSelectors<T>);
};
