import React from 'react';
import { CircularProgress } from '@material-ui/core';
import './style.scss';

export class View extends React.Component {
    render() {
        return <div className='waiting-loader'>
            <div className='waiting-loader-back' />
            <div className='waiting-loader-fan'>
                <CircularProgress />
            </div>
        </div>;
    }
}
