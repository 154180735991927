import { StorageState } from '../../reducers';
import { createSelector } from 'reselect';

const controlsSelector = (state: StorageState) => state.controls;

export const controlsSnackbarSelector = () => createSelector(
    controlsSelector,
    controls => controls.snackbar
);

export const controlsModalNotificationsSelector = () => createSelector(
    controlsSelector,
    controls => controls.modalNotifications
);
