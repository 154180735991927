import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientNotificationViewModel } from 'common/model/notification/clientNotificationViewModel';
import { ActionType } from '../../actions/model';

export interface NotificationsState {
    list: ClientNotificationViewModel[] | null;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: NotificationsState = {
    list: null,
    isLoading: false,
    isLoaded: false,
};

export const notificationsSlice = createSlice({
    name: '@@vp/clients/reports/list',
    initialState,
    reducers: {
        setNotificationsList(state, action: PayloadAction<ClientNotificationViewModel[]>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoadingNotifications(state, action: PayloadAction<NotificationsState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        clearNotifications(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
        updateViewedNotification(state, action: PayloadAction<string>): void {
            state.list = state.list?.map(item => {
                const currentId = action.payload;
                if (item.id === currentId) {
                    return {
                        ...item,
                        viewed: true
                    };
                }
                return item;
            }) ?? null;
        },
        updateViewedAllNotification(state): void {
            state.list = state.list?.map(item => {
                return {
                    ...item,
                    viewed: true
                };
            }) ?? null;
        },
        deleteNotification(state, action: PayloadAction<string>): void {
            state.list = state.list?.filter(item => item.id !== action.payload) ?? null;
        },
        deleteAllNotifications(state): void {
            state.list = null;
            state.isLoaded = true;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): NotificationsState => initialState,
    }
});

export const {
    setNotificationsList,
    setLoadingNotifications,
    clearNotifications,
    updateViewedNotification,
    updateViewedAllNotification,
    deleteNotification,
    deleteAllNotifications
} = notificationsSlice.actions;
