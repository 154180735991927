import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleViewModel } from '../../../../../common/model/users/role/roleViewModel';
import { ActionType } from '../../../../actions/model';

export interface RolesListAccessState {
    list: RoleViewModel[];
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: RolesListAccessState = {
    list: [],
    isLoading: false,
    isLoaded: false,
};

export const rolesListAccessSlice = createSlice({
    name: '@@vp/users/roles/list',
    initialState,
    reducers: {
        setRolesList(state, action: PayloadAction<RoleViewModel[]>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<RolesListAccessState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): RolesListAccessState => initialState,
    }
});

export const {
    setRolesList,
    setLoading
} = rolesListAccessSlice.actions;
