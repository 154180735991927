import { adminNotificationsCreateSlice } from './create/index';
import { adminNotificationsEditSlice } from './edit/index';
import { adminNotificationsListSlice } from './list';
import { combineReducers } from 'redux';

export const adminNotifications = combineReducers({
    list: adminNotificationsListSlice.reducer,
    create: adminNotificationsCreateSlice.reducer,
    edit: adminNotificationsEditSlice.reducer,
});
