import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientProfileUpdateViewModel } from '../../../../common/model/client/clientProfileUpdateViewModel';

export interface ClientProfileUpdatingState {
  profileUpdatingActiveField: keyof ClientProfileUpdateViewModel | null;
  profileIsUpdating: boolean;
  profileUpdatingFields: (keyof ClientProfileUpdateViewModel)[];
  profileUpdatingError: string | Partial<Record<keyof ClientProfileUpdateViewModel, string>> | null;
}

const initialState: ClientProfileUpdatingState = {
    profileUpdatingActiveField: null,
    profileIsUpdating: false,
    profileUpdatingFields: [],
    profileUpdatingError: null,
};

export const clientProfileUpdatingSlice = createSlice({
    name: '@@vp/clientProfileUpdating',
    initialState,
    reducers: {
        setProfileUpdatingActiveField(state, action: PayloadAction<ClientProfileUpdatingState['profileUpdatingActiveField']>): void {
            state.profileUpdatingActiveField = action.payload;
        },
        setProfileUpdating(state, action: PayloadAction<ClientProfileUpdatingState['profileIsUpdating']>): void {
            state.profileIsUpdating = action.payload;
        },
        setProfileUpdatingFields(state, action: PayloadAction<ClientProfileUpdatingState['profileUpdatingFields']>): void {
            state.profileUpdatingFields = action.payload;
        },
        setProfileUpdatingError(state, action: PayloadAction<ClientProfileUpdatingState['profileUpdatingError']>): void {
            state.profileUpdatingError = action.payload;
        },
    }
});

export const {
    setProfileUpdatingActiveField,
    setProfileUpdating,
    setProfileUpdatingFields,
    setProfileUpdatingError,
} = clientProfileUpdatingSlice.actions;
