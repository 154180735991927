import { isNullOrUndefined } from '../../shared/utils';
import { VatType } from '../account/vatType';
import { Regions } from "../account/regions";

/**
 * Подразделения
 * https://docs.google.com/spreadsheets/d/1P-7iz0pudhKQVJYFje6okDvl5W3ND-f5nwZUL9nFWwE/edit#gid=693141117
 */
export enum DivisionType {
    /** ЭК Восток */
    Vostok,
    /** ЕРИЦ */
    Eric,
    /** НТЭСК */
    Ntesk,
    /** РИЦ */
    Ric,
    /** ЮРИЦ */
    Uric,
    /** Биллинг */
    Billing
}

export enum DivisionDomain {
    Vostok = 'flk.vostok-electra.ru',
    Eric = 'flk.eric-yanao.ru',
    Ntesk = 'flk.ntesk.ru',
    Ric = 'flk.ricso.ru',
    Uric = 'flk.yritz.ru',
}

export enum SocialNetworkType {
    VK = 'VK',
    FC = 'FC',
    TG = 'TG',
    YT = 'YT',
    INST = 'INST',
}

export interface SocialNetwork {
    type: SocialNetworkType;
    link: string;
    logo?: string;
}

export interface DivisionSetting {
    title: string;
    inn: string;
    fiscalName: string;
    fiscalInn: string;
    email: string;
    contactEmail: string;
    defaultVatType: VatType;
    smsSourceAddress: string;
    codeViewReading: number;
    domainURL: string;
    infoPhoneNumber: string;
    phoneNumber: string;
    additionalPhoneNumber?: string;
    address: string;
    copyright: string;
    privacyPolicy: string;
    termsOfUse: string;
    socialNetworks: SocialNetwork[];
    type: DivisionType;
    webSiteUrl: string;
    VK: VKSetting;
    workHours: string;
    lunchHours?: string;
}

export interface VKSetting {
    clientId: string;
    redirectUri: string;
    redirectUriLogin: string;
    display: 'page' | 'popup' | 'mobile';
    scope?: string;
}

export const DivisionTypeDictionary: Record<DivisionType, DivisionSetting> = {
    [DivisionType.Vostok]: {
        title: 'АО "ЭК Восток"',
        inn: '7705424509',
        fiscalInn: '4501127196',
        fiscalName: 'ЕРЦ Прогресс',
        email: 'no-reply-flk@vostok-electra.ru',
        contactEmail: 'lk.erc@erc-progress.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'EK_Vostok',
        codeViewReading: 32,
        domainURL: DivisionDomain.Vostok,
        infoPhoneNumber: '8 (800) 250-60-06',
        phoneNumber: '8 (800) 250-60-06',
        additionalPhoneNumber: '',
        address: '107045, г. Москва, Вн.тер.г. Муниципальный округ Красносельский, пер. Просвирин, 4',
        copyright: 'АО «ЭК «Восток»',
        termsOfUse: './documents/terms_of_use.pdf',
        privacyPolicy: './documents/privacy_policy.pdf',
        workHours: 'Будние дни с 8:00 до 17:00',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/vostok_electra',
            },
            {
                type: SocialNetworkType.INST,
                link: 'https://www.instagram.com/vostok.electra/',
            }
        ],
        type: DivisionType.Vostok,
        webSiteUrl: 'https://flk.vostok-electra.ru/',
        VK: {
            clientId: '8068656',
            redirectUri: '/social-confirm/vk',
            redirectUriLogin: '/social-confirm-login/vk',
            display: 'page'
        },
    },
    [DivisionType.Eric]: {
        title: 'ЕРИЦ ЯНАО',
        inn: '8901025439',
        fiscalInn: '8901025439',
        fiscalName: 'ЕРИЦ ЯНАО',
        email: 'no-reply-flk@eric-yanao.ru',
        contactEmail: 'lk@eric-yanao.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'ERIC_YANAO',
        codeViewReading: 43,
        domainURL: DivisionDomain.Eric,
        infoPhoneNumber: '8 (800) 250-60-06',
        phoneNumber: '8 (800) 250-60-06',
        address: '629008, ЯНАО, г. Салехард, ул. Броднева, 55',
        copyright: 'АО «ЕРИЦ ЯНАО»',
        workHours: 'Будние дни с 8:30 до 17:12',
        lunchHours: '12-30 до 14-00',
        privacyPolicy: 'https://flk.eric-yanao.ru/documents/upload/polozhenie-ob-obrabotke-personalnykh-dannykh-erits-yanaopdf_429904.pdf',
        termsOfUse: 'https://flk.eric-yanao.ru/upload/medialibrary/ca1/polzovatelskoe-soglashenie.pdf',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/public200566212',
            },
            {
                type: SocialNetworkType.YT,
                link: 'https://www.youtube.com/channel/UCPRAhaGv-4JIqj5kmymRsrw',
            }
        ],
        type: DivisionType.Eric,
        webSiteUrl: 'https://flk.eric-yanao.ru/',
        VK: {
            clientId: '8068656',
            redirectUri: `/social-confirm/vk`,
            redirectUriLogin: `/social-confirm-login/vk`,
            display: 'page'
        },
    },
    [DivisionType.Ntesk]: {
        title: 'НТЭСК',
        inn: '5544332219',
        fiscalInn: '5544332219',
        fiscalName: 'НТЭСК',
        email: 'lk.erc@erc-progress.ru',
        contactEmail: 'lk@ntesk.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'EK_Vostok',
        codeViewReading: 32,
        domainURL: DivisionDomain.Ntesk,
        infoPhoneNumber: '+7 (3435) 23-02-55',
        phoneNumber: '8 (800) 250-60-06',
        additionalPhoneNumber: '5',
        address: 'Нижний Тагил, ул. Красноармейская, 60',
        copyright: 'АО «Нижнетагильская Энергосбытовая компания»',
        workHours: 'Будние дни с 8:00 до 17:00',
        termsOfUse: 'https://ricso.ru/upload/medialibrary/dc9/polzovatelskoe-soglashenie-dlya-lichnogo-kabineta-rits.pdf',
        privacyPolicy: 'https://ntesk.ru/privacy/',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/ntesk',
            },
        ],
        type: DivisionType.Ntesk,
        webSiteUrl: 'https://ntesk.ru/',
        VK: {
            clientId: '8068656',
            redirectUri: `/social-confirm/vk`,
            redirectUriLogin: `/social-confirm-login/vk`,
            display: 'page'
        },
    },
    [DivisionType.Ric]: {
        title: 'РИЦ',
        inn: '6671332911',
        fiscalInn: '6671332911',
        fiscalName: 'РИЦ',
        email: 'no-reply-flk@ricso.ru',
        contactEmail: ' lk@ricso.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'RICSO',
        codeViewReading: 32,
        domainURL: DivisionDomain.Ric,
        infoPhoneNumber: '8 (800) 250-32-42',
        workHours: 'Будние дни с 8:00 до 17:00',
        phoneNumber: '8 (800) 250-32-42',
        additionalPhoneNumber: '4',
        address: '620063, г. Екатеринбург, ул. Чапаева, 14, корпус 5',
        copyright: 'АО «РИЦ»',
        termsOfUse: 'https://flk.ricso.ru/upload/medialibrary/dc9/polzovatelskoe-soglashenie-dlya-lichnogo-kabineta-rits.pdf',
        privacyPolicy: 'https://flk.ricso.ru/upload/medialibrary/b15/polozhenie-ob-obrabotke-pdn_rits-novaya-red.-07.2021.pdf',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/ricso',
            },
            {
                type: SocialNetworkType.YT,
                link: 'https://www.youtube.com/channel/UCPRAhaGv-4JIqj5kmymRsrw',
            }
        ],
        type: DivisionType.Ric,
        webSiteUrl: 'https://flk.ricso.ru/',
        VK: {
            clientId: '8068656',
            redirectUri: `/social-confirm/vk`,
            redirectUriLogin: `/social-confirm-login/vk`,
            display: 'page'
        },
    },
    [DivisionType.Uric]: {
        title: 'ЮРИЦ',
        inn: '8602286305',
        fiscalInn: '8602286305',
        fiscalName: 'ЮРИЦ',
        email: 'no-reply-flk@yritz.ru',
        contactEmail: 'lk@yritz.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'YRITZ',
        codeViewReading: 32,
        domainURL: DivisionDomain.Uric,
        infoPhoneNumber: '8 (800) 250-60-06',
        phoneNumber: '8 (800) 250-60-06',
        address: '628422, г. Сургут, ул. Энергостроителей, д. 5',
        copyright: 'ООО «ЮРИЦ»',
        workHours: 'Будние дни с 8:00 до 17:00',
        termsOfUse: 'https://flk.yritz.ru/upload/medialibrary/dc9/polzovatelskoe-soglashenie-dlya-lichnogo-kabineta-rits.pdf',
        privacyPolicy: 'https://flk.yritz.ru/upload/medialibrary/d57/politika-v-otnoshenii-obrabotki-i-zashchity-pd.pdf',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/yritz',
            },
            {
                type: SocialNetworkType.YT,
                link: 'https://www.youtube.com/channel/UCPRAhaGv-4JIqj5kmymRsrw',
            }
        ],
        type: DivisionType.Uric,
        webSiteUrl: 'https://flk.yritz.ru',
        VK: {
            clientId: '8068656',
            redirectUri: `/social-confirm/vk`,
            redirectUriLogin: `/social-confirm-login/vk`,
            display: 'page'
        },
    },
    [DivisionType.Billing]: {
        title: 'Биллинг',
        inn: '5544332219',
        fiscalInn: '5544332219',
        fiscalName: 'Биллинг',
        email: 'lk.erc@erc-progress.ru',
        contactEmail: 'lk.erc@erc-progress.ru',
        defaultVatType: VatType.Vat20,
        smsSourceAddress: 'EK_Vostok',
        codeViewReading: 32,
        domainURL: DivisionDomain.Vostok,
        infoPhoneNumber: '8 (800) 250-60-06',
        phoneNumber: '8 (800) 250-60-06',
        additionalPhoneNumber: '5',
        workHours: 'Будние дни с 8:00 до 17:00',
        address: '119121, г. Москва, ул. Бурденко, д. 22',
        copyright: 'АО «ЭК «Восток»',
        privacyPolicy: './documents/privacy_policy.pdf',
        termsOfUse: './documents/terms_of_use.pdf',
        socialNetworks: [
            {
                type: SocialNetworkType.VK,
                link: 'https://vk.com/vostok_electra',
            },
            {
                type: SocialNetworkType.INST,
                link: 'https://www.instagram.com/vostok.electra/',
            }
        ],
        type: DivisionType.Billing,
        webSiteUrl: 'https://vostok-electra.ru/',
        VK: {
            clientId: '8068656',
            redirectUri: `/social-confirm/vk`,
            redirectUriLogin: `/social-confirm-login/vk`,
            display: 'page'
        },
    },
};

export const RegionsToDivisionTypeMap: Record<Regions, DivisionType> = {
    [Regions.TjumenskajaOblast]: DivisionType.Vostok,
    [Regions.OrenburgskajaOblast]: DivisionType.Vostok,
    [Regions.KurganskajaOblast]: DivisionType.Vostok,
    [Regions.JaNAO]: DivisionType.Eric,
    [Regions.Surgut]: DivisionType.Uric,
    [Regions.SverdlovskajaOblast]: DivisionType.Ric,
};

export const isDivisionInDictionary = (division: DivisionType) => {
    return !isNullOrUndefined(division) && Object.keys(DivisionTypeDictionary).includes(division.toString());
};

export const getDivisionLkUrl = (division: DivisionType) => {
    return !isNullOrUndefined(division) && `https://${DivisionTypeDictionary[division].domainURL}`;
};

export function getDivision(divisionType?: DivisionType): DivisionSetting {
    return divisionType ? DivisionTypeDictionary[divisionType] : DivisionTypeDictionary[DivisionType.Vostok];
};

export const WorkgroupNameToDivision: Record<string, DivisionType> = {
    'АО "РИЦ"': DivisionType.Ric,
    'АО «ЭК «Восток»': DivisionType.Vostok,
    'Филиал г. Сургут': DivisionType.Uric,
    'АО «ЕРИЦ ЯНАО»': DivisionType.Eric,
}

export const HostnamesToDivisionTypesMap: Record<string, DivisionType> = {
    [DivisionDomain.Vostok]: DivisionType.Vostok,
    [DivisionDomain.Eric]: DivisionType.Eric,
    [DivisionDomain.Ric]: DivisionType.Ric,
    [DivisionDomain.Uric]: DivisionType.Uric,
}

export const HostnamesToRegionsMap: Record<string, Regions> = {
    [DivisionDomain.Vostok]: Regions.TjumenskajaOblast,
    [DivisionDomain.Eric]: Regions.JaNAO,
    [DivisionDomain.Ric]: Regions.SverdlovskajaOblast,
    [DivisionDomain.Uric]: Regions.Surgut,
}
