import { LocalStorage } from 'app/shared/constants';
import { getEnvBool } from './get-env';

interface FeatureFlags extends Record<string, string | boolean | number> {
    sbp: boolean;
    sberpay: boolean;
    paymentsTyumen: boolean;
    paymentsKurgan: boolean;
    paymentsOrenburg: boolean;
    paymentsSverdlovsk: boolean;
    paymentsSurgut: boolean;
    paymentsJanao: boolean;
    paynow: boolean;
}

interface LkFeatures {
    flags: FeatureFlags;

    setFeature: (key: keyof FeatureFlags, value: string) => void;
}

export class LkFeaturesService implements LkFeatures {
    flags: FeatureFlags;

    constructor() {
        this.flags = {
            sbp: getEnvBool('FEATURE_SBP'),
            sberpay: getEnvBool('FEATURE_SBERPAY'),
            paymentsTyumen: getEnvBool('FEATURE_PAYMENTS_TYUMEN'),
            paymentsKurgan: getEnvBool('FEATURE_PAYMENTS_KURGAN'),
            paymentsOrenburg: getEnvBool('FEATURE_PAYMENTS_ORENBURG'),
            paymentsSverdlovsk: getEnvBool('FEATURE_PAYMENTS_SVERDLOVSK'),
            paymentsSurgut: getEnvBool('FEATURE_PAYMENTS_SURGUT'),
            paymentsJanao: getEnvBool('FEATURE_PAYMENTS_JANAO'),
            paynow: getEnvBool('CLIENT_ENTRIES_PAYNOW'),
        };
    }

    setGlobal() {
        (window as any)._lk_features = {
            flags: this.flags,
            setFeature: this.setFeature.bind(this),
        };
    }

    init() {
        const storedFeatures = localStorage.getItem(LocalStorage.lk_features);

        if (storedFeatures) {
            try {
                const parsedFeatures = JSON.parse(storedFeatures);
                this.flags = { ...parsedFeatures, ...this.flags };
            } catch {
                // do nothing
            }
        }

        this.setGlobal();
        this.saveToLS();
    }

    setFeature(key: keyof FeatureFlags, value: string | boolean | number) {
        this.flags[key] = value;
        this.saveToLS();
    }

    saveToLS() {
        localStorage.setItem(LocalStorage.lk_features, JSON.stringify(this.flags));
    }
}

export const featureFlagBoolean = (feature: keyof FeatureFlags) => {
    return Boolean((window as any)._lk_features?.flags[feature]);
};
