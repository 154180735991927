import { Routes } from '../constants';
import { stringify } from 'query-string';
import { appUrls } from '../../../common/appUrls';
import { PaymentStatus } from '../../../../../common/model/payment/paymentStatus';
import { PaymentOrderStatus } from '../../../common/model/payment/order/paymentOrderEnums';
import { ClientStatusType } from '../../../common/model/client/clientStatusType';
import { PaymentSource } from '../../../../../common/model/payment/paymentSource';
import { DivisionType } from '../../../../../common/model/client/divisionType';
import { SocialRoutes } from '../../../common/model/client/socialNetworks/socialRoutes';

export interface RouterParams {
    from?: string;
    to?: string;
    role?: string;
    count?: string;
    offset?: string;
    sortFieldName?: string;
    asc?: string;
    dateFrom?: string;
    dateTo?: string;
    search?: string;
    logType?: string;
    eventType?: string;
    dateRange?: string;
    groupId?: string;
    orderId?: string;
}

export interface RequestParams {
    clientId?: string;
    from?: number;
    meterId?: string;
    meterIds?: (string | undefined)[];
    type?: string | string[],
    serviceId?: string;
    meterNumber?: string;
    dateFrom?: string;
    dateTo?: string;
    count?: number;
    offset?: number;
    sortFieldName?: string;
    asc?: boolean;
    roleId?: string;
    userId?: number;
    id?: string | number;
    limit?: number;
    schemeId?: string | number;
    groupId?: number;
    value?: string;
    search?: string;
    login?: string;
    password?: string;
    sessionId?: string;
    foreignId?: string;
    serialNum?: string;
    accountId?: string | null;
    accountStatus?: number | null;
    paymentId?: string;
    year?: number;
    month?: number;
    status?: PaymentStatus | PaymentOrderStatus | ClientStatusType;
    source?: PaymentSource;
    number?: string;
    address?: string;
    fullName?: string;
    division?: DivisionType;
    region?: string;
    timezoneOffset?: number;
    ids?: string[];
    code?: string;
    socialNetwork?: string;
    ownerFullName?: string;
    accountNumber?: string;
    isDailyReadings?: boolean;
    qrcId?: string;
    documentId?: string;
    spcId?: string;
    statuses?: ClientStatusType[];
}

export const getRoute = <T = unknown>(routeTemplate: Routes, params?: RouterParams, queryParams?: T | string): string => {
    let result: string = routeTemplate;
    if (params) {
        for (const property in params) {
            const value = params[property as keyof RouterParams];
            if (value !== undefined) {
                result = result.replace(`:${property}`, value);
            }
        }
    }

    if (queryParams) {
        if (typeof queryParams === 'string') {
            result += queryParams;
        } else {
            for (const propName in queryParams) {
                if (queryParams[propName] === null || queryParams[propName] === undefined) {
                    delete queryParams[propName];
                }
            }
            const query = stringify(queryParams);
            if (query && query.length) {
                result = `${result}?${query}`;
            }
        }
    }

    return result.replace('//', '/');
};

export const getApiRoute = (baseUrl: appUrls, params?: RequestParams, queryParams?: RequestParams): string => {
    let result = `${process.env.REACT_APP_BACKEND_URL}${baseUrl}`;
    if (params) {
        for (const property in params) {
            const value = params[property as keyof RequestParams];
            if (value !== undefined) {
                result = result.replace(`:${property}`, value as string);
            }
        }
    }
    if (queryParams) {
        for (const propName in queryParams) {
            const value = queryParams[propName as keyof RequestParams];
            if (value === null || value === undefined) {
                delete queryParams[propName as keyof RequestParams];
            }
        }
        const query = stringify(queryParams);
        if (query && query.length) {
            result = `${result}?${query}`;
        }
    }
    return result;
};

export const getExternalRoute = <T = unknown>(routeTemplate: SocialRoutes, queryParams?: T | string): string => {
    let result: string = routeTemplate;

    if (queryParams) {
        if (typeof queryParams === 'string') {
            result += queryParams;
        } else {
            for (const propName in queryParams) {
                if (queryParams[propName] === null || queryParams[propName] === undefined) {
                    delete queryParams[propName];
                }
            }
            const query = stringify(queryParams);
            if (query && query.length) {
                result = `${result}?${query}`;
            }
        }
    }

    return result;
};
