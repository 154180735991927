import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserViewModel } from '../../../../../common/model/users/user/userViewModel';
import { UsersFindResponse } from '../../../../../common/model/users/user/usersFindResponse';
import { ActionType } from '../../../../actions/model';

export interface UsersListAccessState {
    list: UserViewModel[];
    total: number;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: UsersListAccessState = {
    list: [],
    total: 0,
    isLoading: false,
    isLoaded: false,
};

export const usersListAccessSlice = createSlice({
    name: '@@vp/users/users/list',
    initialState,
    reducers: {
        setUsersList(state, action: PayloadAction<UsersFindResponse>): void {
            state.list = action.payload.data;
            state.total = action.payload.total;
            state.isLoaded = true;
        },
        setLoading(state, action: PayloadAction<UsersListAccessState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): UsersListAccessState => initialState,
    }
});

export const {
    setUsersList,
    setLoading
} = usersListAccessSlice.actions;
