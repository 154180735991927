import React from 'react';
import { IStateProps } from './model';
import classNames from 'classnames';

import classes from './Typography.module.scss';

export const View = ({ variant, component, className, children }: IStateProps) => {
    const Component = component ?? 'p';

    return (
        <Component className={classNames(classes.common, classes[variant], className)}>{children}</Component>
    );
};
