import { getApiRoute } from '../../../../../app/shared/pipes/routing';
import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { AccessRule } from '../../../../../common/model/users/accessRule';
import { RoleViewModel } from '../../../../../common/model/users/role/roleViewModel';
import { AppThunk } from '../../../../reducers';
import { setActiveRole, setAccessRules } from '../../../../reducers/users/roles/active';

export const setActiveAccessRole = (role: RoleViewModel): AppThunk => (dispatch): void => {
    dispatch(setActiveRole(role));
};

export const setActiveAccessRoleRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(setAccessRules(rules));
};

export const getActiveAccessRoleRules = (roleId: string): AppThunk => async (dispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_roles_access_rules, { roleId }));
    dispatch(setActiveAccessRoleRules(rules));
};

export const updateActiveAccessRoleRules = (
    roleId: string,
    payload: AccessRule[]
): AppThunk => async (): Promise<void> => {
    await httpService.put<AccessRule[]>(
        getApiRoute(appUrls.api_put_access_roles_access_rules, { roleId }),
        payload,
    );
};

export const resetActiveRoleState = (): AppThunk => (dispatch): void => {
    dispatch(setAccessRules([]));
    dispatch(setActiveRole(null));
};
