import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const adminPaymentsOrderSliceSelector = (state: StorageState) => state.adminPayment.adminPaymentsOrder;

export const adminPaymentsOrderListSelector = createSelector(
    adminPaymentsOrderSliceSelector,
    payment => payment.list
);

export const adminPaymentsOrderTotalSelector = createSelector(
    adminPaymentsOrderSliceSelector,
    payment => payment.total
);

export const adminPaymentsOrderLoadingSelector = createSelector(
    adminPaymentsOrderSliceSelector,
    payment => payment.isLoading
);

export const adminPaymentsOrderLoadedSelector = createSelector(
    adminPaymentsOrderSliceSelector,
    payment => payment.isLoaded
);

export const adminPaymentOrderLogsExcelLoadingSelector = createSelector(
    adminPaymentsOrderSliceSelector,
    payment => payment.isLoadingExcel
);
