import React from 'react';
import { SnackbarMessageProps } from './model';
import { Alert } from 'antd';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    alert: {
        padding: '16px',
        borderRadius: '16px',
    }
}));


export const View = (props: SnackbarMessageProps) => {
    const { key, message, ref, title, type, isClosed, icon, onClose } = props;
    const classes = useStyles();
    return (
        <div ref={ref} key={key}>
            {
                title ?
                    <Alert
                        className={classes.alert}
                        message={title}
                        onClose={onClose}
                        description={message}
                        type={type}
                        showIcon={icon}
                        closable={isClosed}
                    /> :
                    <Alert
                        className={classes.alert}
                        message={message}
                        type={type}
                        showIcon={icon}
                        closable={isClosed}
                        onClose={onClose}
                    />
            }
        </div>
    );
};
