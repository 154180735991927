import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HistoryEntriesReportState {
    isLoadingPdf: boolean;
}

const initialState: HistoryEntriesReportState = {
    isLoadingPdf: false,
};

export const historyEntriesReportSlice = createSlice({
    name: '@@wh/historyEntriesReport',
    initialState,
    reducers: {
        setLoadingPdf(state, action: PayloadAction<HistoryEntriesReportState['isLoadingPdf']>): void {
            state.isLoadingPdf = action.payload;
        },
    }
});

export const {
    setLoadingPdf,
} = historyEntriesReportSlice.actions;
