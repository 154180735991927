import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Alert } from 'antd';
import { getTechnicalWorkingsStatesByRegions } from '../../../redux/actions/unauthorized/technicalWorking';
import { impersonationFlag } from '../../../redux/selectors/impersonation';
import { useTechnicalWorkingState } from '../../shared/hooks/useTechnicalWorkingState';
import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const GETTING_STATUS_INTERVAL = 5 * 60 * 1000;

export const TechnicalWorkingAlert = () => {
    const dispatch = useDispatch();
    const isImpersonation = useSelector(impersonationFlag);
    const technicalWorkingState = useTechnicalWorkingState();

    useEffect(() => {
        const run = () => {
            dispatch(getTechnicalWorkingsStatesByRegions());
        };

        run();
        const timer = setInterval(run, GETTING_STATUS_INTERVAL);

        return () => {
            clearInterval(timer);
        };
    }, [dispatch]);

    if (!technicalWorkingState?.enabled) {
        return null;
    }

    return (
        <Alert
            message={technicalWorkingState.message ?? 'В настоящее время проводятся технические работы. Возможны перебои в работе личного кабинета'}
            type="error"
            className={classNames(styles['TechnicalWorkingAlert'], isImpersonation && styles['is-impersonation'])}
        />
    );
};
