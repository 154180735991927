import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ConfirmPaymentRequestState {
    isSubmitting: boolean;
}

const initialState: ConfirmPaymentRequestState = {
    isSubmitting: false,
};

export const unauthorizedConfirmPaymentRequestSlice = createSlice({
    name: '@@vp/unauthorizedConfirmPaymentRequest',
    initialState,
    reducers: {
        setConfirmPaymentRequestSubmitting(state, action: PayloadAction<ConfirmPaymentRequestState['isSubmitting']>): void {
            state.isSubmitting = action.payload;
        },
    }
});

export const {
    setConfirmPaymentRequestSubmitting,
} = unauthorizedConfirmPaymentRequestSlice.actions;
