import { ActionType } from '../../actions/model';
import { NotificationMessage } from '../../actions/httpWrapper';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MessagesState = NotificationMessage[];
const initialState: MessagesState = [];

export const messagesSlice = createSlice({
    name: '@@callwrapper',
    initialState,
    reducers: {
        push: (state, action: PayloadAction<NotificationMessage>) => [...state, action.payload],
        clear: () => []
    },
    extraReducers: {
        [ActionType.RESET]: () => initialState
    }
});
