import { httpService, HttpWrapperOptions } from 'app/shared/httpWrapper';
import { getApiRoute } from 'app/shared/pipes';
import { appUrls } from 'common/appUrls';
import { AllowNotificationsUpdater } from 'common/model/account/allowNotificationsUpdater';
import { Regions } from 'common/model/account/regions';
import { RejectPaperPaymentDocumentRequest } from 'common/model/account/rejectPaperPaymentDocument';
import { ClientAccountsRequestOptions } from 'common/model/client/clientAccountRequestOptions';
import { ClientAccountViewModel } from 'common/model/client/clientAccountViewModel';
import { AppThunk } from '../../../reducers';
import { clientAccountsSlice } from '../../../reducers/client/accounts';
import { clientAccountsSliceSelector, clientCurrentRegionSelector } from '../../../selectors';
import { createSnackbar, modalNotificationsPush } from '../../controls';

export const getClientAccounts = (options?: ClientAccountsRequestOptions): AppThunk => async (dispatch, getState) => {
    const { isLoading } = clientAccountsSliceSelector(getState());
    const currentRegion = clientCurrentRegionSelector(getState());

    if (isLoading) {
        return;
    }

    dispatch(clientAccountsSlice.actions.fetchStart());

    try {
        const httpWrapperOptions = new HttpWrapperOptions();
        httpWrapperOptions.queryParams = { ...options, region: currentRegion };

        const result = await httpService.get<ClientAccountViewModel[]>(
            appUrls.api_get_client_client_accounts,
            httpWrapperOptions,
        );
        dispatch(clientAccountsSlice.actions.fetchSuccess({ data: result }));
    } catch (err: any) {
        const errorMessage = 'Произошла ошибка при загрузке аккаунтов профиля. Попробуйте позже';
        dispatch(clientAccountsSlice.actions.fetchFailure(errorMessage));

        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: errorMessage,
            type: 'error',
        }));
    }
};

export const updateAccountAllowNotifications = (accountId: string, updater: AllowNotificationsUpdater): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_post_account_allow_notifications, { accountId });
        await httpService.post<AllowNotificationsUpdater>(route, updater);
        const updatedAccounts = await httpService.get<ClientAccountViewModel[]>(
            appUrls.api_get_client_client_accounts
        );
        dispatch(clientAccountsSlice.actions.fetchSuccess({ data: updatedAccounts }));
    } catch (error) {
        dispatch(modalNotificationsPush({
            type: 'error',
            title: 'Ошибка',
            message: 'При обновлении настроек профиля произошла ошибка. Попробуйте позже'
        }));
    }
};

export const rejectPaperPaymentDocument = (accountId: string,
    region: Regions | undefined,
    request: RejectPaperPaymentDocumentRequest,
): AppThunk => async (dispatch) => {
    try {
        const route = getApiRoute(appUrls.api_post_reject_paper_payment_document, { accountId });
        await httpService.post(route, request);
        const httpWrapperOptions = new HttpWrapperOptions();
        httpWrapperOptions.queryParams = { region };

        const updatedAccounts = await httpService.get<ClientAccountViewModel[]>(
            appUrls.api_get_client_client_accounts,
            httpWrapperOptions,
        );
        dispatch(clientAccountsSlice.actions.fetchSuccess({ data: updatedAccounts }));
        dispatch(createSnackbar({
            type: 'success',
            message: request.status
                ? 'Отказ от доставки платежного документа на бумажном носителе выполнен успешно'
                : 'Возобновление получения платежного документа на бумажном носителе выполнено успешно'
        }));
    } catch (error) {
        dispatch(modalNotificationsPush({
            type: 'error',
            title: 'Ошибка',
            message: (error as any)?.data?.errorDetails ?? 'При выполнении запроса на отказ от доставки платежного документа на бумажном носителе произошла ошибке. Попробуйте позже'
        }));
    }
};
