import { adminReportsPaymentDetailsExcelSlice } from './paymentDetailsExcel';
import { adminReportsPaymentDetailsSlice } from './paymentDetails';
import { adminReportsPaymentsExcelSlice } from './paymentsExcel';
import { adminReportsPaymentsSlice } from './payments';
import { combineReducers } from 'redux';
import { adminClientAccountsRegistrationReportsSlice } from './clientAccountsRegistrationReports';
import { adminClientsReportsSlice } from './clientsReports';
import { adminClientsReportsRegistrationsSlice } from './clientsReportsRegistrations';
import { adminMeterReadingOrderReportsSlice } from './meterReadingOrderReports';
import { adminMeterReadingsReportSlice } from './meterReadingsReport';

export const adminReports = combineReducers({
    adminClientsReports: adminClientsReportsSlice.reducer,
    adminClientAccountsRegistrationReports: adminClientAccountsRegistrationReportsSlice.reducer,
    adminClientsReportsRegistrations: adminClientsReportsRegistrationsSlice.reducer,
    adminMeterReadingReports: adminMeterReadingOrderReportsSlice.reducer,
    payments: adminReportsPaymentsSlice.reducer,
    paymentsExcel: adminReportsPaymentsExcelSlice.reducer,
    paymentDetails: adminReportsPaymentDetailsSlice.reducer,
    paymentDetailsExcel: adminReportsPaymentDetailsExcelSlice.reducer,
    adminMeterReadingsReport: adminMeterReadingsReportSlice.reducer,
});
