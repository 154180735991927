import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { MeterReadingReport } from '../../../../common/model/meterReadings/adminMeterReadingReport';

export interface AdminMeterReadingsReportState {
    list: MeterReadingReport | null;
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingExcel: boolean;
}

const initialState: AdminMeterReadingsReportState = {
    list: null,
    isLoading: false,
    isLoaded: false,
    isLoadingExcel: false,
};

export const adminMeterReadingsReportSlice = createSlice({
    name: '@@vp/meterReadings/reports/list',
    initialState,
    reducers: {
        setMeterReadingsReportList(state, action: PayloadAction<MeterReadingReport>): void {
            state.list = action.payload;
            state.isLoaded = true;
        },
        setLoadingMeterReadingsReport(state, action: PayloadAction<AdminMeterReadingsReportState['isLoading']>): void {
            state.isLoading = action.payload;
        },
        setLoadingMeterReadingsReportExcel(state, action: PayloadAction<AdminMeterReadingsReportState['isLoadingExcel']>): void {
            state.isLoadingExcel = action.payload;
        },
        clearMeterReadingsReport(state): void {
            state.list = null;
            state.isLoading = false;
            state.isLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminMeterReadingsReportState => initialState,
    }
});

export const {
    setMeterReadingsReportList,
    setLoadingMeterReadingsReport,
    setLoadingMeterReadingsReportExcel,
    clearMeterReadingsReport
} = adminMeterReadingsReportSlice.actions;
