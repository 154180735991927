import React from 'react';
import { modalNotificationsPop } from '../../../../redux/actions/controls/modalNotifications';
import { controlsModalNotificationsSelector } from '../../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../modal/infoModal';
import { ModalNotification } from '../../../../redux/reducers/controls/modalNotifications';

export const ModalNotifier: React.FC = () => {
    const dispatch = useDispatch();
    const [isModalOpened, setModalOpened] = React.useState(false);
    const { notifications } = useSelector(controlsModalNotificationsSelector());

    React.useEffect(() => {
        if (!notifications.length || isModalOpened) {
            return;
        }

        setModalOpened(true);

        const {
            title,
            message: infoMessage,
            type,
            onOk,
            onCancel,
            okButtonTitle: okText,
            cancelButtonTitle: cancelText,
        } = notifications.slice().pop() as ModalNotification;

        dispatch(modalNotificationsPop());

        showModal({
            title,
            infoMessage,
            type,
            onOk,
            okText,
            onCancel,
            cancelText,
            afterClose: () => {
                setModalOpened(false);
            }
        });
    }, [dispatch, isModalOpened, notifications]);

    return null;
};
