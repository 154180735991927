import React from 'react';
import { DemoAlertViewProps } from './model';
import './styles.scss';

export const View = ({ isDemoMode }: DemoAlertViewProps) => {
    if (!isDemoMode) {
        return null;
    }

    return <div className="demoAlert">Вы находитесь в демо-режиме личного кабинета</div>;
};
