import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../actions/model';

export interface Impersonation {
    isImpersonation: boolean;
}

const initialState: Impersonation = {
    isImpersonation: false
};

export const impersonationSlice = createSlice({
    name: '@@vp/impersonation',
    initialState,
    reducers: {
        setIsImpersonation(state, action: PayloadAction<Impersonation['isImpersonation']>): void {
            state.isImpersonation = action.payload;
        }
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): Impersonation => initialState,
    }
});

export const {
    setIsImpersonation
} = impersonationSlice.actions;
