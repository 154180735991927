import { AppThunk } from '../../../reducers';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { setIsImpersonation } from '../../../reducers/impersonation';
import { getApiRoute } from '../../../../app/shared/pipes';
import { push } from 'connected-react-router';
import { Routes } from '../../../../app/shared/constants';
import { ActionType } from '../../model';
import { getCurrentUser } from '../../users';

export const getImpersonation = (): AppThunk => async (dispatch) => {
    const result = await httpService.get<boolean>(
        appUrls.api_get_impersonation
    );
    dispatch(setIsImpersonation(result));
};

export const logoutImpersonation = (): AppThunk => async (dispatch) => {
    const route = getApiRoute(appUrls.api_client_logout);
    await httpService.post(route);
    dispatch(getCurrentUser());
    dispatch(push(Routes.adminClients));
    dispatch({ type: ActionType.LOGOUT });
};
