export enum AutoPaymentDebtType {
    Main = 'Основной',
    Other = 'Прочий',
    TKO = 'ТКО',
}

export enum DebtTypeFromBilling {
    Main = 'Основной',
    Penalties = 'Пени',
    Other = 'Прочий',
}

export const AutoPaymentDebtTypeToBilling: Record<AutoPaymentDebtType, DebtTypeFromBilling> = {
    [AutoPaymentDebtType.Main]: DebtTypeFromBilling.Main,
    [AutoPaymentDebtType.Other]: DebtTypeFromBilling.Other,
    [AutoPaymentDebtType.TKO]: DebtTypeFromBilling.Main,
};