import React from 'react';
import _ from 'lodash';
import { MeterEntriesValidationError, MeterEntriesValidationErrorType } from 'common/model/meterReadings/meterEntriesValidateError';
import { HttpStatusCode } from 'common/httpStatusCode';
import { objectEntries } from 'common/shared/utils/object';
import { ModalNotification } from 'redux/reducers/controls/modalNotifications';
import { ValidationErrorMessage, ValidationErrorMessages } from './components/ValidationErrorMessage';

const meterEntriesValidationErrorTypeToModalTitle: Record<MeterEntriesValidationErrorType, string> = {
    [MeterEntriesValidationErrorType.Excessive]: 'Показания к расчету не приняты.',
    [MeterEntriesValidationErrorType.Insufficient]: 'Проверьте корректность введенных показаний.',
    [MeterEntriesValidationErrorType.Format]: 'Проверьте корректность введенных показаний.',
    [MeterEntriesValidationErrorType.Period]: 'Показания к расчету не приняты.',
    [MeterEntriesValidationErrorType.DailyLimit]: 'Показания к расчету не приняты.',
    [MeterEntriesValidationErrorType.MonthLimit]: 'Показания к расчету не приняты.',
    [MeterEntriesValidationErrorType.UpdateLimit]: 'Показания к расчету не приняты.'
};

export const buildModalNotificationFromMeterEntriesValidationError = (error: any): ModalNotification => {
    // error это request
    if (error.status === HttpStatusCode.badRequest) {
        if (Array.isArray(error.data?.errorData) && error.data.errorData.length) {
            const rawErrors = error.data.errorData as MeterEntriesValidationError[];

            const periodError = rawErrors.find((err) => err.type === MeterEntriesValidationErrorType.Period);
            if (periodError) {
                return {
                    title: 'Показания к расчету не приняты.',
                    message: React.createElement(ValidationErrorMessage, periodError),
                    type: 'error'
                };
            }

            const title = meterEntriesValidationErrorTypeToModalTitle[rawErrors[0]?.type ?? ''];
            const message = React.createElement(ValidationErrorMessages, {
                groupedErrors: objectEntries<MeterEntriesValidationError[], string>(_.groupBy(rawErrors, 'serviceName'))
            });

            return { title, message, type: 'error' };
        } else {
            return {
                title: 'Показания не приняты.',
                message: 'Ошибка при отправке показаний. Позвоните по тел. 8 (800) 250-60-06.',
                type: 'error',
            };
        }
    } else {
        return {
            title: 'Внутренняя ошибка',
            message: error?.data?.errorDetails ?? 'Попробуйте позже',
            type: 'error',
        };
    }
};

const waterServicesCodes = ['ГВС', 'ХВС', '1', '2', '61'];

export const isWaterService = (serviceCode: string | undefined): boolean => {
    if (!serviceCode) {
        return false;
    }
    return waterServicesCodes.includes(serviceCode);
};
