
export interface LoaderProps {
    startShowLoaderDelayMs?: number;
    size?: LoaderSize;
    className?: string;
}

export enum LoaderSize {
    Small = 80,
    Default = 120,
}
