export const withCapitalLetter = (src: string): string => {
    if (!src?.length) {
        return src;
    }
    return `${src.slice(0, 1).toLocaleUpperCase()}${src.slice(1).toLocaleLowerCase()}`;
};

export const removeSpacesFromString = (str: string): string => {
    return String(str).replace(/\s/g, '');
};

export const isUUID = (value: string) => {
    return /[^-a-zA-Z0-9]+/.test(value) === false;
};

export const primitiveStripHtmlTags = (str: string): string => {
    return String(str).replace(/<[^>]*>?/gm, '');
};
