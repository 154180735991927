import { View } from './view';
import { OwnProps } from './model';
import React, { Ref } from 'react';
import { closeSnackbar } from '../../../../redux/actions';
import { useDispatch } from 'react-redux';

export const SnackbarMessage = React.forwardRef((props: OwnProps, ref: Ref<any>) => {
    const dispatch = useDispatch();
    const snackbarClose = (args: string) => dispatch(closeSnackbar(args));

    const { message, title, icon, type, isClosed, key } = JSON.parse(props.message);
    return View({
        message,
        key,
        icon,
        type,
        title,
        isClosed,
        ref,
        onClose: () => {
            snackbarClose(key);
        },
    });
});
