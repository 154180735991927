import React from 'react';
import { ModalProps } from 'antd/lib/modal/Modal';
import { Modal } from 'antd';
import { Button } from '../../button';

export const View: React.FC<ModalProps> = ({ footer, centered = true, ...props }) => {
    return (
        <Modal
            {...props}
            centered={centered}
            footer={footer ?? [
                <Button
                    key={1}
                    value={props.cancelText?.toString() ?? 'Отмена'}
                    shape="round"
                    variant="secondary"
                    onClick={props.onCancel}
                />,
                <Button
                    key={2}
                    value={props.okText?.toString() ?? 'Ок'}
                    shape="round"
                    onClick={props.onOk}
                />,
            ]}
        />
    );
};
