export const downloadFile = ({
    resource,
    filename,
    type = 'text/plain',
    openInNewTab = false,
}: {
    resource: Blob | string;
    filename: string;
    type?: string;
    openInNewTab?: boolean;
  }) => {
    const file = typeof resource === 'string' ? new Blob([resource], { type }) : resource;
    // IE10+
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
        // Others
        const a = document.createElement('a'),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
        if (openInNewTab) {
            window.open(url);
        }
    }
};
