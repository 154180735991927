import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentQRCViewModel } from 'common/model/payment/paymentQRCViewModel';

export interface PaymentQrCallbackState {
  paymentQrCallbackLoading: boolean;
  paymentQrCallbackLoadingError: string | null;
  paymentQrCallbackCompleteLoading: boolean;
  paymentQrCallbackCompleteLoadingError: string | null;
  paymentQrCallbackResponse: PaymentQRCViewModel | null;
}

const initialState: PaymentQrCallbackState = {
    paymentQrCallbackLoading: false,
    paymentQrCallbackLoadingError: null,
    paymentQrCallbackCompleteLoading: false,
    paymentQrCallbackCompleteLoadingError: null,
    paymentQrCallbackResponse: null,
};

export const paymentQrCallbackSlice = createSlice({
    name: '@@vp/payment/createQr',
    initialState,
    reducers: {
        resetPayment: () => initialState,
        setPaymtneQrCallbackLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentQrCallbackLoading = action.payload;
        },
        setPaymentQrCallbackLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentQrCallbackLoadingError = action.payload;
        },
        setPaymentQrCallbackCompleteLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentQrCallbackCompleteLoading = action.payload;
        },
        setPaymentQrCallbackCompleteLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentQrCallbackCompleteLoadingError = action.payload;
        },
        setPaymentQrCallbackResponse: (state, action: PayloadAction<PaymentQRCViewModel | null>) => {
            state.paymentQrCallbackResponse = action.payload;
        },
    }
});

export const {
    resetPayment,
    setPaymtneQrCallbackLoading,
    setPaymentQrCallbackLoadingError,
    setPaymentQrCallbackCompleteLoading,
    setPaymentQrCallbackCompleteLoadingError,
    setPaymentQrCallbackResponse,
} = paymentQrCallbackSlice.actions;
