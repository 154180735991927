import { getApiRoute, RequestParams } from '../../../app/shared/pipes';
import { JournalMeterBody, MeterIsupExcelBody } from '../../../common/model/isup/journalMeterBody';
import { AppThunk } from '../../reducers';
import { appUrls } from '../../../common/appUrls';
import { httpService } from '../../../app/shared/httpWrapper';
import { ExtendedActiveEnergyReadingData, MeterViewModel } from '../../../common/model/isup/dailyIncrementalResponse';
import { createSnackbar, modalNotificationsPush } from '../controls';
import { ExtendedIncrementalEnergyReadingData, MeterOrder } from '../../../../../common/model/isup/dailyIncrementalResponse';
import {
    setMeterActiveEnergyReadingData,
    setMeterActiveEnergyReadingDataLoading,
    setMeterIncrementalEnergyReadingData,
    setMeterIsupInfo,
    clearMeterDailyEnergyReadingData,
    setMeterIncrementalEnergyReadingDataLoading,
    setMeterJournalExcelLoading,
    setMeterReadingDataExcelLoading,
    setMeterIsupInfoLoading,
    setSendMeterCurrentReadingsOrderLoading,
    setIsupMeterEnergyReadingData,
    setIsupMeterEnergyReadingLoading
} from '../../reducers/isup';
import {
    MeterCurrentReadingOrderStatusError,
    meterOrdersErrorStatusTypesDictionary
} from '../../../common/model/isup/meterCurrentReadingOrders';
import { JournalMeterResponse } from '../../../../../common/model/isup/journalMeterResponse';
import { downloadExcelData } from '../../../app/shared/utils/excel';
import { HttpStatusCode } from '../../../app/shared/constants';

const digitsAfterDot = 2;
const formatCumulativeEnergyDataFromIsup = (data: ExtendedActiveEnergyReadingData) => {
    data.cumulativeActiveEnergyExp = +data.cumulativeActiveEnergyExp.toFixed(digitsAfterDot);
    data.cumulativeActiveEnergyImp = +data.cumulativeActiveEnergyImp.toFixed(digitsAfterDot);
    data.cumulativeReactiveEnergyExp = +data.cumulativeReactiveEnergyExp.toFixed(digitsAfterDot);
    data.cumulativeReactiveEnergyImp = +data.cumulativeReactiveEnergyImp.toFixed(digitsAfterDot);
    data.cumulativeActiveEnergyTZ1 = +data.cumulativeActiveEnergyTZ1.toFixed(digitsAfterDot);
    data.cumulativeActiveEnergyTZ2 = +data.cumulativeActiveEnergyTZ2.toFixed(digitsAfterDot);
    data.cumulativeActiveEnergyTZ3 = +data.cumulativeActiveEnergyTZ3.toFixed(digitsAfterDot);
    return data;
};

const formatIncrementalEnergyDataFromIsup = (data: ExtendedIncrementalEnergyReadingData) => {
    data.activeEnergyExp = +data.activeEnergyExp.toFixed(digitsAfterDot);
    data.activeEnergyImp = +data.activeEnergyImp.toFixed(digitsAfterDot);
    data.reactiveEnergyExp = +data.reactiveEnergyExp.toFixed(digitsAfterDot);
    data.reactiveEnergyImp = +data.reactiveEnergyImp.toFixed(digitsAfterDot);
    return data;
};

export const getMeterDailyEnergyReadingData = (options: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setMeterActiveEnergyReadingDataLoading(true));
    try {
        const route = getApiRoute(appUrls.api_get_meter_cumulative_energy_reading_data, {}, { ...options });
        const data = await httpService.get<ExtendedActiveEnergyReadingData[]>(route);
        const formattedData = data.map(item => formatCumulativeEnergyDataFromIsup(item));
        dispatch(setMeterActiveEnergyReadingData(formattedData));
    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке списка статистики. Попробуйте позже',
            type: 'error',
        }));
    }
    dispatch(setMeterActiveEnergyReadingDataLoading(false));
};

export const getMeterIncrementalEnergyReadingData = (options: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setMeterIncrementalEnergyReadingDataLoading(true));
    dispatch(setMeterIncrementalEnergyReadingData([]));
    try {
        const route = getApiRoute(appUrls.api_get_meter_incremental_energy_reading_data, {}, { ...options });
        const data = await httpService.get<ExtendedIncrementalEnergyReadingData[]>(route);
        const formattedData = data.map(item => formatIncrementalEnergyDataFromIsup(item));
        dispatch(setMeterIncrementalEnergyReadingData(formattedData));
    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке списка статистики. Попробуйте позже',
            type: 'error',
        }));
    }
    dispatch(setMeterIncrementalEnergyReadingDataLoading(false));
};

export const getIsupMeterInfo = (options: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setMeterIsupInfoLoading(true));
    try {
        const route = getApiRoute(appUrls.api_get_meter, {}, { ...options });
        const data = await httpService.get<MeterViewModel>(route);
        if (data) {
            dispatch(setMeterIsupInfo(data));
        }
    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке списка статистики. Попробуйте позже',
            type: 'error',
        }));
    } finally {
        dispatch(setMeterIsupInfoLoading(false));
    }
};

export const getIsupMeterEnergyReadingData = (options: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setIsupMeterEnergyReadingLoading(true));
    try {
        const route = getApiRoute(appUrls.api_get_meter_cumulative_energy_reading_data, {}, { ...options });
        const data = await httpService.get<ExtendedActiveEnergyReadingData[]>(route);
        const formattedData = data?.length ? formatCumulativeEnergyDataFromIsup(data[0]) : undefined;
        dispatch(setIsupMeterEnergyReadingData(formattedData));
        dispatch(createSnackbar({
            type: 'success',
            message: 'Показания обновлены',
        }));

    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке статистики. Попробуйте позже',
            type: 'error',
        }));
    }
    dispatch(setIsupMeterEnergyReadingLoading(false));
};

export const currentReadingOrders = (foreignId: string, isDemoMode: boolean): AppThunk => async (dispatch) => {
    if (isDemoMode) {
        dispatch(modalNotificationsPush({
            title: 'Заявка',
            message: 'На прибор учета отправлена заявка на снятие показаний. Показания будут получены через несколько минут. Чтобы их увидеть, нажмите кнопку обновить в блоке "Показания ПУ"',
            type: 'success',
        }));

        return;
    }

    try {
        dispatch(setSendMeterCurrentReadingsOrderLoading(true));
        const route = getApiRoute(appUrls.api_post_meter_current_reading_order, {}, { foreignId });
        const data = await httpService.post<MeterOrder>(route);

        if (meterOrdersErrorStatusTypesDictionary[data.status as MeterCurrentReadingOrderStatusError]) {
            dispatch(modalNotificationsPush({
                title: 'Статус ответа',
                message: meterOrdersErrorStatusTypesDictionary[data.status as MeterCurrentReadingOrderStatusError],
                type: 'info',
            }));
        } else {
            dispatch(modalNotificationsPush({
                title: 'Заявка',
                message: 'На прибор учета отправлена заявка на снятие показаний. Показания будут получены через несколько минут. Чтобы их увидеть, нажмите кнопку обновить в блоке "Показания ПУ"',
                type: 'success',
            }));
        }
    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке обработке заявки. Попробуйте позже',
            type: 'error',
        }));
    } finally {
        dispatch(setSendMeterCurrentReadingsOrderLoading(false));
    }
};

export const resetMeterDailyEnergyReadingData = (): AppThunk => (dispatch) => {
    dispatch(clearMeterDailyEnergyReadingData());
};


export const getIsupJournalMeter = (options: Partial<JournalMeterBody>): AppThunk => async (dispatch) => {
    dispatch(setMeterJournalExcelLoading(true));
    try {
        const route = getApiRoute(appUrls.api_post_meter_journal);
        const { data } = await httpService.post<JournalMeterResponse>(route, options);
        downloadExcelData(data, 'Журнал событий ПУ');
    } catch (err: any) {
        if (err.status === HttpStatusCode.notFound) {
            dispatch(modalNotificationsPush({
                title: 'Журнал',
                message: err?.data?.errorDetails ?? 'Данных в журналах нет',
                type: 'success',
            }));
        } else {
            dispatch(modalNotificationsPush({
                title: 'Ошибка',
                message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке списка статистики. Попробуйте позже',
                type: 'error',
            }));
        }
    } finally {
        dispatch(setMeterJournalExcelLoading(false));
    }
};

export const getIsupExcel = (options: Partial<MeterIsupExcelBody>): AppThunk => async (dispatch) => {
    dispatch(setMeterReadingDataExcelLoading(true));
    try {
        const route = getApiRoute(appUrls.api_post_meter_isup_excel);
        const { data } = await httpService.post<JournalMeterResponse>(route, options);
        downloadExcelData(data, 'Список показаний');
    } catch (err: any) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: err?.data?.errorDetails ?? 'Произошла ошибка при загрузке файла. Попробуйте позже',
            type: 'error',
        }));
    } finally {
        dispatch(setMeterReadingDataExcelLoading(false));
    }
};
