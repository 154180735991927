import { StorageState } from '../../../reducers';

export const clientRegionsSliceSelector = (state: StorageState) => state.client.regions.regions;

export const clientCurrentRegionSelector = (state: StorageState) => state.client.regions.currentRegion;

export const clientCurrentRegionHasAtolSelector = (state: StorageState) => state.client.regions.hasAtolGatewayGroupCode;

export const clientCurrentRegionHasSberPaySettingsSelector = (state: StorageState) => state.client.regions.hasSberPaySettings;

export const paymentAvailableForCurrentRegionSelector = (state: StorageState) => state.client.regions.paymentAvailableForCurrentRegion;
