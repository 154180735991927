import { httpService } from '../../../../app/shared/httpWrapper';
import { getApiRoute, RequestParams } from '../../../../app/shared/pipes/routing';
import { downloadExcelData } from '../../../../app/shared/utils/excel';
import { appUrls } from '../../../../common/appUrls';
import { PaymentOrderResponse } from '../../../../common/model/payment/paymentOrderResponse';
import { PaymentOrderLogsExcelResponse } from '../../../../common/model/payment/paymentOrderLogsExcelResponse';
import { setLoading, setPaymentsOrderList, clearPaymentsOrder, setLoadingPaymentsOrderLogsExcel } from '../../../reducers/adminPayment/paymentsOrder';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';


export const getAdminPaymentsOrder = (options?: RequestParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_admin_payments_order, { }, { ...options });

    try {
        const data = await httpService.get<PaymentOrderResponse>(route);
        dispatch(setPaymentsOrderList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка платежей. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};


export const resetAdminPaymentsOrderData = (): AppThunk => (dispatch) => {
    dispatch(clearPaymentsOrder());
};

export const downloadAdminPaymentOrderLogs = (options?: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setLoadingPaymentsOrderLogsExcel(true));
    try {
        const route = getApiRoute(appUrls.api_download_payment_order_logs_excel, { }, { ...options });
        const { data } = await httpService.post<PaymentOrderLogsExcelResponse>(route);
        downloadExcelData(data, 'payment-order-log');
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при экспорте списка платежей из личного кабинета. Попробуйте еще раз',
            type: 'error',
        }));
    }
    dispatch(setLoadingPaymentsOrderLogsExcel(false));
};
