import { appUrls } from 'common/appUrls';
import { PaymentHistoryResponse } from 'common/model/payment/paymentHistoryResponse';
import { AppThunk } from 'redux/reducers';
import { setLoading, setPaymentsHistoryList, clearPaymentsHistory } from 'redux/reducers/payment/paymentsHistory';
import { httpService } from 'app/shared/httpWrapper';
import { getApiRoute, RequestParams } from 'app/shared/pipes';
import { modalNotificationsPush } from '../..';


export const getPaymentsHistory = (options?: RequestParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_payments_history, { }, { ...options });

    try {
        const data = await httpService.get<PaymentHistoryResponse>(route);
        dispatch(setPaymentsHistoryList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка платежей. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};


export const resetPaymentsHistoryData = (): AppThunk => (dispatch) => {
    dispatch(clearPaymentsHistory());
};
