import { View } from './view';
import { useDispatch, useSelector } from 'react-redux';
import { impersonationFlag } from '../../../redux/selectors/impersonation';
import { clientProfileDataSliceSelector } from '../../../redux/selectors';
import { logoutImpersonation } from '../../../redux/actions/client/impersonation';

export const ImpersonationAlert = () => {
    const dispatch = useDispatch();
    const isImpersonation = useSelector(impersonationFlag);
    const clientProfile = useSelector(clientProfileDataSliceSelector);

    const onReturnAdminPanel = () => {
        dispatch(logoutImpersonation());
    };

    return View({
        isImpersonation,
        clientProfile,
        onReturnAdminPanel
    });
};
