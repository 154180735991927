import { AppThunk } from '../../../reducers';
import { clientRegionsSlice } from '../../../reducers/client/regions';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { getApiRoute } from '../../../../app/shared/pipes';

export const getClientRegions = (accountNumber: string|null): AppThunk => async (dispatch, getState) => {
    try {
        const url = `${appUrls.api_get_client_client_account_regions}${accountNumber ? '?accountNumber=' + accountNumber : ''}`;
        const result = await httpService.get<string[]>(url);

        if (Array.isArray(result) && result.length) {
            const currentRegion = getState().client.regions.currentRegion;
            if (!currentRegion || !result.includes(currentRegion)) {
                dispatch(clientRegionsSlice.actions.setCurrentClientRegion(result[0]));
            }
        }

        dispatch(clientRegionsSlice.actions.setClientRegions(result || []));
    } catch (err: any) {
        dispatch(clientRegionsSlice.actions.setClientRegions(null));
        dispatch(clientRegionsSlice.actions.setCurrentClientRegion(null));
    }
};

export const figureOutRegionHasAtol = (): AppThunk => async (dispatch, getState) => {
    try {
        const region = getState().client.regions.currentRegion;
        if (!region) {
            return;
        }

        await httpService.get(getApiRoute(appUrls.api_get_figure_out_region_has_atol, {}, { region }));

        dispatch(clientRegionsSlice.actions.setHasAtolGatewayGroupCode(true));
    } catch (err: any) {
        dispatch(clientRegionsSlice.actions.setHasAtolGatewayGroupCode(false));
    }
};

export const figureOutRegionHasSberPaySettings = (): AppThunk => async (dispatch, getState) => {
    try {
        const region = getState().client.regions.currentRegion;
        if (!region) {
            return;
        }

        await httpService.get(getApiRoute(appUrls.api_get_figure_out_region_has_sberpay_settings, {}, { region }));

        dispatch(clientRegionsSlice.actions.setHasSberPaySettings(true));
    } catch (err: any) {
        dispatch(clientRegionsSlice.actions.setHasSberPaySettings(false));
    }
};
