import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';
import { AccountDetailsViewModel } from '../../../../common/model/account/accountDetailsViewModel';

export interface UnauthorizedAccountMetersState {
    unauthorizedAccountMeters: AccountDetailsViewModel;
    isLoading: boolean;
    isLoaded: boolean;
}

const initialState: UnauthorizedAccountMetersState = {
    unauthorizedAccountMeters: {
        services: []
    },
    isLoading: false,
    isLoaded: false
};

export const unauthorizedAccountMetersSlice = createSlice({
    name: '@@vp/unauthorizedAccountMeters',
    initialState,
    reducers: {
        setUnauthorizedAccountMeters(state, action: PayloadAction<AccountDetailsViewModel>): void {
            state.unauthorizedAccountMeters = action.payload;
            state.isLoaded = true;
        },
        setLoadingUnauthorizedAccountMeters(state, action: PayloadAction<UnauthorizedAccountMetersState['isLoading']>): void {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): UnauthorizedAccountMetersState => initialState,
    }
});

export const {
    setUnauthorizedAccountMeters,
    setLoadingUnauthorizedAccountMeters,
} = unauthorizedAccountMetersSlice.actions;
