
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateSberPayResponseViewModel } from 'common/model/payment/order/createSberPayResponseViewModel';

export interface PaymentSberPayState {
  paymentSberPayCreateLoading: boolean;
  paymentSberPayCreateLoadingError: string | null;
  paymentSberPayCompleteLoading: boolean;
  paymentSberPayCompleteLoadingError: string | null;
  paymentSberPayResponse: CreateSberPayResponseViewModel | null;
}

const initialState: PaymentSberPayState = {
    paymentSberPayCreateLoading: false,
    paymentSberPayCreateLoadingError: null,
    paymentSberPayCompleteLoading: false,
    paymentSberPayCompleteLoadingError: null,
    paymentSberPayResponse: null,
};

export const paymentSberPayCreateSlice = createSlice({
    name: '@@vp/payment/createSberPay',
    initialState,
    reducers: {
        resetPayment: () => initialState,
        setPaymtneSberPayCreateLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentSberPayCreateLoading = action.payload;
        },
        setPaymentSberPayCreateLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentSberPayCreateLoadingError = action.payload;
        },
        setPaymentSberPayCompleteLoading: (state, action: PayloadAction<boolean>) => {
            state.paymentSberPayCompleteLoading = action.payload;
        },
        setPaymentSberPayCompleteLoadingError: (state, action: PayloadAction<string | null>) => {
            state.paymentSberPayCompleteLoadingError = action.payload;
        },
        setPaymentSberPayResponse: (state, action: PayloadAction<CreateSberPayResponseViewModel | null>) => {
            state.paymentSberPayResponse = action.payload;
        },
    }
});

export const {
    resetPayment,
    setPaymtneSberPayCreateLoading,
    setPaymentSberPayCreateLoadingError,
    setPaymentSberPayCompleteLoading,
    setPaymentSberPayCompleteLoadingError,
    setPaymentSberPayResponse,
} = paymentSberPayCreateSlice.actions;
