export enum Routes {
    empty = '/',
    notFound = '/page-not-found',
    adminNotFound = '/admin/page-not-found',
    login = '/login',
    registration = '/registration',
    registrationConfirm = '/registration-confirm',
    resetPasswordRequest = '/reset-password-request',
    resetPasswordConfirmByPhone = '/reset-password-confirm-phone',
    resetPasswordConfirmByEmail = '/reset-password-confirm-email',
    resetPasswordResendEmail = '/reset-password-resend-email',
    resetPassword = '/reset-password',
    addAccountConfirm = '/add-account-confirm',
    deleteAccountConfirm = '/delete-account-confirm',
    documents = '/technical-documents',
    wallet = '/wallet/:orderId?/:status?',
    walletPage = '/wallet',
    spb = '/spb',
    walletDetailsPage = '/wallet-details',
    paymentQuittance = '/payment-quittance',
    dashboard = '/dashboard',
    statistic = '/statistic',
    tickets = '/tickets',
    newTicket = '/new-ticket',
    paymentsStatistic = '/statistic/payments',
    chargesStatistic = '/statistic/charges',
    entriesStatistic = '/statistic/entries',
    entriesStatisticMeters = '/statistic/entries/meters',
    entriesStatisticMeter = '/statistic/entries/:meterId?',
    appeals = '/appeals',
    instructions = '/instructions',
    support = '/support',
    settings = '/settings',
    notifications = '/notifications',
    profile = '/profile',
    passwordChanging = '/profile/password-changing',
    accountDelete = '/profile/account-delete',
    accountAllowPaperDocument = '/profile/allow-paper-document',
    accountPage = '/account/:accountId?',
    confirmAddressChange = '/client/confirm-change-address',
    confirmPhoneChange = '/client/confirm-phone-change',
    confirmRegistrationEmail = '/client/confirm-registration',
    meters = '/meters',
    claims = '/claims',
    adminLogin = '/admin/login',
    adminAuthError = '/admin/auth-error',
    admin = '/admin',
    adminPayments = '/admin/payments',
    adminPaymentQuittance = '/admin/payment-quittance',
    adminMetrics = '/admin/metrics',
    adminUsers = '/admin/users',
    adminClients = '/admin/clients',
    adminSettings = '/admin/settings',
    adminServices = '/admin/services',
    adminReports = '/admin/reports',
    adminReportsClients = '/admin/reports/clients',
    adminReportsClientsRegistrations = '/admin/reports/clients-registrations',
    adminReportsAccountsRegistration = '/admin/reports/accounts-registration',
    adminReportsPayments = '/admin/reports/payments',
    adminReportsPaymentsDetails = '/admin/reports/payments-details',
    adminReportsMetersIsup = '/admin/reports/meters-isup',
    adminReportsMeterReadings = '/admin/reports/meter-readings',
    adminNotifications = '/admin/notifications',
    selectAccount = '/select-account',
    meterIsupInfo = '/meters/meter-isup-info',
    socialConfirm = '/social-confirm/:socialNetwork',
    socialConfirmLogin = '/social-confirm-login/:socialNetwork',
    unauthorizedAccountMeters = '/unauthorized/account-meters',
    unauthorizedPaymentPage = '/unauthorized/payment',
    unauthorizedPayment = '/unauthorized/payment/:accountNumber?/:orderId?/:status?',
    unauthorizedPaymentDetailsPage = '/unauthorized/details',
    unauthorizedConfirmPhonePayment = '/unauthorized/confirm-phone-payment',
}
