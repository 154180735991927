import { httpService } from '../../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../../common/appUrls';
import { RoleViewModel } from '../../../../../common/model/users/role/roleViewModel';
import { AppThunk } from '../../../../reducers';
import { setRolesList, setLoading } from '../../../../reducers/users/roles/list';
import { modalNotificationsPush } from '../../../';

export const setAccessRolesList = (roles: RoleViewModel[]): AppThunk => (dispatch): void => {
    dispatch(setRolesList(roles));
};

export const getAccessRolesList = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));

    try {
        const roles = await httpService.get<RoleViewModel[]>(appUrls.api_get_access_roles);
        dispatch(setAccessRolesList(roles));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при загрузке списка ролей. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));

};
