import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const adminPaymentDetailsSliceSelector = (state: StorageState) => state.adminPayment.adminPaymentDetails;

export const adminPaymentDetailsListSelector = createSelector(
    adminPaymentDetailsSliceSelector,
    payment => payment.list
);

export const adminPaymentDetailsLoadingSelector = createSelector(
    adminPaymentDetailsSliceSelector,
    payment => payment.isLoading
);

export const adminPaymentDetailsLoadedSelector = createSelector(
    adminPaymentDetailsSliceSelector,
    payment => payment.isLoaded
);
