export enum Regions {
    TjumenskajaOblast = 'Тюменская область',
    KurganskajaOblast = 'Курганская область',
    OrenburgskajaOblast = 'Оренбургская область',
    SverdlovskajaOblast = 'Свердловская область',
    Surgut = 'Сургут',
    JaNAO = 'ЯНАО',
}

export const activeRegionsList: Regions[] = [
    Regions.TjumenskajaOblast,
    Regions.KurganskajaOblast,
    Regions.OrenburgskajaOblast,
    Regions.SverdlovskajaOblast,
    Regions.Surgut,
    Regions.JaNAO,
];

export const regionsBillingDictionary: Record<Regions, string> = {
    [Regions.TjumenskajaOblast]: 'TYUMEN',
    [Regions.OrenburgskajaOblast]: 'ORENBURG',
    [Regions.Surgut]: 'SURGUT',
    [Regions.JaNAO]: 'JANAO',
    [Regions.KurganskajaOblast]: 'KRUGAN',
    [Regions.SverdlovskajaOblast]: 'EKATERINBURG',
}

// date-fns-tz
export const regionTimezones: Record<Regions, string> = {
    [Regions.TjumenskajaOblast]: 'Asia/Yekaterinburg',
    [Regions.OrenburgskajaOblast]: 'Asia/Yekaterinburg',
    [Regions.Surgut]: 'Asia/Yekaterinburg',
    [Regions.JaNAO]: 'Asia/Yekaterinburg',
    [Regions.KurganskajaOblast]: 'Asia/Yekaterinburg',
    [Regions.SverdlovskajaOblast]: 'Asia/Yekaterinburg',
}
