import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { ButtonOwnProps } from './model';

import './styles.scss';

export const View: React.FC<ButtonOwnProps> = ({
    className,
    variant = 'primary',
    icon,
    value,
    ...props
}) => {
    return (
        <Button
            shape="round"
            className={classNames(variant, { 'has-icon': Boolean(icon) }, className)}
            icon={icon}
            {...props}
        >
            {value}
        </Button>
    );
};
