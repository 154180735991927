import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../reducers';

export const notificationsSliceSelector = (state: StorageState) => state.notifications;

export const notificationsListSelector = createSelector(
    notificationsSliceSelector,
    notifications => notifications.list
);

export const notificationsLoadingSelector = createSelector(
    notificationsSliceSelector,
    notifications => notifications.isLoading
);

export const notificationsLoadedSelector = createSelector(
    notificationsSliceSelector,
    notifications => notifications.isLoaded
);

export const notificationsNotReadingCountSelector = createSelector(
    notificationsListSelector,
    notifications => {
        const count = notifications?.filter(({ viewed }) => !viewed)?.length;
        if (count && count > 0) {
            return count;
        }
        return null;
    }
);
