import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentQuittanceState {
  text: string | null;
}

const initialState: PaymentQuittanceState = {
    text: null,
};

export const paymentQuittanceSlice = createSlice({
    name: '@@vp/payment/quittance',
    initialState,
    reducers: {
        setPaymentQuittanceText(state, action: PayloadAction<PaymentQuittanceState['text']>): void {
            state.text = action.payload;
        },
    }
});

export const {
    setPaymentQuittanceText,
} = paymentQuittanceSlice.actions;
