import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OTPState {
  checkIsLoading: boolean;
  checkLoadingError: string | null;
}

const initialState: OTPState = {
    checkIsLoading: false,
    checkLoadingError: null,
};

export const otpSlice = createSlice({
    name: '@@vp/otp',
    initialState,
    reducers: {
        setOTPCheckLoading(state, action: PayloadAction<OTPState['checkIsLoading']>): void {
            state.checkIsLoading = action.payload;
        },
        setOTPCheckLoadingError(state, action: PayloadAction<OTPState['checkLoadingError']>): void {
            state.checkLoadingError = action.payload;
        },
    }
});

export const {
    setOTPCheckLoading,
    setOTPCheckLoadingError,
} = otpSlice.actions;
