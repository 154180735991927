import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FrontSettings } from '../../../../../../common/model/resources/lkSettings';

interface Settings {
    webClientVersion?: number;
    frontSettings?: FrontSettings;
}

export type ResourcesSettingsState = Settings;
const initialState: ResourcesSettingsState = {
    webClientVersion: 1,
    frontSettings: undefined,
};

export const resourcesSettingsSlice = createSlice({
    name: '@@wh/resources/settings',
    initialState,
    reducers: {
        update(state, action: PayloadAction<Settings>): ResourcesSettingsState {
            return Object.assign({}, state, action.payload);
        }
    }
});
