export enum VatType {
  None = 'none',
  Vat0 = 'vat0',
  Vat10 = 'vat10',
  Vat110 = 'vat110',
  Vat20 = 'vat20',
  Vat120 = 'vat120',
}

type VatTypeToVatRate = {
  [key in VatType]: number;
};

export const VatRateDictionary: VatTypeToVatRate = {
  [VatType.None]: 0,
  [VatType.Vat0]: 0,
  [VatType.Vat10]: 10,
  [VatType.Vat110]: 100 / 9,
  [VatType.Vat20]: 20,
  [VatType.Vat120]: 25,
};
