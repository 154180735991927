import { FrontSettings } from '../resources/lkSettings';

export enum MeterEntriesValidationErrorType {
    Excessive = 'excessive',
    Insufficient = 'insufficient',
    Format = 'format',
    Period = 'period',
    DailyLimit = 'dailyLimit',
    MonthLimit = 'monthLimit',
    UpdateLimit = 'updateLimit',
}

export type MeterEntriesValidationError = {
    serviceName?: string;
    tariff?: string;
    meterId: string;
    meterSerialNum: string;
    meterScaleId: string;
    isSaved?: boolean;
    settings: FrontSettings;
    availableToCorrect?: boolean;
} & ({
    type: MeterEntriesValidationErrorType.Excessive 
    | MeterEntriesValidationErrorType.Format 
    | MeterEntriesValidationErrorType.Insufficient;
    consumption: number;
    measure: string;
    amount: number;
} | {
    type: MeterEntriesValidationErrorType.Period
        | MeterEntriesValidationErrorType.DailyLimit
        | MeterEntriesValidationErrorType.MonthLimit
        | MeterEntriesValidationErrorType.UpdateLimit;
});

export const MeterEntriesValidationErrorTypeDictionary: Record<MeterEntriesValidationErrorType, string> = {
    [MeterEntriesValidationErrorType.Excessive]: 'excessive',
    [MeterEntriesValidationErrorType.Insufficient]: 'insufficient',
    [MeterEntriesValidationErrorType.Format]: 'format',
    [MeterEntriesValidationErrorType.Period]: 'period',
    [MeterEntriesValidationErrorType.DailyLimit]: 'dailyLimit',
    [MeterEntriesValidationErrorType.MonthLimit]: 'monthLimit',
    [MeterEntriesValidationErrorType.UpdateLimit]: 'updateLimit',
};
