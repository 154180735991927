import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AccountDeleteState {
  isDeleting: boolean;
  time: number;
  error: string | null;
}

const initialState: AccountDeleteState = {
    isDeleting: false,
    time: 0,
    error: null
};

const START_TIME_SECONDS = 30;

export const accountDeleteSlice = createSlice({
    name: '@@vp/accountDelete',
    initialState,
    reducers: {
        startTimer: (state) => {
            state.isDeleting = true;
            state.time = START_TIME_SECONDS;
            state.error = null;
        },
        stopTimer: (state) => {
            state.isDeleting = false;
        },
        decrementTime: (state) => {
            if (state.time > 0) {
                state.time -= 1;
            }
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    }
});

export const {
    startTimer,
    stopTimer,
    decrementTime,
} = accountDeleteSlice.actions;
