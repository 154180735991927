import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoriesMeterEntries } from '../../../common/model/meterReadings/historyMeterEntries';

import { ActionType } from '../../actions/model';

export interface MeterReadingsState {
    historiesMeterEntries: HistoriesMeterEntries[] | null;
    historiesMeterEntriesLoading: boolean;
    historiesMeterEntriesLoaded: boolean;
}

const initialState: MeterReadingsState = {
    historiesMeterEntries: null,
    historiesMeterEntriesLoading: false,
    historiesMeterEntriesLoaded: false,
};

export const meterReadingsSlice = createSlice({
    name: '@@vp/users/access-rules',
    initialState,
    reducers: {
        setHistoriesMeterEntries(state, action: PayloadAction<MeterReadingsState['historiesMeterEntries']>): void {
            state.historiesMeterEntries = action.payload;
        },
        setHistoriesMeterEntriesLoading(state, action: PayloadAction<MeterReadingsState['historiesMeterEntriesLoading']>): void {
            state.historiesMeterEntriesLoading = action.payload;
        },
        clearMeterEntriesDataData(state): void {
            state.historiesMeterEntries = null;
            state.historiesMeterEntriesLoaded = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): MeterReadingsState => initialState,
    }
});

export const {
    setHistoriesMeterEntries,
    setHistoriesMeterEntriesLoading,
    clearMeterEntriesDataData,
} = meterReadingsSlice.actions;
