import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PasswordResetState {
  isSubmitting: boolean;
}

const initialState: PasswordResetState = {
    isSubmitting: false,
};

export const passwordResetSlice = createSlice({
    name: '@@vp/passwordReset',
    initialState,
    reducers: {
        setPasswordResetSubmitting(state, action: PayloadAction<PasswordResetState['isSubmitting']>): void {
            state.isSubmitting = action.payload;
        },
    }
});

export const {
    setPasswordResetSubmitting,
} = passwordResetSlice.actions;
