export enum appUrls {
    //Регистрация
    api_post_registration = '/api/registration',
    api_registration_confirm = '/api/registration/confirm',
    api_post_external_registration = '/api/external-registration',
    api_external_registration_confirm = '/api/external-registration/confirm',

    //Клиент
    api_client_login = '/api/client/login',
    api_client_logout = '/api/client/logout',
    api_post_client_password_recovery_request = '/api/client/password-recovery-request',
    api_post_client_password_reset = '/api/client/password-reset',
    api_post_client_phone_change_request = '/api/client/phone-change-request',
    api_post_client_add_account_request = '/api/client/accounts/add-request',
    api_post_client_add_account = '/api/client/accounts/add',
    api_get_client_profile = '/api/client/profile',
    api_put_client_profile = '/api/client/profile',
    api_put_client_phone = '/api/client/phone',
    api_put_client_password = '/api/client/password',

    api_get_client_accounts_with_meter_and_entries = '/api/client/accounts/meter-and-entries',
    api_get_client_accounts_without_meter_and_entries = '/api/client/accounts/without-meter-and-entries',
    api_get_client_client_accounts = '/api/client/client-accounts',

    api_get_client_client_account_regions = '/api/client/client-account-regions',
    api_get_figure_out_region_has_atol = '/api/resources/settings/region-atol-group',
    api_get_figure_out_region_has_sberpay_settings = '/api/resources/settings/region-sberpay-settings',
    api_get_client_accounts_with_balance_and_charges = '/api/client/accounts/balance-and-charges',
    api_get_client_accounts_balance_history = '/api/client/accounts/:accountId/balance-history',
    api_get_client_accounts_charges_for_period = '/api/client/accounts/:accountId/charges/:year/:month',

    api_get_client_accounts_claims = '/api/client/accounts/claims',
    api_get_client_accounts_claims_document = '/api/client/accounts/claims/:claimId/document',
    api_get_client = '/api/client',
    api_delete_client_request = '/api/client/delete/request',
    api_delete_client_confirm = '/api/client/delete/confirm',

    api_put_admin_client = '/api/client/admin',
    api_delete_admin_client= '/api/client/admin',

    api_post_delete_client_account_request = '/api/client/accounts/delete',
    api_delete_client_account_confirm = '/api/client/accounts/delete',
    api_post_client_account_paper_document = '/api/client/accounts/paper-document',
    api_post_client_account_paper_document_status = '/api/client/accounts/paper-document-status',

    // Счета
    api_post_account_allow_notifications = '/api/account/:accountId/allow-notifications',
    api_get_account_balance_history = '/api/account/:accountId/balance-history',
    api_get_account_charges_for_period = '/api/account/:accountId/charges/:year/:month',
    api_get_account_services_dictionary = '/api/account/services-dictionary',
    api_get_account_charges_history_excel = '/api/account/:accountId/charges-history/excel',
    api_post_account_invoice_request = '/api/account/:accountId/invoice-request',
    api_post_reject_paper_payment_document = '/api/account/:accountId/reject-paper-payment-document',
    api_get_account_services = '/api/account/services',
    api_post_account_services = '/api/account/services',

    //Показания счетчиков
    api_post_meter_entries = '/api/meter-entries',
    api_put_meter_entries = '/api/meter-entries',
    api_get_history_entries = '/api/history-entries',
    api_get_history_entries_chart = '/api/history-entries-chart',

    // Платежи
    api_post_payment_qr = '/api/payment/qr',
    api_get_payment_qr = '/api/payment/qr/:qrcId',
    api_post_payment_callback = '/api/payment/qr/c2b_payment',

    api_post_payment_sp = '/api/payment/sberpay',
    api_get_payment_sp = '/api/payment/sberpay/:spcId',
    api_get_payment_spc = '/api/payment/sberpayc/:spcId',
    api_post_payment_sp_callback = '/api/payment/sberpay/c2b_payment',

    api_post_payment_order = '/api/payment/order',
    api_post_payment_autopay = '/api/payment/autopay',
    api_put_payment_autopay = '/api/payment/autopay',
    api_delete_payment_autopay = '/api/payment/autopay/:accountId',
    api_put_payment_order_complete = '/api/payment/order-complete/:orderId',
    api_get_payments_history = '/api/payments-history',
    api_get_payment_details = '/api/payment/:paymentId/details',
    api_get_admin_payments_history = '/api/admin-payments-history',
    api_get_admin_payments_report = '/api/admin-payments-report',
    api_get_admin_payments_report_excel = '/api/admin-payments-report/excel',
    api_get_admin_payment_details_report = '/api/admin-payment-details-report',
    api_get_admin_payment_details_report_excel = '/api/admin-payment-details-report/excel',
    api_get_admin_payment_details = '/api/admin-payment/:paymentId/details',
    api_get_admin_payments_order = '/api/admin_payments_order',
    api_download_payment_logs_excel = '/api/payment-logs/excel',
    api_download_payment_order_logs_excel = '/api/payment-order-logs/excel',
    api_post_atol_document_status_callback = '/api/payment/receipt/atol/document-status/:paymentId',

    // Internal OTP
    api_create_otp = '/api/otp',
    api_post_check_otp = '/api/otp/check',
    api_post_check_otp_intermediate = '/api/otp/check/intermediate',
    api_resend_otp = '/api/otp/resend/:sessionId',
    api_get_otp_session_data = '/api/otp/session/:sessionId',

    // External OTP
    api_external_create_otp = '/external/otp',
    api_external_check_otp = '/external/otp/check',

    // Авторизация для пользователей Identity server
    api_user_login = '/api/user/login',
    api_user_login_callback = '/api/user/login/callback',
    api_user_logout = '/api/user/logout',
    api_user_logout_callback = '/api/user/logout/callback',
    api_get_current_user = '/api/current-user',

    // resources
    api_get_resources = '/api/resources',
    api_get_resources_version = '/api/resources/version',
    api_get_cache_clear = '/api/resources/cache-clear',
    api_get_settings = '/api/resources/settings',
    api_get_front_settings = '/api/resources/front-settings',
    api_post_settings = '/api/resources/settings',

    // CRUD users
    api_get_users = '/api/users',
    api_get_user = '/api/users/:userId',
    api_create_user = '/api/users',
    api_update_user = '/api/users/:userId',
    api_delete_user = '/api/users/:userId',
    api_get_user_history_accounts = '/api/users/history/accounts',
    api_get_user_history_entries = '/api/users/history/entries',
    api_get_user_history_account_info = '/api/users/history/account-info',
    api_download_history_entries_excel = '/api/users/history/exel',
    api_download_history_entries_pdf = '/api/users/history/entries/pdf',
    api_download_history_payments_pdf = '/api/users/history/payments/pdf',
    api_download_history_charges_pdf = '/api/users/history/charges/pdf',


    // вспомогательный метод для тестов
    api_test_access = '/access/test',

    // Access rules
    api_get_access_access_rules = '/api/access/access-rules',

    // Roles CRUD
    api_get_access_roles = '/api/access/roles',
    api_post_access_roles = '/api/access/roles',
    api_put_access_roles = '/api/access/roles/:id',
    api_delete_access_roles = '/api/access/roles/:id',

    // Access rules to role
    api_get_access_roles_access_rules = '/api/access/roles/:roleId/access-rules',
    api_put_access_roles_access_rules = '/api/access/roles/:roleId/access-rules',

    // Access rules to user
    api_get_access_users_access_rules = '/api/access/users/:userId/access-rules',
    api_put_access_users_access_rules = '/api/access/users/:userId/access-rules',

    // User regions to user
    api_get_access_users_regions = '/api/access/users/:userId/regions',
    api_put_access_users_regions = '/api/access/users/:userId/regions',

    // Integration ISUP
    api_get_meter_cumulative_energy_reading_data = '/api/meter-cumulative-energy-reading-data',
    api_get_meter_incremental_energy_reading_data = '/api/meter-incremental-energy-reading-data',
    api_get_check_meters = '/api/check-meters',
    api_get_meter = '/api/meter',
    api_post_meter_current_reading_order = '/api/meter-current-reading-order',
    api_post_meter_journal = '/api/meter-journal',
    api_post_meter_isup_excel = '/api/meter-isup-excel',

    // Admin impersonation
    api_get_admin_clients = '/api/clients',
    api_post_admin_login_impersonation = '/api/admin-login-impersonation',
    api_get_impersonation = '/api/impersonation',

    //Admin reports
    api_get_admin_clients_reports = '/api/clients-reports',
    api_get_admin_clients_reports_logs_excel = '/api/clients-reports-logs/excel',
    api_get_admin_services = '/api/services',

    api_get_admin_client_accounts_registration_reports = '/api/client-accounts-registration-reports',
    api_get_admin_client_accounts_registration_reports_logs_excel = '/api/client-accounts-registration-reports-logs/excel',
    api_get_admin_clients_reports_registrations = '/api/clients-reports-registrations',
    api_get_admin_clients_reports_registrations_logs_excel = '/api/clients-reports-registrations-logs/excel',
    api_get_admin_meter_reading_orders_reports = '/api/meter-reading-orders-reports',
    api_get_admin_meter_reading_orders_report_excel = '/api/meter-reading-orders-reports/excel',
    api_get_admin_meter_readings_report = '/api/meter-readings-report',
    api_get_admin_meter_readings_report_excel = '/api/meter-readings-report/excel',

    // Notification
    api_get_notification_list_with_send_details = '/api/notification/list-with-send-details',
    api_post_notification_item = '/api/notification/item',
    api_put_notification_item = '/api/notification/item/:id',
    api_delete_notification_item = '/api/notification/item/:id',
    api_put_notification_item_sending_start = '/api/notification/item-sending/:id/start',
    api_put_notification_item_sending_stop = '/api/notification/item-sending/:id/stop',

    //Notification Sending
    api_get_notification_sending = '/api/notification-sending',
    api_get_notification_errors = '/api/notification-send-process/:id/errors',

    //Client Notifications
    api_get_client_notifications = '/api/client-notifications',
    api_put_client_notification_viewed = '/api/client-notification/viewed/:id',
    api_put_client_all_notifications_viewed = '/api/all-client-notifications/viewed',
    api_delete_client_notification_item = '/api/client-notification/item/:id',
    api_delete_all_client_notifications = '/api/all-client-notifications',

    // Client social networks
    app_get_client_social_network_confirm = '/api/client/social-confirm/:socialNetwork',
    app_get_client_social_network_confirm_login = '/api/client/social-confirm-login/:socialNetwork',
    app_delete_client_social_network = '/api/client/social-network/:socialNetwork',

    //Stress testing
    app_get_stress_testing_meter_entries = '/api/stress-testing/meter-entries',
    app_get_stress_testing_create_meter_entries = '/api/stress-testing/create-meter-entries',
    app_get_stress_testing_statistic = '/api/stress-testing/statistic',
    app_get_stress_testing_get_meter_entries = '/api/stress-testing/statistic/get-meter-entries',
    app_get_stress_testing_get_payments = '/api/stress-testing/statistic/get-payments',
    app_get_stress_testing_get_charges = '/api/stress-testing/statistic/get-charges',
    app_get_stress_testing_login = '/api/stress-testing/login',
    app_get_stress_testing_request_login = '/api/stress-testing/request-login',

    // metrics
    app_get_metrics = '/metrics',
    app_get_metric = '/metrics/:name',
    app_get_health_checks = '/healthchecks',
    app_get_health_check = '/healthchecks/:name',

    // api for mobile app
    app_get_statistic = '/api/statistic',

    // unauthorized
    api_get_unauthorized_account_meters = '/api/unauthorized/account-meters',
    api_post_unauthorized_account_meters = '/api/unauthorized/account-meters',
    api_get_unauthorized_payment = '/api/unauthorized/account-payment',
    api_get_unauthorized_account_with_balance_and_charges = '/api/unauthorized/account/balance-and-charges',
    api_post_unauthorized_payment_order = '/api/unauthorized/payment/order',
    api_post_unauthorized_payment = '/api/unauthorized/payment',
    api_put_unauthorized_payment_order_complete = '/api/unauthorized/payment/order-complete/:orderId',
    api_get_unauthorized_accounts_with_meter_and_entries = '/api/unauthorized/meter-and-entries',
    api_post_unauthorized_confirm_phone_payment = '/api/unauthorized/confirm-phone-payment',
    api_get_unauthorized_short_link = '/api/short-link',

    api_get_technical_working_state = '/api/technical-working-state',
    api_get_technical_working_states_by_regions = '/api/technical-working-states-by-regions',

    // mobile
    api_post_mobile_create_session = '/api/mobile/CreateSession',
    api_post_mobile_list_sessions = '/api/mobile/ListSession',
    api_post_mobile_delete_session = '/api/mobile/DeleteSession',

    api_post_mobile_ask_code = '/api/mobile/AskCode',
    api_post_mobile_change_password_code = '/api/mobile/ChangePasswordCode',
    api_post_mobile_change_password = '/api/mobile/ChangePassword',

    api_post_mobile_prepare_login = '/api/mobile/PrepareLogin',
    api_post_mobile_create_login = '/api/mobile/CreateLogin',

    api_post_mobile_change_phone = '/api/mobile/ChangePhone',
    api_post_mobile_confirm_phone_code = '/api/mobile/ConfirmPhoneCode',

    api_post_mobile_change_email = '/api/mobile/ChangeEmail',
    api_post_mobile_confirm_email_code = '/api/mobile/ConfirmEmailCode',

    api_post_mobile_attach_account = '/api/mobile/AttachAccount',
    api_post_mobile_list_accounts = '/api/mobile/ListAccount',
    api_post_mobile_remove_account = '/api/mobile/RemoveAccount',
    api_post_mobile_check_account = '/api/mobile/CheckAccount',

    api_post_mobile_pull_accounts = '/api/mobile/PullAccounts',
    api_post_mobile_list_pay = '/api/mobile/ListPay',
    api_post_mobile_get_bindings = '/api/mobile/GetBindings',
    api_post_mobile_unbind_card = '/api/mobile/UnBindCard',
    api_post_mobile_pay_order = '/api/mobile/PayOrder',

    api_post_mobile_list_counter_value = '/api/mobile/ListCounterValue',

    api_post_mobile_list_tariff = '/api/mobile/ListTariff',
    api_post_mobile_list_device = '/api/mobile/ListDevice',

    api_post_mobile_check_counter_value = '/api/mobile/CheckCounterValue',
    api_post_mobile_save_counter_value = '/api/mobile/SaveCounterValue',

    api_get_mobile_get_contacts = '/api/mobile/GetContacts',

    api_get_mobile_get_logins = '/api/mobile/GetLogins',

    api_get_mobile_technical_working_state = '/api/mobile/GetTechnicalWorkingState',

    api_post_create_technical_connection = '/api/technical-connection',
    api_get_technical_connection_docs = '/api/accounts/:accountId/technical-connection-docs',
    api_get_technical_connection_doc_download = '/api/technical-connection-docs/:documentId/download',
}
