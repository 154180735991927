import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { clientCurrentRegionSelector, clientProfileDataSliceSelector } from '../../../redux/selectors';
import { technicalWorkingStatesSelector } from '../../../redux/selectors/unauthorized/technicalWorking';
import { TechnicalWorkingStatesResponse } from '../../../common/model/unauthorized/technicalWorking';
import { HostnamesToRegionsMap } from '../../../common/model/client/divisionType';

export const useTechnicalWorkingState = () => {
    const clientProfile = useSelector(clientProfileDataSliceSelector);
    const currentRegion = useSelector(clientCurrentRegionSelector);
    const technicalWorkingStates = useSelector(technicalWorkingStatesSelector);
    const technicalWorkingState = useMemo(() => {
        const stateByHostname = HostnamesToRegionsMap[window.location.hostname];

        if (!clientProfile) {
            return technicalWorkingStates?.[stateByHostname] ?? technicalWorkingStates?.common;
        }

        const key = (currentRegion ?? stateByHostname ?? 'common') as keyof TechnicalWorkingStatesResponse;

        return technicalWorkingStates?.[key]?.enabled
            ? technicalWorkingStates?.[key]
            : technicalWorkingStates?.common;
    }, [clientProfile, technicalWorkingStates, currentRegion]);

    return {
        enabled: technicalWorkingState?.enabled ?? false,
        message: technicalWorkingState?.message ?? '',
    };
};
