import React from 'react';
import { Modal, ModalFuncProps } from 'antd';
import { ExclamationCircleOutlined, InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ModalOwnProps } from '../model';

// 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm'
const defaultModalType: Required<ModalOwnProps>['type'] = 'info';

const modalTypeToIcon: Record<Required<ModalOwnProps>['type'], React.FC> = {
    basic: InfoCircleOutlined,
    info: InfoCircleOutlined,
    success: CheckCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
    confirm: ExclamationCircleOutlined,
};

export const showModal = ({
    type,
    title,
    infoMessage,
    onOk,
    onCancel,
    afterClose,
    okText,
    cancelText,
    centered = true
}: ModalOwnProps & Pick<ModalFuncProps, 'afterClose'>) => {
    const Icon = modalTypeToIcon[type ?? defaultModalType];

    Modal[type as Exclude<ModalOwnProps['type'], 'basic'> ?? defaultModalType]({
        icon: <Icon />,
        title,
        content: infoMessage,
        okButtonProps: {
            className: 'primary',
            shape: 'round',
        },
        okText,
        onOk,
        cancelButtonProps: {
            className: 'secondary',
            shape: 'round',
        },
        cancelText,
        onCancel,
        maskClosable: true,
        autoFocusButton: null,
        okCancel: Boolean(onCancel),
        afterClose,
        centered
    });
};

export const View: React.FC<ModalOwnProps> = props => {
    const showInfoModal = () => {
        showModal(props);
    };

    return (
        <div onClick={showInfoModal}>
            {props.children}
        </div>
    );
};
