import { push } from 'connected-react-router';
import { Routes } from '../../../../app/shared/constants';
import { httpService } from '../../../../app/shared/httpWrapper';
import { getRoute } from '../../../../app/shared/pipes';
import { OTPChecker } from '../../../../common/model/otp/oTPChecker';
import { OtpCreateResult } from '../../../../common/model/otp/otpCreateResult';
import { clientProfileSlice } from '../../../reducers/client/profile';
import { appUrls } from '../../../../common/appUrls';
import { ClientProfileExtViewModel } from '../../../../common/model/client/clientProfileExtViewModel';
import { AppThunk } from '../../../reducers';
import { clientAccountToDeleteSliceSelector, clientProfileSliceSelector } from '../../../selectors';
import { createSnackbar, modalNotificationsPush } from '../../controls';
import { getAccountsWithBalanceAndCharges, getAccountsWithMetersAndEntries } from 'redux/actions/accounts';
import { getClientAccounts } from '../accounts';
import { removeAccountToDelete, setAccountToDelete } from 'redux/reducers/client/accountToDelete';
import { removeEditToClient } from 'redux/actions/users';

export const getClientProfile = (): AppThunk => async (dispatch, getState) => {
    const { isLoading } = clientProfileSliceSelector(getState());

    if (isLoading) {
        return;
    }

    dispatch(clientProfileSlice.actions.fetchStart());

    try {
        const result = await httpService.get<ClientProfileExtViewModel>(
            appUrls.api_get_client_profile
        );
        localStorage.setItem('client', JSON.stringify({ phoneNumber: result.phoneNumber }));
        dispatch(clientProfileSlice.actions.fetchSuccess({ data: result }));
    } catch (err: any) {
        const errorMessage = 'Произошла ошибка при загрузке профиля. Попробуйте позже';
        dispatch(clientProfileSlice.actions.fetchFailure(errorMessage));
    }
};

export const deleteAccountRequest = (): AppThunk => async (dispatch) => {
    try {
        const { sessionId } = await httpService.post<OtpCreateResult>(appUrls.api_delete_client_request);
        const confirmRoute = getRoute(Routes.deleteAccountConfirm, {}, {
            sessionId,
        });
        dispatch(createSnackbar({
            type: 'success',
            message: 'На ваш номер телефона отправлено СМС с кодом для подтверждения удаления аккаунта'
        }));
        dispatch(push(confirmRoute));
    } catch {
        dispatch(createSnackbar({
            type: 'success',
            message: 'При удалении аккаунта произошла ошибка. Попробуйте позже'
        }));
    }
};

export const deleteAccountConfirm = (data: OTPChecker): AppThunk => async (dispatch) => {
    try {
        await httpService.delete(appUrls.api_delete_client_confirm, data);
        dispatch(modalNotificationsPush({
            title: 'Удаление аккаунта',
            message: 'Аккаунт успешно удалён.',
            type: 'info',
            onOk: () => {
                dispatch(push(Routes.login));
            }
        }));
    } catch {
        dispatch(createSnackbar({
            type: 'error',
            message: 'При удалении аккаунта произошла ошибка. Попробуйте позже'
        }));
    }
};

export const updateAccountAdmin = (clientId: string, fields: any): AppThunk => async (dispatch) => {
    try {
        await httpService.put<void>(appUrls.api_put_admin_client, {
            clientId,
            ...fields
        });

        const accountsToDelete = fields.accounts.filter((account: any) => account.deleteAccount);

        const message = accountsToDelete.length === fields.accounts.length
            ? 'Аккаунт и лицевые счета успешно удалены'
            : 'Аккаунт успешно обновлен';

        dispatch(modalNotificationsPush({
            title: 'Изменение аккаунта',
            message,
            type: 'info',
        }));
        dispatch(removeEditToClient());
    } catch (e) {
        const error = e as { data: { errorDetails: string }, message: string };
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? error.message ?? 'В процессе сохранения данных возникла неизвестная ошибка'
        }));
    }
};

export const deleteAccountAdmin = (clientId: string): AppThunk => async (dispatch) => {
    try {
        await httpService.delete(appUrls.api_delete_admin_client, {
            clientId,
        });

        dispatch(modalNotificationsPush({
            title: 'Удаление аккаунта',
            message: 'Аккаунт успешно удалён',
            type: 'info',
        }));
    } catch (e) {
        const error = e as { data: { errorDetails: string } };
        dispatch(createSnackbar({
            type: 'error',
            message: error?.data?.errorDetails ?? 'При удалении аккаунта произошла ошибка. Попробуйте позже'
        }));
    } finally {
        dispatch(removeEditToClient());
    }
};

export const unlinkAccountRequest = (accountId?: string | null, disableInvoice?: boolean): AppThunk => async (dispatch) => {
    if (!accountId) {
        dispatch(createSnackbar({
            type: 'error',
            message: 'Лицевой счет не найден'
        }));
    }
    try {

        const baseRoute = appUrls.api_post_delete_client_account_request;
        const { sessionId } = await httpService.post<OtpCreateResult>(baseRoute, { accountId });

        const confirmRoute = getRoute(Routes.accountDelete, {}, {
            sessionId,
            accountId,
            disableInvoice
        });
        dispatch(createSnackbar({
            type: 'success',
            message: 'На ваш номер телефона отправлено СМС с кодом для подтверждения удаления лицевого счета'
        }));

        dispatch(setAccountToDelete({ accountId: accountId as string, disabledInvoice: disableInvoice }));
        dispatch(push(confirmRoute));
    } catch (e) {
        const message = (e as any).data.errorDetails ?? 'При удалении лицевого счета произошла ошибка. Попробуйте позже';
        dispatch(createSnackbar({
            type: 'error',
            message
        }));
    }
};

export const allowPapertRequest = (accountId?: string | null): AppThunk => async (dispatch) => {
    if (!accountId) {
        dispatch(createSnackbar({
            type: 'error',
            message: 'Лицевой счет не найден'
        }));
    }
    try {
        const baseRoute = appUrls.api_post_client_account_paper_document;
        const { sessionId } = await httpService.post<OtpCreateResult>(baseRoute, { accountId });

        const confirmRoute = getRoute(Routes.accountAllowPaperDocument, {}, {
            sessionId,
            accountId,
        });
        dispatch(createSnackbar({
            type: 'success',
            message: 'На ваш номер телефона отправлено СМС с кодом для подтверждения удаления лицевого счета'
        }));
        dispatch(push(confirmRoute));
    } catch (e) {
        const message = (e as any).data?.errorDetails ?? 'При удалении лицевого счета произошла ошибка. Попробуйте позже';
        dispatch(createSnackbar({
            type: 'error',
            message
        }));
    }
};

export const unlinkAccountConfirm = (
    data: OTPChecker,
    accountId: string | null,
): AppThunk => async (dispatch, getState) => {
    if (!accountId) {
        dispatch(createSnackbar({
            type: 'error',
            message: 'Лицевой счет не найден'
        }));
    }

    const { disabledInvoice } = clientAccountToDeleteSliceSelector(getState());

    try {
        await httpService.put(appUrls.api_delete_client_account_confirm, { accountId, otpChecker: data });

        const invalidate = () => {
            dispatch(getClientAccounts({ includeAutopaySettings: true }));
            dispatch(getAccountsWithBalanceAndCharges());
            dispatch(getAccountsWithMetersAndEntries());
            dispatch(removeAccountToDelete());
        };

        if (disabledInvoice) {
            dispatch(modalNotificationsPush({
                type: 'warning',
                title: 'Внимание',
                message: 'Доставка платежного документа будет возобновлена на бумажном носителе',
                cancelButtonTitle: 'Отмена',
                okButtonTitle: 'Подтвердить',
                onOk: () => {
                    dispatch(allowPapertRequest(accountId));
                },
                onCancel: () => {
                    dispatch(modalNotificationsPush({
                        title: 'Удаление лицевого счета',
                        message: 'Лицевой счет успешно удалён.',
                        type: 'info',
                        onOk: () => {
                            dispatch(push(Routes.profile));
                        }
                    }));
                }
            }));
        } else {
            dispatch(modalNotificationsPush({
                title: 'Удаление лицевого счета',
                message: 'Лицевой счет успешно удалён.',
                type: 'info',
                onOk: () => {
                    dispatch(push(Routes.profile));
                }
            }));
        }

        invalidate();

    } catch (e) {
        const message = (e as any).data?.errorDetails ?? 'При удалении лицевого счета произошла ошибка. Попробуйте позже';
        dispatch(createSnackbar({
            type: 'error',
            message
        }));
    }
};

export const allowPaperDocumentConfirm = (
    data: OTPChecker,
    accountId?: string | null,
): AppThunk => async (dispatch) => {
    if (!accountId) {
        dispatch(createSnackbar({
            type: 'error',
            message: 'Лицевой счет не найден'
        }));
    }

    try {
        await httpService.post(appUrls.api_post_client_account_paper_document_status, { accountId, otpChecker: data });

        const invalidate = () => {
            dispatch(push(Routes.profile));
            dispatch(getClientAccounts({ includeAutopaySettings: true }));
            dispatch(getAccountsWithBalanceAndCharges());
            dispatch(getAccountsWithMetersAndEntries());
            dispatch(removeAccountToDelete());
        };

        dispatch(modalNotificationsPush({
            title: 'Удаление лицевого счета',
            message: 'Лицевой счет успешно удалён.',
            type: 'info',
            onOk: () => {
                invalidate();
            }
        }));

    } catch (e) {
        const message = (e as any).data?.errorDetails ?? 'При удалении лицевого счета произошла ошибка. Попробуйте позже';
        dispatch(createSnackbar({
            type: 'error',
            message
        }));
    }
};
