import { getApiRoute } from '../../../../app/shared/pipes';
import { httpService } from '../../../../app/shared/httpWrapper';
import { appUrls } from '../../../../common/appUrls';
import { AccessRule } from '../../../../common/model/users/accessRule';
import { AppThunk } from '../../../reducers';
import { accessAccessRulesSlice } from '../../../reducers/users/accessRules';

export const setActiveAccessRules = (rules: AccessRule[]): AppThunk => (dispatch): void => {
    dispatch(accessAccessRulesSlice.actions.setAccessRules(rules));
};

export const getActiveAccessRules = (): AppThunk => async (dispatch): Promise<void> => {
    const rules = await httpService.get<AccessRule[]>(getApiRoute(appUrls.api_get_access_access_rules));
    if (Array.isArray(rules)) {
        dispatch(setActiveAccessRules(rules));
    }
};
