
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountDetailsViewModel } from '../../../../common/model/account/accountDetailsViewModel';
import { ActionType } from '../../../actions/model';


export interface AccountsState {
  accounts: AccountDetailsViewModel[] | null;
  accountsLoading: boolean;
  accountsLoaded: boolean;
  accountsLoadingError: string | null;
}

const initialState: AccountsState = {
    accounts: null,
    accountsLoading: false,
    accountsLoaded: false,
    accountsLoadingError: null,
};

export const accountsWithoutMetersAndEntriesSlice = createSlice({
    name: '@@vp/accounts/withoutMetersAndEntries',
    initialState,
    reducers: {
        setAccountsWithoutMetersLoading(state, action: PayloadAction<AccountsState['accountsLoading']>): void {
            state.accountsLoading = action.payload;
        },
        resetAccountsWithoutMeters(state): void {
            state.accounts = initialState.accounts;
            state.accountsLoaded = initialState.accountsLoaded;
            state.accountsLoading = initialState.accountsLoading;
            state.accountsLoadingError = initialState.accountsLoadingError;
        },
        setAccountsWithoutMeters(state, action: PayloadAction<AccountDetailsViewModel[]>): void {
            state.accounts = action.payload;
            state.accountsLoaded = true;
            state.accountsLoading = false;
        },
        setAccountsWithoutMetersLoadingError(state, action: PayloadAction<AccountsState['accountsLoadingError']>): void {
            state.accountsLoadingError = action.payload;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AccountsState => initialState,
    }
});

export const {
    resetAccountsWithoutMeters,
    setAccountsWithoutMeters,
    setAccountsWithoutMetersLoading,
    setAccountsWithoutMetersLoadingError,
} = accountsWithoutMetersAndEntriesSlice.actions;
