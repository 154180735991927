import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionType } from '../../../actions/model';


interface AdminNotificationsEditState {
    itemId: string | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: AdminNotificationsEditState = {
    itemId: null,
    isLoading: false,
    error: null,
};

export const adminNotificationsEditSlice = createSlice({
    name: '@@vp/adminNotifications/edit',
    initialState,
    reducers: {
        startRequest(state, action: PayloadAction<AdminNotificationsEditState['itemId']>): void {
            state.itemId = action.payload;
            state.isLoading = true;
            state.error = null;
        },
        requestSuccess(state) {
            state.itemId = null;
            state.isLoading = false;
        },
        requestFailed(state, action: PayloadAction<AdminNotificationsEditState['error']>): void {
            state.error = action.payload;
            state.isLoading = false;
        },
    },
    extraReducers: {
        [ActionType.LOGOUT]: (): AdminNotificationsEditState => initialState,
    }
});
