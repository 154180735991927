import _ from 'lodash';
import { Branches, branchesWithAlwaysSeparativePayments } from '../../../../common/model/account/branches';
import { AccountServicesWithChargesViewModel } from '../../../../common/model/account/accountServicesWithChargesViewModel';
import { AccountViewModel } from '../../../../common/model/account/accountViewModel';
import { AccountBalanceViewModel } from '../../../../common/model/account/accountBalanceViewModel';
import { DebtTypeFromBilling } from '../../../../common/model/account/debtType';
import { AccountWithBalanceAndChargesViewModel } from '../../../../common/model/account/accountWithBalanceAndChargesViewModel';
import { AccountServiceChargeDetailViewModel } from '../../../../common/model/account/accountServiceChargeDetailViewModel';
import { AccountServiceChargeViewModel } from '../../../../common/model/account/accountServiceChargeViewModel';
import { ServicesViewModel } from '../../../../common/model/account/servicesViewModel';

export interface ChargeDetails {
    id?: string;
    accountServiceChargeId?: string;
    volume: string;
    tariffName?: string;
    tariffPrice?: number | string;
    tariffZone?: string;
    chargeAmount?: number;
    recalcAmount?: number;
    chargeType?: string;
}

export type BalanceAndChargesServiceMappedData = Required<
    Pick<ServicesViewModel, 'name' | 'parentalServiceId' | 'id' | 'hasTariff' | 'prepaymentAllowed'> & // Поставщик / Услуга
    Pick<AccountServicesWithChargesViewModel, 'branch'> &
    Pick<
        AccountServiceChargeViewModel,
        'accountServiceId' // id услуги ЛС
        | 'beginPeriodBalance' // Сальдо ?
        | 'paymentsAmount' // Оплачено,₽
        | 'endPeriodBalance' // К оплате,₽
        | 'debtType' // Тип долга
        | 'excessPay'
        | 'increaseCoefficient'
    > & Pick<
        AccountServiceChargeDetailViewModel,
        'chargeAmount' | // Начислено
        'recalcAmount' | // Перерасчет,₽
        'tariffPrice' // Тариф
    > & { chargeDetails: ChargeDetails[]; isAtomic: boolean; }
>;

export type BalanceAndChargesServicesDictionary = {
    [serviceProvider: string]: BalanceAndChargesServiceMappedData[];
};

export interface BalanceAndChargesMappedDataItem {
    accountId: AccountWithBalanceAndChargesViewModel['id'];
    isSeparatePayments: AccountWithBalanceAndChargesViewModel['isSeparatePayments'];
    balance: AccountBalanceViewModel;
    allServices: BalanceAndChargesServicesDictionary;
    atomicServices: BalanceAndChargesServicesDictionary;
    separatableServices: BalanceAndChargesServicesDictionary;
}

export type BalanceAndChargesMappedData = Partial<Record<DebtTypeFromBilling, BalanceAndChargesMappedDataItem>>;

export type BalanceAndChargesItemMappedData = NonNullable<BalanceAndChargesMappedData[keyof BalanceAndChargesMappedData]>;
export type AccountWithMappedBalanceAndChargesData = AccountViewModel & { mappedData: BalanceAndChargesMappedData; services: AccountWithBalanceAndChargesViewModel['services']; };

const getTransformedVolume = (hasTariff: boolean, volume?: number, measure?: string) => {
    if (!hasTariff) {
        return volume ? `${`${volume}`.replace('.', ',')}${measure ? `\u00a0${measure}` : ''}` : '';
    }

    return volume ? `${`${volume}`.replace('.', ',')}${measure ? `\u00a0${measure}` : ''}` : '-';
};

const getTransformedTariffPrice = (hasTariff: boolean, tariffPrice?: number) => {
    return (hasTariff ? (tariffPrice ?? 0) : tariffPrice) || '';
};

const mapAccountServiceChargeDetails = (chargeDetails: AccountServiceChargeDetailViewModel[], measure: string, hasTariff: boolean): BalanceAndChargesServiceMappedData['chargeDetails'] => {
    return chargeDetails.map(chargeDetail => {
        const {
            id,
            accountServiceChargeId,
            volume,
            tariffName,
            tariffPrice,
            chargeAmount,
            recalcAmount,
            chargeType,
            tariffZone
        } = chargeDetail;

        const displayTariffCell = hasTariff || (tariffPrice && volume);

        return {
            id,
            accountServiceChargeId,
            volume: displayTariffCell ? getTransformedVolume(hasTariff, volume, measure) : '',
            tariffName,
            tariffPrice: displayTariffCell ? getTransformedTariffPrice(hasTariff, tariffPrice) : '',
            tariffZone,
            chargeAmount,
            recalcAmount,
            chargeType,
        };
    });
};

export const accountBalanceAndChargesDataMapper = (
    account: AccountWithBalanceAndChargesViewModel
): AccountWithMappedBalanceAndChargesData => {
    const mappedData: BalanceAndChargesMappedData = {
        [DebtTypeFromBilling.Main]: {
            accountId: account.id,
            isSeparatePayments: account.isSeparatePayments,
            balance: {
                beginPeriodBalance: 0,
                chargeAmount: 0,
                paymentsAmount: 0,
                recalcAmount: 0,
                endPeriodBalance: 0,
                periodYear: 0,
                periodMonth: 0,
            },
            allServices: {},
            atomicServices: {},
            separatableServices: {},
        }
    };

    const { services, ...dataToClone } = account;

    for (const service of services ?? []) {
        if (!service.provider?.name || !service.accountServiceCharges?.length) {
            continue;
        }

        for (const serviceCharge of (service.accountServiceCharges as Required<AccountServiceChargeViewModel>[])) {
            if (!serviceCharge.debtType) {
                continue;
            }

            const isServiceFromAtomicGroup = !account.isSeparatePayments && serviceCharge.debtType !== DebtTypeFromBilling.Other && (
                !service.branch || !branchesWithAlwaysSeparativePayments.includes(service.branch)
            );

            const servicesGroupProp = isServiceFromAtomicGroup ? 'atomicServices' : 'separatableServices';
            const existedServicesMappedData =
                mappedData[DebtTypeFromBilling.Main]?.[servicesGroupProp] as BalanceAndChargesServicesDictionary ?? {};
            const existedBalance = mappedData[DebtTypeFromBilling.Main]?.balance as Required<AccountBalanceViewModel>;

            if (!existedServicesMappedData[service.provider.name]) {
                existedServicesMappedData[service.provider.name] = [];
            }

            const servicesAllMappedData = mappedData[DebtTypeFromBilling.Main]?.allServices ?? {};
            if (!servicesAllMappedData[service.provider.name]) {
                servicesAllMappedData[service.provider.name] = [];
            }

            const servicesGroupMappedData = existedServicesMappedData[service.provider.name];
            const existedServiceMappedData = servicesGroupMappedData.find(
                s => s.accountServiceId === serviceCharge.accountServiceId
            ) as BalanceAndChargesServiceMappedData;

            const extendServiceMappedDataFromChargeDetails = (
                serviceMappedData: BalanceAndChargesServiceMappedData
            ) => {
                existedBalance.periodYear = serviceCharge.periodYear;
                existedBalance.periodMonth = serviceCharge.periodMonth;
                existedBalance.beginPeriodBalance += serviceCharge.beginPeriodBalance ?? 0;
                existedBalance.paymentsAmount += serviceCharge.paymentsAmount ?? 0;
                existedBalance.endPeriodBalance += serviceCharge.endPeriodBalance ?? 0;

                for (const chargeDetail of (
                    serviceCharge.accountServiceChargeDetails as Required<AccountServiceChargeDetailViewModel>[]
                )) {
                    serviceMappedData.chargeAmount += chargeDetail.chargeAmount;
                    serviceMappedData.recalcAmount += chargeDetail.recalcAmount;
                    serviceMappedData.tariffPrice += chargeDetail.tariffPrice;

                    existedBalance.chargeAmount += chargeDetail.chargeAmount ?? 0;
                    existedBalance.recalcAmount += chargeDetail.recalcAmount ?? 0;
                }
            };

            const measure = service?.accountServiceCharges[0]?.measure || '';

            if (existedServiceMappedData) {
                existedServiceMappedData.beginPeriodBalance += serviceCharge.beginPeriodBalance;
                existedServiceMappedData.paymentsAmount += serviceCharge.paymentsAmount;
                existedServiceMappedData.endPeriodBalance += serviceCharge.endPeriodBalance;
                existedServiceMappedData.chargeDetails.push(
                    ...mapAccountServiceChargeDetails(
                        serviceCharge.accountServiceChargeDetails, measure, !!service.service?.hasTariff
                    )
                );

                extendServiceMappedDataFromChargeDetails(existedServiceMappedData);
            } else {
                const newServiceMappedData: BalanceAndChargesServiceMappedData = {
                    id: service.service?.id ?? '',
                    name: `${service.service?.name}` || '(Неизвестная услуга)',
                    parentalServiceId: service.service?.parentalServiceId ?? '',
                    branch: service.branch as Branches,
                    accountServiceId: serviceCharge.accountServiceId,
                    beginPeriodBalance: serviceCharge.beginPeriodBalance,
                    paymentsAmount: serviceCharge.paymentsAmount,
                    endPeriodBalance: serviceCharge.endPeriodBalance,
                    chargeDetails: mapAccountServiceChargeDetails(
                        serviceCharge.accountServiceChargeDetails, measure, !!service.service?.hasTariff
                    ),
                    chargeAmount: 0,
                    recalcAmount: 0,
                    tariffPrice: 0,
                    debtType: serviceCharge.debtType,
                    hasTariff: !!service.service?.hasTariff,
                    excessPay: serviceCharge.excessPay,
                    increaseCoefficient: serviceCharge.increaseCoefficient,
                    // #2162 если флаг опциональный, тогда считаем что по умолчанияю можно платить
                    prepaymentAllowed: service.service?.prepaymentAllowed ?? true,
                    isAtomic: isServiceFromAtomicGroup,
                };

                extendServiceMappedDataFromChargeDetails(newServiceMappedData);

                servicesGroupMappedData.push(newServiceMappedData);
                servicesAllMappedData[service.provider.name].push(newServiceMappedData);
            }
        }
    }

    return { ..._.clone(dataToClone), mappedData, services };
};
