import { httpService } from '../../../../app/shared/httpWrapper';
import { downloadExcelData } from '../../../../app/shared/utils/excel';
import { getApiRoute, RequestParams } from '../../../../app/shared/pipes/routing';
import { appUrls } from '../../../../common/appUrls';
import { PaymentHistoryResponse } from '../../../../common/model/payment/paymentHistoryResponse';
import { PaymentLogsExcelResponse } from '../../../../common/model/payment/paymentLogsExcelResponse';
import { setLoading, setPaymentsHistoryList, clearPaymentsHistory, setLoadingPaymentLogsExcel } from '../../../reducers/adminPayment/paymentsHistory';
import { AppThunk } from '../../../reducers';
import { modalNotificationsPush } from '../..';

export const getAdminPaymentsHistory = (options?: RequestParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setLoading(true));
    const route = getApiRoute(appUrls.api_get_admin_payments_history, { }, { ...options });

    try {
        const data = await httpService.get<PaymentHistoryResponse>(route);
        dispatch(setPaymentsHistoryList(data));
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: (err as any)?.data?.errorDetails || 'Произошла ошибка при загрузке списка платежей. Попробуйте позже',
            type: 'error',
        }));
    }

    dispatch(setLoading(false));
};


export const resetAdminPaymentsHistoryData = (): AppThunk => (dispatch) => {
    dispatch(clearPaymentsHistory());
};

export const downloadAdminPaymentLogs = (options?: RequestParams): AppThunk => async (dispatch) => {
    dispatch(setLoadingPaymentLogsExcel(true));
    try {
        const route = getApiRoute(appUrls.api_download_payment_logs_excel, { }, { ...options });
        const { data } = await httpService.post<PaymentLogsExcelResponse>(route);
        downloadExcelData(data, 'payment-log');
    } catch (err) {
        dispatch(modalNotificationsPush({
            title: 'Ошибка',
            message: 'Произошла ошибка при экспорте списка платежей. Попробуйте еще раз',
            type: 'error',
        }));
    }
    dispatch(setLoadingPaymentLogsExcel(false));
};
