import { createSelector } from '@reduxjs/toolkit';
import { StorageState } from '../../../reducers';

export const paymentSberPayCallbackSliceSelector = (state: StorageState) => state.payment.paymentSberPayCallback;

export const paymentSberPayCallbackLoadingSelector = createSelector(
    paymentSberPayCallbackSliceSelector,
    state => state.paymentSberPayCallbackLoading
);

export const paymentSberPayCallbackLoadingErrorSelector = createSelector(
    paymentSberPayCallbackSliceSelector,
    state => state.paymentSberPayCallbackLoadingError
);

export const paymentSberPayCallbackCompleteLoadingSelector = createSelector(
    paymentSberPayCallbackSliceSelector,
    state => state.paymentSberPayCallbackCompleteLoading
);

export const paymentSberPayCallbackCompleteLoadingErrorSelector = createSelector(
    paymentSberPayCallbackSliceSelector,
    state => state.paymentSberPayCallbackCompleteLoadingError
);

export const paymentSberPayCallbackResponseSelector = createSelector(
    paymentSberPayCallbackSliceSelector,
    state => state.paymentSberPayCallbackResponse
);
